import { Expose, Type } from '@nts/std/serialization';
import { ZoomDataDto } from "./zoom-data.dto";

export class ZoomDataListDto {

    constructor() {
        this.zooms = [];
    }

    @Expose()
    @Type(() => ZoomDataDto)
    zooms: ZoomDataDto[];
}