import { BaseIdentity } from '../domain-models/base-identity';
import { InternalViewModelInterface } from './internal-view-model.interface';
import { AggregateElementViewModel } from './aggregate-element-view-model';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { CoreModel } from '../domain-models/core-model';
import { ClassInformationInterface, ClassInformationType } from '@nts/std/utility';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { ClassConstructor } from '@nts/std/serialization';

export class InternalViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends AggregateElementViewModel<TModel, TIdentity> implements InternalViewModelInterface, ClassInformationInterface {

    classType = ClassInformationType.InternalViewModel;
    relationMetaData: InternalRelationMetaData;

    async initInternalViewModel(
        domainModel: TModel,
        metadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        relationMetadata: InternalRelationMetaData,
        path: string,
        isMockedViewModel: boolean,
        parent = null,
        domainModelType: ClassConstructor<TModel> = null
    ) {

        this.relationMetaData = relationMetadata;
        this.reservedPath = path;
        this.reservedName = MetaDataUtils.toCamelCase(relationMetadata.principalPropertyName);
        this.isMock = isMockedViewModel;
        this.parent = parent;
        
        await this.initAggregateElementViewModel(
            domainModel, 
            metadata, 
            orchestratorViewModel,
            undefined,
            this.isMock,
            domainModelType
        );

        this.metadataShortDescription = orchestratorViewModel?.useMessageResourceKey ?
            MessageResourceManager.Current.getMessageIfExists(
                relationMetadata.dependentMetaData.descriptions.displayNameKey) : relationMetadata.dependentMetaData.descriptions.displayName;

        this.metadataDescription = orchestratorViewModel?.useMessageResourceKey ?
            MessageResourceManager.Current.getMessageIfExists(
                relationMetadata.dependentMetaData.descriptions.descriptionKey) : relationMetadata.dependentMetaData.descriptions.description;
    }
}
