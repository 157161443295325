import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ColumnCellRendererParams } from './column_cell_renderer_params.interface';
import { UICommandInterface } from '../../../../src/lib/view-models/commands/ui-command.interface';
import { AsyncPipe, NgClass } from '@angular/common';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe
    ],
    template: `
    <button type="button" class="btn btn-sm btn-secondary" (click)="command.execute(params.data)" [disabled]="!(command.canExecute$ | async)" [attr.title]="command.tooltip">
        <span class="mr-2"><i class="fa" [ngClass]="command.iconClass"></i></span>{{ command.displayName }}</button>
    `
})
export class CommandCellRendererComponent implements AgRendererComponent {
    params: ColumnCellRendererParams;

    refresh(params: any): boolean {
        return true;
    }

    agInit(params: any): void {
        this.params = params;
    }

    get command(): UICommandInterface {
        return this.params.data[this.params.columnInfo.propertyName];
    }
}
