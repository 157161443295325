import { Expose } from "@nts/std/serialization";
import { PanelState } from './panel-state.enum';

export class UserPanelMetaData {
    constructor() {

    }

    @Expose()
    panelState: PanelState;

    @Expose()
    panelId: string;
}
