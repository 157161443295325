import { Expose, Type } from '@nts/std/serialization';
import { ExternalFieldMetaData } from './external-field-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class ExternalLayoutMetaData extends FieldMetaData {

    constructor() {
        super();

        this.availableExternalCodes = [];
        this.externalDecodes = [];
        this.availableExternalDecodes = [];
        this.externalFields = [];
        this.showCodeInDescription = true;

        this.fieldType = FieldTypes.External;
    }

    @Expose()
    @Type(() => ExternalFieldMetaData)
    externalFields?: ExternalFieldMetaData[];

    @Expose()
    availableExternalCodes: string[];

    @Expose()
    externalDecodes?: string[];

    @Expose()
    availableExternalDecodes: string[];

    @Expose()
    public showCodeInDescription: boolean;

    /**
     * @deprecated utilizzare showCodeInDescription
     */
    @Expose()
    public get showCode() {
        return this.showCodeInDescription;
    }
    public set showCode(showCodeInDescription: boolean) {
        this.showCodeInDescription = showCodeInDescription;
    }
}
