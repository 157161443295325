import { of, Observable } from 'rxjs';
import { ServiceResponse } from '../responses/service-response';
import { BaseError } from '../messages/base-error';
import { WebApiServiceAgent } from './web-api-service-agent';
import { LogService } from '@nts/std/utility';

export class HttpCommunication {

    constructor(
        protected agent: WebApiServiceAgent
    ) { }

    getWebApiServiceAgent(): WebApiServiceAgent {
        return this.agent;
    }

    protected handleErrorAsResponse<TResponse extends ServiceResponse>(error: any, responseType: any): Observable<TResponse> {

        const messageError = new BaseError();

        let errorMessage = 'Error in ApiClient: ';
        if (error.message) {
            errorMessage += error.message;
        }

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        messageError.description = errorMessage;

        const typedResponse: TResponse = new responseType();
        typedResponse.operationSuccedeed = false;
        typedResponse.errors.push(messageError);

        LogService.warn(errorMessage, messageError);

        return of(typedResponse);

    }

    protected handleErrorAsResponseWithInstance<TResponse extends ServiceResponse>(
        error: any, responseInstance: TResponse): Observable<TResponse> {

        const messageError = new BaseError();

        let errorMessage = 'Error in ApiClient: ';
        if (error.message) {
            errorMessage += error.message;
        }

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        messageError.description = errorMessage;

        responseInstance.operationSuccedeed = false;
        responseInstance.errors.push(messageError);

        LogService.warn(errorMessage, messageError);

        return of(responseInstance);
    }

    protected handleErrorAsString(error: any): Observable<string> {

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        let errorMessage = 'Error in ApiClient: ';
        if (error.message) {
            errorMessage += error.message;
        }

        LogService.warn(errorMessage);

        return of(errorMessage);
    }
}
