import { Expose } from "@nts/std/serialization";

export class CookiePreferencesDto {

    constructor() {
        this.analyticsCookies = false;
        this.essentialCookies = true;
    }

    @Expose()
    analyticsCookies: boolean;
    
    @Expose()
    essentialCookies: boolean;
}
