import { BaseIdentity } from '../domain-models/base-identity';
import { ItemViewModel } from './item-view-model';
import { CollectionViewModel } from './collection-view-model';
import { CoreModel } from '../domain-models/core-model';

export class BaseSerialCollectionViewModel<
    TItemViewModel extends ItemViewModel<TItemModel, TItemIdentity>,
    TItemModel extends CoreModel<TItemIdentity>,
    TItemIdentity extends BaseIdentity
    > extends CollectionViewModel<TItemViewModel, TItemModel, TItemIdentity> {
}

