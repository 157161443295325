import { BaseIdentity } from './base-identity';
import { CoreModel } from './core-model';
import { BaseMultiLanguageModelInterface } from './base-multi-language-model.interface';
import { MultiLanguageIdentityInterface } from './multi-language-identity.interface';
import { Expose } from "@nts/std/serialization";


export class BaseMultiLanguageModel<TMLIdentity extends BaseIdentity & MultiLanguageIdentityInterface> extends CoreModel<TMLIdentity> implements BaseMultiLanguageModelInterface {

    private _languageCode: string;

    /**
     * Codice lingua (in formato en-GB, it-IT)
     */
    @Expose()
    get languageCode(): string {
        return this.getValueForIdentityValue<string>(() => this._languageCode, 'languageCode');
    }
    set languageCode(value: string) {
        this.setValueForIdentityValue<string>(() => { this._languageCode = value; }, value, 'languageCode');
    }

}
