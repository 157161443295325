import { ModifiedSubscriberInterface } from '../modified-subscriber.interface';
import { BaseViewModel } from '../base-view-model';
import { UICommandInterface } from '../commands/ui-command.interface';
import { ZoomApiClientInterface } from '../../api-clients/zoom/zoom-api-client.interface';
import { DomainModelMetaData } from '../../meta-data/domain-model-meta-data';
import { ZoomStarterMode } from '../../domain-models/zoom/zoom-starter-mode';
import { ZoomQueryContext } from './zoom-query-context';
import { Subject, of, timeout, firstValueFrom, Observable } from 'rxjs';
import { FilterOperators, Filter } from '../../domain-models/find-options/filter';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { ZoomUIStarterArgs } from './zoom-ui-starter-args';
import { ZoomOrchestratorViewModelResolver } from './zoom-orchestrator-view-model-resolver';
import { MessageCodes } from '../../resources/message-codes';
import { ZoomArgs } from './zoom-args';
import { FilterCollection } from '../../domain-models/find-options/filter-collection';
import { UICommandSettingsManager } from '../commands/ui-command-settings-manager';
import { CommandTypes } from '../commands/command-types';
import { ZoomFilterViewModel } from './filter-view-model/zoom-filter-view-model';
import { ZoomMetaData } from '../../meta-data/zoom-meta-data';
import { BoolZoomFilterViewModel } from './filter-view-model/bool-zoom-filter-view-model';
import { DateTimeZoomFilterViewModel } from './filter-view-model/date-time-zoom-filter-view-model';
import { ModalService } from '../modal/modal.service';
import { ZoomApiClient } from '../../api-clients/zoom/zoom-api-client';
import { CommandFactory } from '../commands/command-factory';
import { StringZoomFilterViewModel } from './filter-view-model/string-zoom-filter-view-model';
import { EnumZoomFilterViewModel } from './filter-view-model/enum-zoom-filter-view-model';
import { NumericZoomFilterViewModel } from './filter-view-model/numeric-zoom-filter-view-model';
import { ZoomAdvancedOptions } from '../../domain-models/find-options/zoom-advanced-options';
import { DateTimeOffset } from '../../domain-models/date-time-offset';
import { AggregateMetaData } from '../../meta-data/aggregate-meta-data';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { GuidZoomFilterViewModel } from './filter-view-model/guid-zoom-filter-view-model';
import { LogService } from '@nts/std/utility';
import findLastIndex from 'lodash-es/findLastIndex';
import { ChildrenAwareInterface } from './filter-view-model/children-aware.interface';
import { OutputDataOrderDto } from '../../domain-models/zoom/dto/output-data-order.dto';
import { ZoomAvailablesFilterPipe } from '../../components/zoom/zoom-availables-filter.pipe.ts';
import { OrderBy, OrderByType } from '../../domain-models/autocomplete/auto-complete-options';
import { ZoomPropertyViewModelInterface } from './property-view-model/zoom-property-view-model.interface';
import { DateTimeZoomPropertyViewModel } from './property-view-model/date-time-zoom-property-view-model';
import { DateTimeOffsetZoomPropertyViewModel } from './property-view-model/date-time-offset-zoom-property-view-model';
import { EnumZoomPropertyViewModel } from './property-view-model/enum-zoom-property-view-model';
import { StringZoomPropertyViewModel } from './property-view-model/string-zoom-property-view-model';
import { NumericZoomPropertyViewModel } from './property-view-model/numeric-zoom-property-view-model';
import { ExternalZoomFilterViewModel } from './filter-view-model/external-zoom-filter-view-model';
import { ZoomOperatorViewModelProperty } from './zoom-operator-view-model-property';
import { InternalZoomFilterViewModel } from './filter-view-model/internal-zoom-filter-view-model';
import { InternalCollectionZoomFilterViewModel } from './filter-view-model/internal-collection-zoom-filter-view-model';
export class ZoomParametersViewModel extends BaseViewModel implements ModifiedSubscriberInterface {

    resetCommand: UICommandInterface;
    clearCommand: UICommandInterface;
    findCommand: UICommandInterface;

    private _apiClient: ZoomApiClientInterface;
    private _aggregateMetaData: AggregateMetaData;
    private _domainModelMetaData: DomainModelMetaData;
    private _refreshFilter = true;
    private _currentOption: ZoomAdvancedOptions;
    private _defaultFilterAttributes: FilterCollection;
    private _domainModelMetaDataList: DomainModelMetaData[];
    private _zoomMetaDataList: ZoomMetaData[];
    private _backupOptions: Array<ZoomFilterViewModel>;
    private _zoomStarterMode: ZoomStarterMode;
    private _extDependentAssociationPropertiesDisplayName: Map<string, Map<string, string>>;
    private _context: ZoomQueryContext;
    private _callerDomainModelFullName: string;

    // sortInitializationFinished: Subject<boolean> = new Subject<boolean>();
    isLoaded = false;
    rowToSetFocus: number;
    headerPropertyName: string;
    headerOperatorName: string;
    headerValue: string;
    filters: Array<ZoomFilterViewModel>;
    externalDomainModelDomainModelNameToZoom: string;
    isActiveF6: boolean;
    paramtersUpdated: Subject<void> = new Subject();
    findCommandFocus: Subject<void> = new Subject();
    onSortChangedFromExternal: Subject<void> = new Subject();
    parametersChanged: Subject<void> = new Subject();
    findEmitted: Subject<void> = new Subject();
    showAdvancedFilters = false;
    showSortingGroups = false;
    showSelectionsFilterOptions = false;
    showFilterWithPopulatedOperator = false;
    // Store a reference to the enum
    filterOperators = FilterOperators;
    advancedButtonTitle = MessageResourceManager.Current.getMessage('std_Zoom_Advanced');
    sortingGroupButtonTitle = MessageResourceManager.Current.getMessage('std_Zoom_SortingGroups');
    selectionsFiltersOptionsButtonTitle = MessageResourceManager.Current.getMessage('std_Zoom_SelectionsFiltersOptions');
    filterWithPopulatedOperatorButtonTitle = MessageResourceManager.Current.getMessage('std_Zoom_FilterWithPopulatedOperator');
    variablesPlaceholderTitle = MessageResourceManager.Current.getMessage('std_ComboEmptyItem');
    variablesButtonTitle = 'Valori'; // MessageResourceManager.Current.getMessage('std_Zoom_Advanced');
    addInFieldButtonTitle = 'Aggiungi campo';

    private _selectedZoomFilterViewModel: ZoomFilterViewModel;
    get selectedZoomFilterViewModel(): ZoomFilterViewModel {
        return this._selectedZoomFilterViewModel;
    }
    set selectedZoomFilterViewModel(value: ZoomFilterViewModel) {
        this._selectedZoomFilterViewModel = value;
        this.isActiveF6 = this.canFindExternal();
    }

    // #region External zoom

    private parseZoomResult(zoomResult: string, dependentAssociationProperty: string): string {
        let result = '';
        const plain = JSON.parse(zoomResult);
        const prop = plain[MetaDataUtils.toCamelCase(dependentAssociationProperty)];
        if (prop !== null) {
            result = prop;
        }
        return result;
    }

    selectAll() {
        this.zoomAvailablesFilterPipe.transform(this.filters, this.showFilterWithPopulatedOperator).forEach((f) => {
            if (f.isVisible) {
                f.isSelected = true;
            }
        })
        this.notifyModified();
    }

    deselectAll() {
        this.filters.forEach((f) => {
            f.isSelected = false;
        })
        this.notifyModified();
    }

    canFindExternal(): boolean {
        if (this.selectedZoomFilterViewModel !== null) {
            if (this.selectedZoomFilterViewModel.OperatorDoesNotRequireValue) {
                return false;
            } else {
                return this.selectedZoomFilterViewModel.externalDomainModelNameToZoom !== '';
            }
        } else {
            return false;
        }
    }

    addInField(filter: ZoomFilterViewModel) {
        filter.addNewFilterInValues();
    }

    deleteInField(filter: ZoomFilterViewModel, inToRemove: ZoomPropertyViewModelInterface<any>) {
        filter.deleteFilterInValues(inToRemove);
    }

    // Need it for dashboard's zoom override
    protected buildArgsForFindExternalZoom() {
        return new ZoomUIStarterArgs(
            this.selectedZoomFilterViewModel.metaData.external.dependentAggregateMetaData,
            this.selectedZoomFilterViewModel.externalDomainModelNameToZoom,
            this.selectedZoomFilterViewModel.externalDomainModelFullNameToZoom,
            this.selectedZoomFilterViewModel.metaData.external.isRemote,
            this._aggregateMetaData,
            this._apiClient.rootDomainModelName,
        );
    }

    async findExternal() {

        // lettura parametri
        try {

            const args = this.buildArgsForFindExternalZoom();

            args.zoomStarterMode = ZoomStarterMode.Zoom;

            //         if (!args.Options.FilterAttributes.Exists(
            //                 f => f.PropertyName == SelectedZoomFilterViewModel.PropertyName.Item2))
            //         {
            //             args.Options.FilterAttributes.Add(new FindFilter()
            //             {
            //                 Operator = SelectedZoomFilterViewModel.Operator.CurrentValue,
            //                 PropertyName = SelectedZoomFilterViewModel.PropertyName.Item2,
            //                 PropertyValue = SelectedZoomFilterViewModel.FilterValue.Value
            //             });
            //         }
            const zoomWindowControlViewModel = ZoomOrchestratorViewModelResolver.createZoomOrchestratorViewModel();

            await zoomWindowControlViewModel.initialize(args);
            const res = await this.modalService.showZoomAsync(zoomWindowControlViewModel);

            if (!res.cancel && res.result?.result) {
                const identityPlain: {[key:string]: string|number} = JSON.parse(res.result.result);
                const selectedZoomFilterViewModel = this.selectedZoomFilterViewModel as ExternalZoomFilterViewModel;
                selectedZoomFilterViewModel.filterValue.setValue({identity: identityPlain});
            }


        } catch (error) {
            const popUpMessage = MessageResourceManager.Current.getMessage(MessageCodes.ZoomFindException);
            this.modalService.showExceptionPopUp(error, '', popUpMessage);

        } finally {
            //         MemoryUtils.CompactMemory();
        }

    }

    private setExternalDomainModelNameToZoom(filter: ZoomFilterViewModel, zoomProp: ZoomMetaData) {

        if (zoomProp.external) {
            filter.externalDomainModelNameToZoom = zoomProp.external.dependentAggregateMetaData.rootName;
            filter.externalDomainModelFullNameToZoom = zoomProp.external.dependentAggregateMetaData.rootFullName;
            filter.dependentAssociationProperty = zoomProp.external.associationProperties[0].dependentPropertyName;
        }
    }

    private createOrBackup(create: Function, backupOption: ZoomFilterViewModel): ZoomFilterViewModel {

        return backupOption !== undefined && !backupOption.hasErrors ? backupOption : create();

    }

    private setOpenParameters(filterCollection: FilterCollection) {
        this.rowToSetFocus = 0;
       
        filterCollection.forEach(openFilter => {
            let converted = false;
            let filterVM = this.filters.filter(x => x.propertyNameMap.propertyPath === openFilter.name)[0];
            if (filterVM !== undefined) {

                // Se il filtro è stato nascosto non posso aprirlo
                //  a meno che non sia un backing field e quindi apro il suo related
                if (filterVM.isHidden) {

                    // Se eiste il suo related
                    // converto il filtro attuale con il suo related
                    if (filterVM.related != null) {

                        // TODO Verifico che il suo related non sia già presente nella filterCollection dei filtri da aprire
                        // if (filterCollection.find((f) =>f.name === filterVM.related.zoomParametersViewModel.filt))
                        filterVM = filterVM.related;
                        converted = true;
                    } else {
                        return
                    }
                }

                if (openFilter.operator === FilterOperators.Between) {
                    this.setAdvancedFilters();
                }

                // Se il filtro è stato convertito in un external
                // sovrascrivo l'operatore con l'equal
                if (filterVM.isExternal && converted) {
                    filterVM.operator.currentValue = FilterOperators.Equals;
                } else {
                    filterVM.operator.currentValue = openFilter.operator;
                }                

                // in certi casi si potrebbe avere preimpostato solo il PropertyName e l'operatore
                // ma non il valore; pertanto in questo caso non si setta nulla sul valore

                // gestisce dateTimeOffsetValue
                openFilter.value = openFilter.dateTimeOffSetValue != null ? openFilter.dateTimeOffSetValue : openFilter.value;

                openFilter.upperValue = openFilter.upperValue != null ? openFilter.upperValue : openFilter.upperValue;
                if (openFilter.value != null) {
                    filterVM.filterValue.setValue(openFilter.value);
                }
                if (openFilter.upperValue != null) {
                    filterVM.filterValue2.setValue(openFilter.upperValue);
                }
                
                if (openFilter.values != null && openFilter.values?.length > 0 && (this.isFilterEnum(filterVM.filterValue as ZoomPropertyViewModelInterface<number>) || this.isFilterNumeric(filterVM.filterValue as ZoomPropertyViewModelInterface<number>) || this.isFilterString(filterVM.filterValue as ZoomPropertyViewModelInterface<string>))) {
                    (filterVM as EnumZoomFilterViewModel|NumericZoomFilterViewModel|StringZoomFilterViewModel).setZoomPropertyViewModelValuesFromEntities(openFilter.values);
                }

                if (openFilter.dateTimeValues != null && openFilter.dateTimeValues?.length > 0 && (this.isFilterDate(filterVM.filterValue as ZoomPropertyViewModelInterface<Date>))) {
                    (filterVM as DateTimeZoomFilterViewModel).setDateTimeZoomPropertyViewModelFromEntities(openFilter.dateTimeValues);
                }

                if (openFilter.dateTimeOffSetValues != null && openFilter.dateTimeOffSetValues?.length > 0 && (this.isFilterDateTimeOffset(filterVM.filterValue as ZoomPropertyViewModelInterface<DateTimeOffset>))) {
                    (filterVM as DateTimeZoomFilterViewModel).setDateTimeZoomPropertyViewModelFromEntities(openFilter.dateTimeOffSetValues);
                }

                // imposta il valore delle variabili
                filterVM.setVariable(openFilter.runtimeVariable, openFilter.runtimeVariableUperrValue);

                // apro eventuali internal/external
                if (filterVM.parent){
                    (filterVM.parent as ZoomFilterViewModel).isCollapsed = false;
                }

                if (openFilter.isLocked) {
                    filterVM.islocked = openFilter.isLocked;
                }
                
                // se provengo da F5 il fuoco andrà sulla riga di filterVM alla colonna 2
                /*if (this._zoomStarterMode === ZoomStarterMode.F5) {
                    this.rowToSetFocus = this.filters.indexOf(filterVM);
                }*/
            }
        });

        this.orderFilters();
    }

    private orderFilters(): void {
        const filtersWithOperatorSetAndNoValue: Array<ZoomFilterViewModel> = this.filters.filter(f => (f.operator.currentValue !== FilterOperators.None) && (f.filterValue.value == null)).sort((a, b) => {
            // Nel caso in cui entrambi sono identities
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) > -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) > -1) {
                return a.propertyNameMap.displayName.localeCompare(b.propertyNameMap.displayName);
            }

            // Nel caso in cui solo a è identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) > -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) === -1) {
                return -1; // a è inferiore a b
            }

            // Nel caso in cui solo a b identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) === -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) > -1) {
                return 1; // a è maggiore di b
            }

            // Nel caso in cui ne a ne b è un identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) === -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) === -1) {
                return a.propertyNameMap.displayName.localeCompare(b.propertyNameMap.displayName);
            }

            return null;
        });
        const filtersWithOperatorSetAndValueSet: Array<ZoomFilterViewModel> = this.filters.filter(f => (f.operator.currentValue !== FilterOperators.None) && (!(f.filterValue.value == null))).sort((a, b) => {
            // Nel caso in cui entrambi sono identities
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) > -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) > -1) {
                return a.propertyNameMap.displayName.localeCompare(b.propertyNameMap.displayName);
            }

            // Nel caso in cui solo a è identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) > -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) === -1) {
                return -1; // a è inferiore a b
            }

            // Nel caso in cui solo a b identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) === -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) > -1) {
                return 1; // a è maggiore di b
            }

            // Nel caso in cui ne a ne b è un identity
            if (this._domainModelMetaData.identityNames.indexOf(a.propertyNameMap.propertyPath) === -1 && this._domainModelMetaData.identityNames.indexOf(b.propertyNameMap.propertyPath) === -1) {
                return a.propertyNameMap.displayName.localeCompare(b.propertyNameMap.displayName);
            }

            return null;
        });
        const filtersWithNoOperator: Array<ZoomFilterViewModel> = this.filters.filter(f => (f.operator.currentValue === FilterOperators.None));

        this.filters = filtersWithOperatorSetAndNoValue.concat(filtersWithOperatorSetAndValueSet).concat(filtersWithNoOperator);

        this.fixChildren()
    }

    fixChildren() {
        for (let filterIndex = 0; filterIndex < this.filters.length; filterIndex++) {
            const filter = this.filters[filterIndex];
            if (filter.children?.length > 0) {

                let positionOffset = 0;

                for (const [ic,c] of filter.children.entries()) {
                    for (const [i,f] of this.filters.entries()) {
                        // Se trova il filtro figlio nella lista e lo troca in una posizione superiore al ciclo for iniziale
                        if (f.uniqueId === c.uniqueId && i > filterIndex ) {
                            // Toglie il figlio dalla posizione trovata
                            this.filters.splice(i, 1);
                            
                            // e lo aggiunge all'offset corrente
                            this.filters.splice(filterIndex + ++positionOffset, 0, c);
                            
                            break;
                        }
                    }
                }                
            }
        }
    }

    private setDefaultParameters() {
        this.filters.forEach(filter => {
            filter.setDefault();
        });
    }

    private buildParameter(
        zoomProp: ZoomMetaData,
        backupOption: ZoomFilterViewModel,
        context: ZoomQueryContext,
        parentFilter: ZoomFilterViewModel = null
    ): ZoomFilterViewModel {

        let propertyType = zoomProp.propertyMetadata?.getType();

        if (propertyType == null) {
            if (zoomProp.external) {
                propertyType = 'External'
            } else if (zoomProp.internalRelation) {
                propertyType = 'Internal'
            } else {
                propertyType = 'InternalCollection'
            }
        }

        // forzo il nome da visualizzare della property con quello della lookProp
        if (propertyType === 'Bool') {
            const vm = this.createOrBackup(() => new BoolZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.isSelected = zoomProp.parent == null;
            vm.parent = parentFilter;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'DateTime') {
            const vm = this.createOrBackup(() => new DateTimeZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.isSelected = zoomProp.parent == null;
            vm.parent = parentFilter;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'String') {
            const vm = this.createOrBackup(() => new StringZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.isSelected = zoomProp.parent == null && (zoomProp.isRootIdentity || !this.checkIfZoomMetaDataHasRelation(zoomProp));
            vm.parent = parentFilter;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'Guid') {
            const vm = this.createOrBackup(() => new GuidZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.isSelected = zoomProp.parent == null && (zoomProp.isRootIdentity || !this.checkIfZoomMetaDataHasRelation(zoomProp));
            vm.parent = parentFilter;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'Enum') {
            if (zoomProp.propertyMetadata.name === 'currentState') {
                return undefined;
            } else {
                const vm = this.createOrBackup(() => new EnumZoomFilterViewModel(zoomProp, this), backupOption);
                vm.isVisible = zoomProp.parent == null;
                vm.isSelected = zoomProp.parent == null;
                vm.parent = parentFilter;
                if (vm.parent instanceof ZoomFilterViewModel) {
                    vm.parent.children.push(vm);
                }
                return vm;
            }
        } else if (propertyType === 'Numeric') {

            const vm = this.createOrBackup(() => new NumericZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.parent = parentFilter;
            vm.isSelected = zoomProp.parent == null && (zoomProp.isRootIdentity || !this.checkIfZoomMetaDataHasRelation(zoomProp));

            // disabilito l'operatore se è un internal
            if (zoomProp.internalRelation || zoomProp.internalCollection) {
                vm.operator.isEnabled = false;
            }
            // vm.isSelected = zoomProp.parent == null && zoomProp.ch;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;

            // return this.createOrBackup(() => new NumericZoomFilterViewModel(zoomProp, this), backupOption);

        } else if (propertyType === 'External') {
            const vm = this.createOrBackup(() => new ExternalZoomFilterViewModel(zoomProp, this), backupOption);
            // if (vm.metaData.external.parentIdentityPropertyPathName?.length > 0) {
            //     console.log('parentIdentityPropertyPathName trovato', vm.metaData.external.parentIdentityPropertyPathName)
            // }

            vm.isVisible = zoomProp.parent == null;
            vm.parent = parentFilter;
            vm.isSelected = false;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'Internal') {
            const vm = this.createOrBackup(() => new InternalZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.parent = parentFilter;
            vm.isSelected = false;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else if (propertyType === 'InternalCollection') {
            const vm = this.createOrBackup(() => new InternalCollectionZoomFilterViewModel(zoomProp, this), backupOption);
            vm.isVisible = zoomProp.parent == null;
            vm.parent = parentFilter;
            vm.isSelected = false;
            if (vm.parent instanceof ZoomFilterViewModel) {
                vm.parent.children.push(vm);
            }
            return vm;
        } else {
            return this.createOrBackup(() => undefined, backupOption);
        }
    }

    private checkIfZoomMetaDataHasRelation(zoomMetaData: ZoomMetaData) {
        return zoomMetaData.internalCollection || zoomMetaData.internalRelation || zoomMetaData.external;
    }

    protected async getZoomSortedPropertiesForQuery(findOption: ZoomAdvancedOptions): Promise<Array<ZoomMetaData>> {
        return this._zoomMetaDataList;
    }

    getFilterChildrenNumber(filter: ChildrenAwareInterface): number {
        // Rimappa ogni children con la quantità dei suoi figli
        const childrenQty: number[] = filter.children.map((c) => this.getFilterChildrenNumber(c) + 1);

        // Ritorna la somma delle quantità
        return childrenQty.reduce((a, b) => a + b, 0);
    }

    private checkAndSetParentExternalPropertyFilter() {
        for (const filter of this.filters) {
            if (filter.isExternal) {
                if (filter.metaData.external.parentIdentityPropertyPathName?.length > 0) {
                    const parentExternalFilter = this.filters.find((f) => f.propertyNameMap.propertyPath === filter.metaData.external.parentIdentityPropertyPathName)
                    if (parentExternalFilter) {
                        (filter as ExternalZoomFilterViewModel).parentExternalFilter = parentExternalFilter;
                        filter.updateOperatorList(new ZoomOperatorViewModelProperty(
                            ZoomOperatorViewModelProperty.operatorsForFieldWithRelation,
                            this
                        ));
                        parentExternalFilter.updateOperatorList(new ZoomOperatorViewModelProperty(
                            ZoomOperatorViewModelProperty.operatorsForFieldWithRelation,
                            this
                        ));
                    }
                }
            }
        }
    }

    private hideBackingFields() {
        for (const filter of this.filters) {
            if (filter?.isExternal) {

                for (const identityName of filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames) {

                    // Inizializzo il suo backing field con il nome della property
                    let backinFieldPropertyName = identityName;

                    // Cerco il suo backing field nelle association
                    if (filter.metaData.external.associationProperties?.length > 0) {
                        const foundAssociation = filter.metaData.external.associationProperties.find((a) => 
                            a.dependentPropertyName === backinFieldPropertyName
                        )
                        if(foundAssociation) {
                            backinFieldPropertyName = foundAssociation.principalPropertyName;
                        }
                    }

                    const parentPath: string = filter.propertyNameMap.propertyPath
                        .split('.')
                        // remove last array element
                        .slice(0, -1)
                        .join('.');

                    // Recupero lo zoomMetaData del backingField
                    const backingFieldFilterViewModelIndex = this.filters.findIndex((z) => z?.propertyNameMap?.propertyPath === ((parentPath?.length > 0 ? parentPath + '.' : '') +  backinFieldPropertyName));
                    if (backingFieldFilterViewModelIndex > -1) {
                        // Non posso rimuoverle altrimenti non riesco a vedere le colonne nella ricerca
                        this.filters[backingFieldFilterViewModelIndex].isHidden = true;
                        this.filters[backingFieldFilterViewModelIndex].isBackingField = true;
                        this.filters[backingFieldFilterViewModelIndex].related = filter;
                        filter.related = this.filters[backingFieldFilterViewModelIndex];
                    }
                    
                }

            }

            // Rimuovo le property di relazioni dentro l'internal
            if (filter?.isInternal) {
                for (const identityName of filter.metaData.internalRelation.dependentMetaData.identityNames) {
                    // Inizializzo il suo backing field con il nome della property
                    let backinFieldPropertyName = identityName;

                    // Cerco il suo backing field nelle association
                    if (filter.metaData.internalRelation.associationProperties?.length > 0) {
                        const foundAssociation = filter.metaData.internalRelation.associationProperties.find((a) => 
                            a.dependentPropertyName === backinFieldPropertyName
                        )
                        if(foundAssociation) {
                            backinFieldPropertyName = foundAssociation.principalPropertyName;
                        }
                    }

                    // Recupero lo zoomMetaData
                    const backingFieldFilterViewModelIndex = this.filters.findIndex((z) => z?.propertyNameMap?.propertyPath === (filter?.propertyNameMap?.propertyPath + '.' +  backinFieldPropertyName));
                    if (backingFieldFilterViewModelIndex > -1) {
                        this.filters[backingFieldFilterViewModelIndex] = null;
                    }
                }
            }
        }

        this.filters = this.filters.filter((f) =>f != null);
    }

    private async buildParameters(
        backupOptions: Array<ZoomFilterViewModel>, 
        context: ZoomQueryContext, 
        outputDataOrderList: OutputDataOrderDto[] = [],
        orderByPropertyNames: OrderBy[] = [],
    ) {
        const allProperties: ZoomMetaData[] = await this.getZoomSortedPropertiesForQuery(this._currentOption);
        
        for (const [index, zoomProp] of allProperties.entries()) {
            let backupOption: ZoomFilterViewModel;
            if (backupOptions !== undefined) {
                backupOption = backupOptions.filter(
                    item => MetaDataUtils.toCamelCase(item.propertyNameMap.propertyPath) === MetaDataUtils.toCamelCase(zoomProp.propertyPath))[0];
            }

            let parentFilter: ZoomFilterViewModel = null;

            // se zoomProp ha un parent, cerco il parent tra i filtri e lo passo
            if (zoomProp.parent) {
                parentFilter = this.filters.find((f) => f.metaData === zoomProp.parent);
            }
            const filter: ZoomFilterViewModel = this.buildParameter(zoomProp, backupOption, context, parentFilter);
            
            if (orderByPropertyNames?.length > 0) {
                const foundOrderByPropertyNameIndex = orderByPropertyNames.findIndex((o) => o.propertyName === filter.propertyNameMap.propertyPath);
                if (foundOrderByPropertyNameIndex > -1) {
                    filter.orderByIndex = foundOrderByPropertyNameIndex;
                    filter.orderBy = orderByPropertyNames[foundOrderByPropertyNameIndex].sortType;
                }
            }

            // Nel caso in cui sia un external del primo livello
            if (filter?.isExternal === true && zoomProp.level === 1) {

                // ciclo tutte le sue identity
                for (const identityName of filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames) {
                    
                    // Inizializzo il suo backing field con il nome della property
                    let backinFieldPropertyName = identityName;

                    // Cerco il suo backing field nelle association
                    if (zoomProp.external.associationProperties?.length > 0) {
                        const foundAssociation = zoomProp.external.associationProperties.find((a) => 
                            a.dependentPropertyName === backinFieldPropertyName
                        )
                        if(foundAssociation) {
                            backinFieldPropertyName = foundAssociation.principalPropertyName;
                        }
                    }

                    // Recupero lo zoomMetaData del backingField
                    const foundZoomMetaData = allProperties.find((z) => z.level === 1 && z.propertyPath === backinFieldPropertyName);

                    // Se esiste il backing field ed una identity non la devo rimuovere
                    if (foundZoomMetaData?.isRootIdentity === true) {

                        // Il filtro deve essere selezionato se sostituisce una root identity ed ha solo una identity
                        filter.isSelected = filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames?.length === 1;
                        filter.metaData.isRootIdentity = true;
                    } else {
                        // Se l'identity in corso non è root devo nasconderla
                        const backingFieldIndex = this.filters.findIndex((f) => f.metaData === foundZoomMetaData);
                        if (backingFieldIndex > -1) {
                            this.filters[backingFieldIndex].isHidden = true;
                        }
                    }
                }

            }                
                
            if (filter !== undefined) {

                // Nel caso in cui suo padre sia un external remote devo disabilitare gli operator
                if(((filter?.parent) as ZoomFilterViewModel)?.isExternalRemote === true ) {
                    filter.operator.isEnabled = false;

                    // TODO aggiungere logica per gestire i figli dei figli degli external remote con una property? isExternalRemoteChild = true?
                }

                if (outputDataOrderList.length > 0 ){
                    const found = outputDataOrderList.find(x=>x.propertyName == filter.propertyNameMap.propertyPath);
                    if(found){
                        filter.isSelected = found.isVisible;
                    } else {
                        filter.isSelected = false;
                    }
                }
                this.setExternalDomainModelNameToZoom(filter, zoomProp);
                filter.zoomParametersViewModel = this;

                // se il filtro ha un parent deve aggiungerlo alla fine del suo parent
                if (filter?.parent) {
                    // ricerca se ci sono dei fratelli per aggiungerlo alla fine dei suoi fratelli
                    const lastSiblingIndex = findLastIndex(this.filters, (f: ZoomFilterViewModel) => f.parent === filter?.parent);
                    
                    // se l'ultimo fratello non ha figli
                    if (lastSiblingIndex > -1 && this.filters[lastSiblingIndex].children?.length === 0) {
                        // lo inserisce dopo suo fratello
                        this.filters.splice(lastSiblingIndex + 1, 0, filter);
                    } else if (lastSiblingIndex > -1 && this.filters[lastSiblingIndex].children?.length > 0) {
                        // se l'ultimo fratello ha figli devo metterlo dopo i suoi figli
                        // calcola il numero di figli e lo mette dopo
                        const children = this.getFilterChildrenNumber(this.filters[lastSiblingIndex]);
                        this.filters.splice(lastSiblingIndex + children + 1, 0, filter);
                    } else {
                        // se non esistono fratelli lo aggiunge dopo il suo parent
                        const parentIndex = this.filters.findIndex((f: ZoomFilterViewModel) => f === filter.parent);
                        if (parentIndex > -1) {
                            this.filters.splice(parentIndex + 1, 0, filter);
                        } else {
                            LogService.warn(`Posizione per il filtro ${filter.propertyNameMap.propertyPath} non trovata, lo aggiungo in fondo`);                            this.filters.push(filter);
                        }
                    }
                } else {
                    this.filters.push(filter);
                }
            }
        }
        this.checkAndSetParentExternalPropertyFilter();
    }

    constructor(
        private modalService: ModalService,
        public apiClient: ZoomApiClient,
        public zoomAvailablesFilterPipe: ZoomAvailablesFilterPipe
    ) {
        super();
        this._apiClient = apiClient;
    }

    async init(args: ZoomArgs) {
        this.isLoaded = false;

        this._aggregateMetaData = args.aggregateMetaData;
        this._domainModelMetaData = args.requestedDomainModelMetadata;
        this._domainModelMetaDataList = args.domainModelMetaDataList;
        this._zoomMetaDataList = args.zoomMetaDataList;
        this._callerDomainModelFullName = args.callerDomainModelFullName;
        this._zoomStarterMode = args.zoomStarterMode;
        this._extDependentAssociationPropertiesDisplayName = args.extDependentAssociationPropertiesDisplayName;

        this.headerPropertyName = MessageResourceManager.Current.getMessage(MessageCodes.ZoomParameters_PropertyName);
        this.headerOperatorName = MessageResourceManager.Current.getMessage(MessageCodes.ZoomParameters_Operator);
        this.headerValue = MessageResourceManager.Current.getMessage(MessageCodes.ZoomParameters_FilterValue);
        this._currentOption = args.zoomOptions;
        this._context = args.zoomQueryContext;
        this._defaultFilterAttributes = new FilterCollection();
        this._defaultFilterAttributes.push(...args.zoomOptions.filters);

        this.filters = new Array<ZoomFilterViewModel>();

        const manager = new UICommandSettingsManager();
        this.resetCommand = manager.setUICommand(CommandTypes.Restore,
            CommandFactory.createUICommand(
                async (x) => this.resetParameter(), 
                () => of(this._defaultFilterAttributes.length > 0)
            )
        );

        // comando che pulisce tutti filtri
        this.clearCommand = manager.setUICommand(
            CommandTypes.ClearFilters, CommandFactory.createUICommand(
                async (x) => this.clearParameter(), 
                () => of(true)
            )
        );

        // comando che esegue le ricerche
        this.findCommand = manager.setUICommand(
            CommandTypes.Find, CommandFactory.createUICommand(async (x) => this.findEmitted.next()));

        await this.buildParameters(
            undefined, 
            args.zoomQueryContext, 
            args?.zoomOptions?.outputDataOrderList,
            args?.zoomOptions?.orderByPropertyNames
        );
        this.hideBackingFields();
        this.setDefaultParameters();
        this.setOpenParameters(this._currentOption.filters);
        this.fixSelectedExternals();
        this.refreshCurrentOutputProperties();  // Force refresh of outputproperties, need for column in result
       
        this.onSortChangedFromExternal.next();
       
        // await firstValueFrom(this.sortInitializationFinished.pipe(timeout({
        //     first: 1000,
        //     with: () => of(true)
        // })))

        this.isLoaded = true;
    }

    notifyModified() {
        // qualcosa è cambiato sui valori dei filtri o sugli operatori; i filtri sono quindi da ricalcolare
        this._refreshFilter = true;

        if (this.isLoaded) {
            this.parametersChanged.next();
        }
    }

    // viene chiamata al click del reset filtri
    clearParameter() {
        this.filters.forEach((f) => {
            f.setDefault();
            // f.operator.currentValue = FilterOperators.None;
        });
        // this.filters.length = 0;
        // this.buildParameters(undefined, this._context);
        // this.setDefaultParameters();
    }

    // viene chiamata quando vengono chiusi i filtri avanzati
    resetParameter() {
        this.filters.forEach((f) => {
            f.setDefault();
            // f.operator.currentValue = FilterOperators.None;
        });
        // this.filters.length = 0;
        // this.buildParameters(undefined, this._context);
        // this.setDefaultParameters();
        this.setOpenParameters(this._defaultFilterAttributes);
    }

    updateFiltersFromSortModel(
        sortModels: {sort: string, colId: string}[]
    ) {

        // Save older filters
        const oldFiltersSort: {
            orderBy: OrderByType;
            orderByIndex: number;
            colId: string;
        }[] = this.filters.map((f) => ({
            orderBy: f.orderBy,
            orderByIndex: f.orderByIndex,
            colId: f.propertyNameMap.propertyPath
        }))

        if (sortModels?.length > 0) {
            for (const filter of this.filters) {
                const foundSortModelIndex = sortModels.findIndex((s) => s.colId === filter.propertyNameMap.propertyPath)
                if (foundSortModelIndex > -1) {
                    filter.orderBy = sortModels[foundSortModelIndex].sort === 'asc' ? OrderByType.Ascending : OrderByType.Descending;
                    filter.orderByIndex = foundSortModelIndex;
                } else {
                    filter.orderBy = null;
                    filter.orderByIndex = null;
                }
            }
        } else {
            for (const filter of this.filters) {
                filter.orderBy = null;
                filter.orderByIndex = null;
            }
        }

        const filterUnchanged = this.filters.every((f, i) => {
            if(oldFiltersSort[i].colId === f.propertyNameMap.propertyPath) {
                return oldFiltersSort[i].orderBy == f.orderBy && oldFiltersSort[i].orderByIndex == f.orderByIndex;
            }
            return false;
        })
        
        if (!filterUnchanged) {
            this.onSortChangedFromExternal.next();
        }
    }

    getCurrentOptions(): ZoomAdvancedOptions {
        if (this._refreshFilter) {
            this.refreshCurrentOutputProperties();
            this._currentOption.filters.length = 0;

            const currentFilters = this.getCurrentFindFilters();
            this._currentOption.filters = currentFilters.length > 0 ?
                [...this._currentOption.filters, ...currentFilters] : this._currentOption.filters;

            this._currentOption.orderByPropertyNames = this.getOrderByPropertyNames();

            this._refreshFilter = false;
        }

        return this._currentOption;
    }

    getOrderByPropertyNames(): OrderBy[] {
        const orderBy: OrderBy[] = [];
        this.filters.forEach(par => {
            if (par.orderBy != null) {
                const orderByObj = new OrderBy({
                    propertyName: par.propertyNameMap.propertyPath,
                    sortType: par.orderBy
                })
                orderBy.push(orderByObj);
            }
        })

        orderBy.sort((a, b) => {
            const zoomA = this.filters.find(z => z.propertyNameMap.propertyPath === a.propertyName);
            const zoomB = this.filters.find(z => z.propertyNameMap.propertyPath === b.propertyName);

            if (zoomA.orderByIndex > zoomB.orderByIndex) {
                return 1;
            } else {
                return -1;
            }
        });

        return orderBy;
    }

    // In apertura preseleziono tutte le identity di tutti gli external selezionati
    private fixSelectedExternals() {
        for (const filter of this.filters) {
            if (filter.isExternal && filter.isSelected) {
                if (filter.children?.length > 0) {
                    for (const child of filter.children) {
                        if (child.metaData.isIdentity) {
                            child.isSelected = true;
                        }
                    }
                }
            }
        }
    }

    private refreshCurrentOutputProperties(): string[] {
        this._currentOption.outputProperties = [];
        this.filters.forEach((f) => {
            if (f.isExternal && f.isSelected) {
                

                const externalIdentitiesWithPath = this.getOutputPropertiesFromExternalFilter(f as ExternalZoomFilterViewModel, f.isExternalRemote);
                this._currentOption.outputProperties = [...this._currentOption.outputProperties, ...externalIdentitiesWithPath]
            } else if (f.isInternal) {
                // skip internal property
            } else if (f.isSelected || (f.isRootIdentity && !f.isExternal)) {
                this._currentOption.outputProperties.push(f.propertyNameMap.propertyPath);
            }
        });

        this._currentOption.outputProperties = [...new Set(this._currentOption.outputProperties)]
        return this._currentOption.outputProperties;
    }

    getOutputPropertiesFromExternalFilter(filter: ExternalZoomFilterViewModel, useBackingFields = true) {

        let externalIdentities = [];

        if (useBackingFields) {
            const parentPath: string = filter.propertyNameMap.propertyPath
                .split('.')
                // remove last array element
                .slice(0, -1)
                .join('.')

            // Utilizzo invece i suoi backingFields
            if (filter.metaData.external.associationProperties?.length > 0) {

                for (const association of filter.metaData.external.associationProperties) {
                    externalIdentities.push(parentPath?.length > 0 ? parentPath + '.' + association.principalPropertyName : association.principalPropertyName);
                }
                
            } else {
                for (const identityName of filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames) {
                    externalIdentities.push(parentPath?.length > 0 ? parentPath + '.' + identityName : identityName);
                }
            }

        } else {
            const identities = filter.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames;
            externalIdentities = identities.map((identityName: string) => `${filter.propertyNameMap.propertyPath}.${identityName}`);
        }
        
        return externalIdentities;
    }

    private isFilterDate(filterValue: ZoomPropertyViewModelInterface<Date>) {
        return filterValue instanceof DateTimeZoomPropertyViewModel;
    }
    
    private isFilterDateTimeOffset(filterValue: ZoomPropertyViewModelInterface<DateTimeOffset>) {
        return filterValue instanceof DateTimeOffsetZoomPropertyViewModel;
    }
    
    private isFilterEnum(filterValue: ZoomPropertyViewModelInterface<number>) {
        return filterValue instanceof EnumZoomPropertyViewModel;
    }

    private isFilterString(filterValue: ZoomPropertyViewModelInterface<string>) {
        return filterValue instanceof StringZoomPropertyViewModel;
    }
    
    private  isFilterNumeric(filterValue: ZoomPropertyViewModelInterface<number>) {
        return filterValue instanceof NumericZoomPropertyViewModel;
    }

    private getCurrentFindFilters(): Filter[] {

        let findFilters = [];

        this.filters.forEach(par => {
            if (par.operator.currentValue !== FilterOperators.None) {

                if (par.operator.currentValue === FilterOperators.IsNull || par.operator.currentValue === FilterOperators.IsNotNull) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;

                    if (par.isExternal) {
                        const identiesFilters = this.getIdentitiesFilterFromExternalFilter(par as ExternalZoomFilterViewModel, false, par.isExternalRemote);
                        if (identiesFilters?.length > 0) {
                            findFilters = [...findFilters, ...identiesFilters];
                        }

                    } else {
                        findFilters.push(filter);
                    }

                    
                } else if (par.operator.currentValue === FilterOperators.In && (this.isFilterEnum(par.filterValue as ZoomPropertyViewModelInterface<number>) || this.isFilterNumeric(par.filterValue as ZoomPropertyViewModelInterface<number>))) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    const zfv = par as NumericZoomFilterViewModel|EnumZoomFilterViewModel;
                    filter.values = zfv.filterValues?.length > 0 ? zfv.filterValues.filter((f) =>f.value != null).map(f => f.value) : [];
                    if (filter.values?.length > 0) {
                        findFilters.push(filter);
                    } 
                } else if (par.operator.currentValue === FilterOperators.In && this.isFilterString(par.filterValue as ZoomPropertyViewModelInterface<string>)) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    const zfv = par as StringZoomFilterViewModel;
                    filter.values = zfv.filterValues?.length > 0 ? zfv.filterValues.filter((f) =>f.value != null).map(f => f.value) : [];
                    if (filter.values?.length > 0) {
                        findFilters.push(filter);
                    } 
                } else if (par.operator.currentValue === FilterOperators.In && (this.isFilterDate(par.filterValue as ZoomPropertyViewModelInterface<Date>))) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    const zfv = par as DateTimeZoomFilterViewModel;
                    filter.dateTimeValues = zfv.filterDateTimeValues?.length > 0 ? zfv.filterDateTimeValues.filter((f) =>f.value as Date != null).map(f => f.value as Date) : [];
                    findFilters.push(filter);
                    if (filter.dateTimeValues?.length > 0) {
                        findFilters.push(filter);
                    } 
                } else if (par.operator.currentValue === FilterOperators.In && (this.isFilterDateTimeOffset(par.filterValue as ZoomPropertyViewModelInterface<DateTimeOffset>))) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    const zfv = par as DateTimeZoomFilterViewModel;
                    filter.dateTimeOffSetValues = zfv.filterDateTimeValues?.length > 0 ? zfv.filterDateTimeValues.filter((f) =>f.value as DateTimeOffset != null).map(f => f.value as DateTimeOffset) : [];
                    if (filter.dateTimeOffSetValues?.length > 0) {
                        findFilters.push(filter);
                    }                   
                } else if (
                    par.operator.currentValue === FilterOperators.Between &&
                    (
                        (
                            par.filterValue.value !== undefined &&
                            par.filterValue.value !== null &&
                            !(typeof (par.filterValue.value) === 'string' && par.filterValue.value === '')
                        ) ||
                        (par.filterVariable != undefined)
                    ) &&
                    (
                        (
                            par.filterValue2.value !== undefined &&
                            par.filterValue2.value !== null &&
                            !(typeof (par.filterValue2.value) === 'string' && (par.filterValue2.value as string) === '')
                        ) ||
                        (par.filterVariable2 != undefined)
                    )
                ) {
                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    if (par.filterValue.value != null) {
                        filter.value = par.filterValue.value;
                        // gestisco DateTimeOffset
                        if (par.filterValue.value instanceof DateTimeOffset) {
                            filter.dateTimeOffSetValue = new DateTimeOffset((par.filterValue.value as DateTimeOffset).dateTime, (par.filterValue.value as DateTimeOffset).offset);
                            filter.value = null;
                        } else if (par.filterValue.value instanceof Date) {
                            filter.dateTimeValue = par.filterValue.value;
                            filter.value = null;
                        }
                    }
                    // filtri con variabili
                    if (par.filterVariable != null) {
                        filter.runtimeVariable = par.filterVariable;
                    }
                    if (par.filterValue2.value != null) {
                        filter.upperValue = par.filterValue2.value;
                        // gestisco DateTimeOffset 2
                        if (par.filterValue2.value instanceof DateTimeOffset) {
                            filter.dateTimeOffSetUpperValue = new DateTimeOffset((par.filterValue2.value as DateTimeOffset).dateTime, (par.filterValue2.value as DateTimeOffset).offset);
                            filter.upperValue = null;
                        } else if (par.filterValue2.value instanceof Date) {
                            filter.dateTimeUpperValue = par.filterValue2.value;
                            filter.upperValue = null;
                        }
                    }
                    // filtri con variabili 2
                    if (par.filterVariable2 != null) {
                        filter.runtimeVariableUperrValue = par.filterVariable2;
                    }
                    findFilters.push(filter);

                } else if ((par.operator.currentValue !== FilterOperators.In && 
                    par.operator.currentValue !== FilterOperators.Between &&
                    par.filterValue.value !== undefined
                    && par.filterValue.value !== null
                    && !(typeof (par.filterValue.value) === 'string' && (<String>par.filterValue.value) === '') || par.filterVariable != null)
                ) {

                    const filter = new Filter();
                    filter.name = par.propertyNameMap.propertyPath;
                    filter.operator = par.operator.currentValue;
                    if (par.filterValue.value != null) {
                        filter.value = par.filterValue.value;
                        // gestisco DateTimeOffset
                        if (par.filterValue.value instanceof DateTimeOffset) {
                            filter.dateTimeOffSetValue = new DateTimeOffset((par.filterValue.value as DateTimeOffset).dateTime, (par.filterValue.value as DateTimeOffset).offset);
                            filter.value = null;
                        } else if (par.filterValue.value instanceof Date) {
                            filter.dateTimeValue = par.filterValue.value;
                            filter.value = null;
                        }
                    }
                    // filtri con variabili
                    if (par.filterVariable != null) {
                        filter.runtimeVariable = par.filterVariable;
                    }

                    if (par.isExternal) {
                        const identiesFilters = this.getIdentitiesFilterFromExternalFilter(par as ExternalZoomFilterViewModel);
                        if (identiesFilters?.length > 0) {
                            findFilters = [...findFilters, ...identiesFilters];
                        }

                    } else {
                        findFilters.push(filter);
                    }

                }
            }
        });
        return findFilters;
    }

    getIdentitiesFilterFromExternalFilter(par: ExternalZoomFilterViewModel, setValue = true, useBackingFields = true) {
        
        const filters = [];

         if (!useBackingFields) {
            // Utilizzo le identity dentro il ref

            for (const identityName of par.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames) {
            
                const filter = new Filter();
                filter.name = par.propertyNameMap.propertyPath + '.' + identityName;
                filter.operator = par.operator.currentValue;
    
                if (setValue && par.filterValue?.value?.identity) {
                    const identitiesKeys = Object.keys(par.filterValue.value.identity);
                    const foundKey = identitiesKeys.find((key) =>key.toLowerCase() === identityName.toLowerCase());
                    if (foundKey) {
                        filter.value = par.filterValue.value.identity[foundKey];
                    }
                    filters.push(filter);
                }  else if(setValue === false) {
                    filters.push(filter)
                }
            }
         } else {
            // Utilizzo i suoi backingFields

            const parentPath: string = par.propertyNameMap.propertyPath
                        .split('.')
                        // remove last array element
                        .slice(0, -1)
                        .join('.');

            const identitiesKeys = par.filterValue?.value?.identity ? Object.keys(par.filterValue?.value?.identity) : [];

            
            if (par.metaData.external.associationProperties?.length > 0) {

                for (const association of par.metaData.external.associationProperties) {
                    
                    const filter = new Filter();
                    filter.name = parentPath?.length > 0 ? parentPath + '.' + association.principalPropertyName : association.principalPropertyName;
                    filter.operator = par.operator.currentValue;
                    
                    if (setValue &&par.filterValue?.value?.identity) {
                        const foundKey = identitiesKeys.find((key) =>key.toLowerCase() === association.dependentPropertyName.toLowerCase());
                        if (foundKey) {
                            if (setValue) {
                                filter.value = par.filterValue?.value?.identity[foundKey];
                            }
                            
                            filters.push(filter);
                        }
                    } else if(setValue === false) {
                        filters.push(filter)
                    }                
                }
                
            } else {
                for (const identityName of par.metaData.external.dependentAggregateMetaData.rootMetaData.identityNames) {
                    
                    const filter = new Filter();
                    filter.name = parentPath?.length > 0 ? parentPath + '.' + identityName : identityName;
                    filter.operator = par.operator.currentValue;
                    
                    if (setValue && par.filterValue?.value?.identity) {
                        const foundKey = identitiesKeys.find((key) =>key.toLowerCase() === identityName.toLowerCase());
                        if (foundKey) {
                            if (setValue) {
                                filter.value = par.filterValue?.value?.identity[foundKey];
                            }
                            
                            filters.push(filter);
                        }
                    } else if(setValue === false) {
                        filters.push(filter)
                    }                
                }
            }
        }
        
        return filters;
    }

    getCurrentOptionsToStore(): FilterCollection {
        let filterToStore = new FilterCollection();

        const currentFilters = this.getCurrentFindFilters();
        filterToStore = currentFilters.length > 0 ?
            [...currentFilters] : filterToStore;
        return filterToStore;
    }

    /* Gestione filtri avanzati */
    private setAdvancedFilters() {
        this.showAdvancedFilters = true;
        this.updateFilters();
    }

    toggleAdvancedFilters() {
        this.showSortingGroups = false;
        this.showAdvancedFilters = !this.showAdvancedFilters;
        this.updateFilters();
    }

    toggleSelectionsFilterOptions() {
        this.showSelectionsFilterOptions = !this.showSelectionsFilterOptions
        this.showSortingGroups = false;
    }

    toggleSortingGroups() {
        this.showSortingGroups = !this.showSortingGroups;
        this.showFilterWithPopulatedOperator = false;
        this.showSelectionsFilterOptions = false;
        this.showAdvancedFilters = false;
        this.updateFilters();
    }

    toggleFilterWithPopulatedOperator() {
        this.showFilterWithPopulatedOperator = !this.showFilterWithPopulatedOperator
        this.showSortingGroups = false;
    }

    // TODO attenzione per il momento scendo solo di un livello, sarebbe da fare ricorsiva
    expandAllChildrenFilters() {
        this.filters.forEach((f: ZoomFilterViewModel) => {
            if (f.children && f.children.length) {
                f.isCollapsed = false;
            }
        })
    }

    // TODO attenzione per il momento scendo solo di un livello, sarebbe da fare ricorsiva
    collapseAllChildrenFilters() {
        this.filters.forEach((f: ZoomFilterViewModel) => {
            if (f.children && f.children.length) {
                f.isCollapsed = true;
            }
        })
    }

    updateFilters() {
        // Aggiungi/rimuovi fra gli operatori il contiene e il compreso fra
        this.filters.forEach((filter) => {
            filter.operator.advancedOperatorValuesChanged.next(this.showAdvancedFilters);
        });
        // Se deseleziono 'Avanzate' faccio ripristina
        if (!this.showAdvancedFilters) {
            this.resetCommand.execute();
        }
    }

    async showVariables(filter: ZoomFilterViewModel) {
        filter.showVariables = !filter.showVariables;
        if (filter.showVariables) {
            await filter.filterValue.resetValue();
        } else {
            filter.filterVariableValue = null;
            filter.filterVariable = null;
        }
        this.notifyModified();
    }

    async showVariables2(filter: ZoomFilterViewModel) {
        filter.showVariables2 = !filter.showVariables2;
        if (filter.showVariables2) {
            await filter.filterValue2.resetValue();
        } else {
            filter.filterVariable2Value = null;
            filter.filterVariable2 = null;
        }
        this.notifyModified();
    }

    onFilterVariableBlur(filter: ZoomFilterViewModel) {
        if (filter.filterVariableValue == null) {
            filter.filterVariable = null;
        } else {
            setTimeout(() => {
                filter.filterVariableValue.value = filter.filterVariable;
            });
        }
        this.notifyModified();
    }

    onFilterVariableChange(filter: ZoomFilterViewModel) {
        if (filter.filterVariableValue != null) {
            filter.filterVariable = filter.filterVariableValue.value;
        } else {
            filter.filterVariable = null;
        }
        this.notifyModified();
    }

    onFilterVariable2Blur(filter: ZoomFilterViewModel) {
        if (filter.filterVariable2Value == null) {
            filter.filterVariable2 = null;
        } else {
            setTimeout(() => {
                filter.filterVariable2Value.value = filter.filterVariable2;
            });
        }
        this.notifyModified();
    }

    onFilterVariable2Change(filter: ZoomFilterViewModel) {
        if (filter.filterVariable2Value != null) {
            filter.filterVariable2 = filter.filterVariable2Value.value;
        } else {
            filter.filterVariable2 = null;
        }
        this.notifyModified();
    }
}
