import { Expose } from "@nts/std/serialization";

export class UIInfo {

    @Expose()
    baseAddress: string;

    @Expose()
    fullAddress: string;

    // serviceType: ServiceTypes;

    // clientType: ClientTypes;

    // enviromentType: EnvType;

}
