import { CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { LogService } from '@nts/std/utility';
import { ColumnInfo } from '../../../../src/lib/view-models/column-info';
import { ColumnInfoCollection } from '../../../../src/lib/view-models/column-info-collection';
import { GridSettingsModalViewModel } from '../../../../src/lib/view-models/modal/grid-settings-modal-view-model';
import { ModalComponentInterface } from '../../../../src/lib/components/modal/modal-component.interface';
import { CheckboxComponent } from '../../../../src/lib/components/shared/checkbox/checkbox.component';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'nts-grid-settings-modal-container',
    templateUrl: 'grid-settings-modal-container.component.html',
    styleUrls: ['./grid-settings-modal-container.component.scss'],
    standalone: true,
    imports: [
        CheckboxComponent,
        NgIf,
        FormsModule,
        NgFor,
        CdkDrag
    ]
})

export class GridSettingsModalContainerComponent implements OnInit, ModalComponentInterface {
    viewModel: GridSettingsModalViewModel;

    async ngOnInit(): Promise<void> {
        await this.viewModel.initialize();
    }

    drop(event: any) {
        moveItemInArray(this.viewModel.columns, event.previousIndex, event.currentIndex);
        this.viewModel.columnsChanged.next();
    }

    filterFunction(): ColumnInfoCollection {
        if (this.viewModel.filterText.length > 1) {
            return this.viewModel.columns.filter(c => (<string>c.header).toLocaleLowerCase().indexOf(this.viewModel.filterText.toLocaleLowerCase()) > -1) as ColumnInfoCollection;
        } else {
            return this.viewModel.columns;
        }
    }

    onColumnChangeEvent(isVisible: boolean, col: ColumnInfo) {
        col.isVisible = isVisible;
        this.viewModel.allColumnsHide = !this.viewModel.columns.every((c) => c.isVisible === true);
        this.viewModel.columnsChanged.next();
    }

    debug(i: any) {
        LogService.debug(i)
    }
}
