import { NTSReflection } from '@nts/std/utility';
import { NotString } from '../../types';

const WING_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'wingViewModelTypeDecoratorMetadataKey';

export function WingViewModelTypeDecorator<T>(wingViewModelType: NotString<T>) {

    return (target: any) => {
        Reflect.defineMetadata(WING_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, wingViewModelType, target);
    };
}

export class WingViewModelTypeInspector {

    static META_DATA_KEY = WING_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@WingViewModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            WING_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            WING_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
