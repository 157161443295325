import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ZoomParametersViewModel } from '../../../view-models/zoom/zoom-parameters-view-model';
import { StringZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/string-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../../../view-models/zoom/property-view-model/zoom-property-view-model.interface';
import { NumericZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/numeric-zoom-property-view-model';
import { ZoomFilterViewModel } from '../../../view-models/zoom/filter-view-model/zoom-filter-view-model';
import { trigger, transition, style, animate } from '@angular/animations';
import { BoolZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/bool-zoom-property-view-model';
import { DateTimeZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/date-time-zoom-property-view-model';
import { DateTimeOffsetZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/date-time-offset-zoom-property-view-model';
import { EnumZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/enum-zoom-property-view-model';
import { FilterOperators } from '../../../domain-models/find-options/filter';
import { CollapsableFilterViewModelInterface } from '../../../view-models/zoom/filter-view-model/collapsable-filter-view-model.interface';
import { GuidZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/guid-zoom-property-view-model';
import { FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';
import { AsyncPipe, CommonModule, JsonPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { ZoomAvailablesFilterPipe } from '../zoom-availables-filter.pipe.ts';
import { ZoomFilterStringTextBoxComponent } from '../core/zoom-filter-string-text-box/zoom-filter-string-text-box.component';
import { ZoomFilterNumericTextBoxComponent } from '../core/zoom-filter-numeric-text-box/zoom-filter-numeric-text-box.component';
import { ZoomFilterBoolTextBoxComponent } from '../core/zoom-filter-bool-text-box/zoom-filter-bool-text-box.component';
import { ZoomFilterDateTextBoxComponent } from '../core/zoom-filter-date-text-box/zoom-filter-date-text-box.component';
import { ZoomFilterDateOffsetTextBoxComponent } from '../core/zoom-filter-date-offset-text-box/zoom-filter-date-offset-text-box.component';
import { ZoomFilterEnumTextBoxComponent } from '../core/zoom-filter-enum-text-box/zoom-filter-enum-text-box.component';
import { ZoomParametersSortingGroupsComponent } from '../zoom-parameters-sorting-groups/zoom-parameters-sorting-groups.component';
import { BaseEnumTextBoxComponent } from '../../controls/core/base/base-enum-text-box/base-enum-text-box.component';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';
import { LabelBoxComponent } from '../../controls/core/base/label-box/label-box.component';
import { BaseCheckboxTextBoxComponent } from '../../controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
import { DateTimeOffset } from '../../../domain-models/date-time-offset';
import { ZoomFilterOperatorTextBoxComponent } from '../zoom-parameters/zoom-filter-operator-text-box.component';

@Component({
  selector: 'nts-zoom-parameters-mobile',
  templateUrl: './zoom-parameters-mobile.component.html',
  styleUrls: ['zoom-parameters-mobile.component.scss'],
  standalone: true,
  imports: [
    TextButtonComponent,
    NgFor,
    NgIf,
    NgClass,
    ZoomAvailablesFilterPipe,
    ZoomFilterOperatorTextBoxComponent,
    ZoomFilterStringTextBoxComponent,
    ZoomFilterNumericTextBoxComponent,
    ZoomFilterBoolTextBoxComponent,
    ZoomFilterDateTextBoxComponent,
    ZoomFilterDateOffsetTextBoxComponent,
    ZoomFilterEnumTextBoxComponent,
    BaseEnumTextBoxComponent,
    ZoomParametersSortingGroupsComponent,
    NTSTranslatePipe,
    LabelBoxComponent,
    BaseCheckboxTextBoxComponent,
    AsyncPipe,
    JsonPipe,
    CommonModule,
    SlicePipe

  ],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class ZoomParametersMobileComponent implements OnInit, AfterViewInit {

  @Input()
  zoomParametersViewModel: ZoomParametersViewModel;

  private _rowToSetFocus = -1;
  filledButtonTypeEnum = FilledButtonType;
  guidMaskSettings = {
    mask: '\\{********-****-****-****-************\\}', 
    placeholderChar: 'X', 
    lazy: false, 
    hideMaskOnBlur: true, 
    validateMaskOnBlurFunc: (valueToValidate: string) => {
      return /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(valueToValidate);
    }
  }

  @ViewChild('parameterLabels', { static: true }) parameterLabels: ElementRef;
  @ViewChild('parameterValues', { static: true }) parameterValues: ElementRef;

  constructor() {
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.target.dispatchEvent(new Event('blur'));  // (<HTMLInputElement>event.target).blur();
      this.zoomParametersViewModel.findEmitted.next();
    }
  }

  ngOnInit() {
    if (!this.zoomParametersViewModel) { throw new Error('Missing viewModel!'); }

    this._rowToSetFocus = this.zoomParametersViewModel.rowToSetFocus;
    this.zoomParametersViewModel.paramtersUpdated.subscribe(() => {
      this.setFocus();
    });

    // this.zoomNotifierService.startZoomRequested.subscribe((args: ZoomUIStarterArgs) => {
    //   this.setFocus();
    // });
  }

  hasChildren(filter: ZoomFilterViewModel) {
    return filter.children?.length > 0;
  }

  toggleChildrenVisibility(filter: CollapsableFilterViewModelInterface) {
    filter.toggleCollapsed();
  }

  toggleExpandedFilter(filter: CollapsableFilterViewModelInterface) {
    filter.toggleExpanded();
  }

  isFilterString(filterValue: ZoomPropertyViewModelInterface<string>) {
    return filterValue instanceof StringZoomPropertyViewModel;
  }

  isFilterGuid(filterValue: ZoomPropertyViewModelInterface<string>) {
    return filterValue instanceof GuidZoomPropertyViewModel;
  }

  isFilterNumeric(filterValue: ZoomPropertyViewModelInterface<number>) {
    return filterValue instanceof NumericZoomPropertyViewModel;
  }

  isFilterBool(filterValue: ZoomPropertyViewModelInterface<boolean>) {
    return filterValue instanceof BoolZoomPropertyViewModel;
  }

  isFilterDate(filterValue: ZoomPropertyViewModelInterface<Date>) {
    return filterValue instanceof DateTimeZoomPropertyViewModel;
  }

  isFilterDateTimeOffset(filterValue: ZoomPropertyViewModelInterface<DateTimeOffset>) {
    return filterValue instanceof DateTimeOffsetZoomPropertyViewModel;
  }

  isFilterEnum(filterValue: ZoomPropertyViewModelInterface<number>) {
    return filterValue instanceof EnumZoomPropertyViewModel;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFocus();
    }, 700);
  }

  findExternal(event, filter: ZoomFilterViewModel) {
    if (filter.externalDomainModelNameToZoom !== '') {
      this.parameterFocus(event, filter);
      filter.operator.currentValue = FilterOperators.Equals;
      if (this.zoomParametersViewModel.canFindExternal()) {
        this.zoomParametersViewModel.findExternal();
      }
    }
  }

  setFocus() {
    this._rowToSetFocus = this.zoomParametersViewModel.rowToSetFocus;
    if (this._rowToSetFocus >= 0 && this.zoomParametersViewModel.filters.length > this._rowToSetFocus) {
      // Focus cell
      // setTimeout(() => {
      //   this.filters.toArray()[this._rowToSetFocus].nativeElement.firstElementChild.firstElementChild.focus();
      // });
      // if ((this.zoomParametersViewModel.filters[this._rowToSetFocus].filterValue as ZoomPropertyViewModel<any>).enabled) {
      //   this.zoomParametersViewModel.filters[this._rowToSetFocus].filterValue.setFocus();
      // } else {
        if (this.zoomParametersViewModel.findCommand.isVisible$.value) {
          this.zoomParametersViewModel.findCommandFocus.next();
        }
      // }
    }
  }

  parameterFocus(ev: Event, filter: ZoomFilterViewModel) {
    this.zoomParametersViewModel.selectedZoomFilterViewModel = filter;
  }

  keyDown(ev: any) {
    if (ev.key === 'F6' && ev.ctrlKey) {
      if (this.zoomParametersViewModel.isActiveF6) {
        this.zoomParametersViewModel.findExternal();
      }
    }
  }
}
