import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from './base-identity';
import { IdentityTypeNameDecorator } from './decorators/identity-type-name.decorator';

@IdentityTypeNameDecorator('SnapShotFrameIdentity')
export class SnapShotFrameIdentity extends BaseIdentity {

    private _identity: number;

    @Expose()
    get identity(): number {
        return this.getValue<number>(() => this._identity, 'identity');
    }
    set identity(value: number) {
        this.setValue<number>(() => { this._identity = value; }, value, 'identity');
    }

}
