import { Expose } from "@nts/std/serialization";

/**
 * Associazione valore enumerato e chiave per le risorse in lingua
 */
export class EnumResource<T = number> {

    /**
     * Valore dell'enumerato
     */
    @Expose()
    enumValue: T;

    /**
     * Chiave per recuperare la descrizione del valore dell'enumerato
     */
    @Expose()
    resourceKey: string;

    /**
     * Chiave per recuperare la descrizione del valore dell'enumerato
     */
    @Expose()
    displayValue: string;

    /**
    * Chiave per ricercare la Descrizione estesa nelle risorse
    */
    @Expose()
    descriptionExtKey: string;

    /**
    * Chiave per recuperare la descrizione del valore dell'enumerato
    */
    @Expose()
    displayExtValue: string;

    constructor(
        enumValue?: T,
        resourceKey?: string,
        displayValue?: string,
        descriptionExtKey?: string,
        displayExtValue?: string
    ) {
        this.enumValue = enumValue ?? null;
        this.resourceKey = resourceKey ?? null;
        this.displayValue = displayValue ?? null;
        this.descriptionExtKey = descriptionExtKey ?? null;
        this.displayExtValue = displayExtValue ?? null;
    }
}
