import { PropertyMetaData } from './property-meta-data';
import { PositionMetaDataInterface } from './position-meta-data.interface';
import { NumericMetaData } from './numeric-meta-data';
import { StringMetaData } from './string-meta-data';
import { BoolMetaData } from './bool-meta-data';
import { DateTimeMetaData } from './date-time-meta-data';
import { EnumMetaData } from './enum-meta-data';
import { InternalRelationMetaData } from './internal-relation-meta-data';
import { ExternalMetaData, InternalCollectionMetaData } from './domain-model-meta-data';
import { Expose, Type } from '@nts/std/serialization';
import { GuidMetaData } from './guid-meta-data';
import { OrderByType } from '../domain-models/autocomplete/auto-complete-options';

export class ZoomMetaData implements PositionMetaDataInterface {

    position: number;
    isVisible?: boolean;
    orderBy?: OrderByType;
    orderByIndex?: number;
    isRootIdentity: boolean;
    isIdentity: boolean;

    @Expose()
    @Type(() => PropertyMetaData)
    propertyMetadata: PropertyMetaData;

    @Expose()
    @Type(() => InternalRelationMetaData)
    internalRelation: InternalRelationMetaData;

    @Expose()
    @Type(() => ExternalMetaData)
    external: ExternalMetaData;

    @Expose()
    @Type(() => InternalCollectionMetaData)
    internalCollection: InternalCollectionMetaData;

    @Expose()
    displayName: string;

    @Expose()
    get propertyPath(): string {
        if (this.propertyMetadata) {
            const property = this.propertyMetadata?.name;
            return this.rootPath ? this.rootPath + '.' + property : property;
        } else {
            return '';
        }        
    }

    @Expose()
    @Type(() => ZoomMetaData)
    parent: ZoomMetaData;

    @Expose()
    rootPath: string;

    @Expose()
    @Type(() => NumericMetaData)
    numericMetaData: NumericMetaData;

    @Expose()
    @Type(() => StringMetaData)
    stringMetaData: StringMetaData;

    @Expose()
    @Type(() => GuidMetaData)
    guidMetaData: GuidMetaData;

    @Expose()
    @Type(() => BoolMetaData)
    booleanMetaData: BoolMetaData;

    @Expose()
    @Type(() => DateTimeMetaData)
    dateTimeMetaData: DateTimeMetaData;

    @Expose()
    @Type(() => EnumMetaData)
    enumMetaData: EnumMetaData;

    @Expose()
    level: number;

}
