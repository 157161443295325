import { NTSReflection } from '@nts/std/utility';
import { ClassConstructor } from '@nts/std/serialization';

const EXTERNAL_DOMAIN_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'externalDomainModelTypeDecoratorMetaDataKey';

export function ExternalDomainModelTypeDecorator(externalDomainModelType: ClassConstructor<any>) {
    return (target: any) => {
        Reflect.defineMetadata(EXTERNAL_DOMAIN_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, externalDomainModelType, target);
    };
}

export class ExternalDomainModelTypeInspector {

    static META_DATA_KEY = EXTERNAL_DOMAIN_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ExternalDomainModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            EXTERNAL_DOMAIN_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): ClassConstructor<any> {
        return NTSReflection.getClassMetadata(
            EXTERNAL_DOMAIN_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
