import { HttpClient } from '@angular/common/http';
import { BaseEnumPropertyViewModel } from './enum-property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { TimeZoneDataCollectionProvider } from '@nts/std/timezone';;
import { EnumPropertyViewModelItem } from '../../view-models/base-type/enum-property-view-model';
export abstract class BaseOffsetPropertyViewModel extends BaseEnumPropertyViewModel {
    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
        super(initInfo, valueIsNullable);
    }

    async populateValueDescriptionsAsync(http: HttpClient) {
        const timeZones = await TimeZoneDataCollectionProvider.getTimeZoneDataCollection(http);

        timeZones.forEach(tz => {
            this.valueDescriptions.push(new EnumPropertyViewModelItem(tz.key, tz.description));
        });
    }
}


export class OffsetPropertyViewModel extends BaseOffsetPropertyViewModel {
    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
    }
}

export class NOffsetPropertyViewModel extends BaseOffsetPropertyViewModel {
    showNoneItem = true;

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
    }
}
