import { CoreModel } from './core-model';
import { LongOpIdentity } from './long-op.indentity';

/**
 * Base Class per i modelli delle elaborazioni LongOp o Action
 */
export abstract class BaseActionModel extends CoreModel<LongOpIdentity> {


}
