import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from "@nts/std/serialization";

export class PinIdentityToDashboardDto<TIDentity extends BaseIdentity> {

    @Expose()
    identity: TIDentity;

    @Expose()
    title: string;
}
