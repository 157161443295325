<nts-loader [isVisible]="snapShotListViewModel.actionInProgress"></nts-loader>
<div class="aside-left-control-top-content">
</div>
<div class="aside-left-control-bottom-content">
    <div class="filter-pane-content">
        <nts-grid class="full-column" (rowDoubleClick)="selectRow()" (keydown.enter)="selectRow()"
            (onGridOptionsReady)="gridOptionsReady($event)"    
            [showErrorsInCells]="false" [collectionViewModel]="snapShotListViewModel.searchResult" [isDisabled]="true"
            [autoCreateRow]="false" [minRowNumberHeight]="0" [pagination]="true" [defaultCommandsName]="[]"
            [columns]="snapShotListViewModel.searchResultColumns" (collectionViewModelInitialized)="snapShotListViewModel.collectionViewModelInitialized.next()">
        </nts-grid>
    </div>
</div>