import { Input, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { GridApi, GridOptions } from '@ag-grid-community/core';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnapShotListViewModelInterface } from '../../view-models/snap-shot-list-view-model.interface';
import { LoaderComponent } from '../shared/loader/loader.component';
import { GridComponent } from '../../../../components/controls/grid/grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'nts-snap-shot-list-container',
    templateUrl: './snap-shot-list-container.component.html',
    styleUrls: ['./snap-shot-list-container.component.scss'],
    standalone: true,
    imports: [
        LoaderComponent,
        GridComponent
    ]
})
export class SnapShotListContainerComponent implements OnInit, OnDestroy {

    gridOptions: GridOptions;
    api: GridApi;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _cd: ChangeDetectorRef
    ) { }

    @Input() snapShotListViewModel: SnapShotListViewModelInterface;

    ngOnInit() {
        merge(
            this.snapShotListViewModel.searchCompleted,
            this.snapShotListViewModel.selectionChanged,
            this.snapShotListViewModel.selectionUpdated,
            this.snapShotListViewModel.onActionInProgress
        ).pipe(takeUntil(this.destroy$)).subscribe(
            () => this._cd.detectChanges()
        );

        // Resetto lo scroll alla prima riga se è il primo caricamento
        this.snapShotListViewModel.searchCompleted.pipe(takeUntil(this.destroy$)).subscribe((firstLoad) => {
            if (this.gridOptions && this.snapShotListViewModel.searchResult.length > 0 && firstLoad) {
                this.api.ensureIndexVisible(0);
            }
        })
    }

    gridOptionsReady({gridOptions, api}) {
        this.gridOptions = gridOptions;
        this.api = api;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }

    // Utilizzata per il full screen
    // Se si imposta a false, automaticamnte viene fatta la getbyidentity della selezione che c'era in full screen
    // Questa logica è nel master view model
    selectRow() {
        this.snapShotListViewModel.searchResult.exitFocusGridCommand.execute();
        this._cd.detectChanges();
    }
}
