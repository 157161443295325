import { NgModule } from "@angular/core";
import { EmptyContainerComponent } from "./empty-container/empty-container.component";
import { EmptyContainerWithToastComponent } from "./empty-container-with-toast/empty-container-with-toast.component";
import { HeaderComponent } from "./header/header.component";
import { MainContainerComponent } from "./main-container/main-container.component";
import { MenuListComponent } from "./menu-list/menu-list.component";
import { ListFilterPipe } from "./menu-list/menu-list-filter.pipe";
import { MenuListNewFilterPipe } from "./menu-list-new/menu-list-new-filter.pipe";
import { MenuListNewComponent } from "./menu-list-new/menu-list-new.component";
import { NotificationComponent } from "./notification/notification.component";
import { MenuSearchComponent } from "./menu-search/menu-search.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { ToastMessageComponent } from "./toast-message/toast-message.component";
import { ToolBarComponent } from "./tool-bar/tool-bar.component";
import { ValidationComponent } from "./validation/validation.component";

@NgModule({
    imports: [
        EmptyContainerComponent,
        EmptyContainerWithToastComponent,
        HeaderComponent,
        MainContainerComponent,
        MenuListComponent,
        ListFilterPipe,
        MenuListNewFilterPipe,
        MenuListNewComponent,
        MenuSearchComponent,
        NotificationComponent,
        PageHeaderComponent,
        ToastMessageComponent,
        ToolBarComponent,
        ValidationComponent,
    ],
    exports: [
        EmptyContainerComponent,
        EmptyContainerWithToastComponent,
        HeaderComponent,
        MainContainerComponent,
        MenuListComponent,
        ListFilterPipe,
        MenuListNewFilterPipe,
        MenuListNewComponent,
        MenuSearchComponent,
        NotificationComponent,
        PageHeaderComponent,
        ToastMessageComponent,
        ToolBarComponent,
        ValidationComponent,
    ]
})
export class LayoutModule {

}