import { RootViewModel } from './root-view-model';
import { CoreModel } from '../domain-models/core-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { VersionPropertyViewModel } from './base-type/version-property-view-model';

export class OCCRootViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends RootViewModel<TModel, TIdentity>  {

    get occ(): VersionPropertyViewModel {
        return this.getVersionPropertyViewModel((value) => { this._occ = value; }, this._occ, 'occ');
    }

    private _occ: VersionPropertyViewModel;
    override async postInit() {

        await super.postInit();
    }
}
