import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Dropdown } from "primeng/dropdown";
import { ButtonSize } from "../button-size.enum";
import { DomHandler } from "primeng/dom";
import { BehaviorSubject, Subject, filter } from "rxjs";
import { take } from "rxjs/operators";
import { ExtendedMenuItem } from "../../menu/menu-item-content.component";
import { UICommandInterface } from "../../../../view-models/commands/ui-command.interface";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { PopperHelper } from "@nts/std/utility";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { SvgIconComponent } from "@ngneat/svg-icon";
import { MenuComponent } from "../../menu/menu.component";

@UntilDestroy()
@Component({
    selector: 'nts-text-button',
    templateUrl: './text-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./text-button.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        NgClass,
        SvgIconComponent,
        MenuComponent,
        AsyncPipe
    ]
})
export class TextButtonComponent implements OnInit, OnChanges, OnDestroy {

    @Input() buttonAttributeType: string = 'button';
    @Input() tabIndex: number;
    @Input() icon: string = null;
    @Input() isLoading: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() hasNotification: boolean = false;
    @Input() label: string = null;
    @Input() title: string = null;
    @Input() size: ButtonSize = ButtonSize.Default;
    @Input() commands: UICommandInterface[] = [];
    @Input() additionalClasses = [];
    @Input() isDetachedObservable: BehaviorSubject<boolean> = new BehaviorSubject(false);

    @Output() onClicked: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<Dropdown> = new EventEmitter();
    @Output() onInit: EventEmitter<Dropdown> = new EventEmitter();

    @ViewChild('menu', { static: false }) menu: MenuComponent;
    @ViewChild('button', { static: false }) button: ElementRef;
    @ViewChild(NgxPopperjsDirective, {static: false}) popperInfo: NgxPopperjsDirective;

    menuOpened: Subject<void> = new Subject();
    buttonSize = ButtonSize;
    menuItems: ExtendedMenuItem[] = [];
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    ngOnInit(): void {
        this.menuItems = this.commands.map((c) => {
            return  {
                label: c.displayName,
                title: c.description,
                loading: c.loading$,
                command: () => c.execute(c),
                hightlighted: c.isHighlighted(c)
            } as ExtendedMenuItem
        })
        if (this.isDetachedObservable) {
            this.isDetachedObservable
            .pipe(untilDestroyed(this), filter((isDetached: boolean) => isDetached))
            .subscribe((isDetached) => {
                    if (this.popperInfo && isDetached) {
                        PopperHelper.hide(this.popperInfo);
                    }
                })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Se disabilito il componente nascondo il tooltip
        if (changes['isDisabled']) {
            if (changes['isDisabled']?.currentValue === true && this.popperInfo) {
                PopperHelper.hide(this.popperInfo);
            }
        // Se cambio il title nascondo il tooltip
        } else if (changes['title']) {
            if (changes['title']?.currentValue !== changes['title']?.previousValue) {
                PopperHelper.hide(this.popperInfo);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.popperInfo) {
            PopperHelper.hide(this.popperInfo);
        }        
    }

    buttonClicked(e) {
        if (this.commands?.length > 0) {
            this.menu.toggle(e);
        } else {
            this.onClicked.emit(e);
        }
        if (this.popperInfo) {
            PopperHelper.hide(this.popperInfo);
        }
    }

    onItemKeyDown(event) {

        if (this.commands?.length > 0) {

            switch (event.code) {
                case 'ArrowDown':

                    if (this.menu.visible === true) {
                        this.selectFirstValidMenuItem();
                    } else {
                        this.menuOpened.pipe(take(1)).subscribe(() => {
                            setTimeout(() => this.selectFirstValidMenuItem())
                        })      
                        this.menu.toggle(event);                        
                    }                                                

                    event.preventDefault();
                    break;

                default:
                    break;
            }
        }
    }

    private selectFirstValidMenuItem() {
        const listItem = this.menu.container.children[0].children[0];
        const valid = this.findValidItem(listItem)

        if (valid) {
            valid.children[0].focus();
        }
    }

    private checkValidItem(item) {
        if (item)
            return !DomHandler.hasClass(item, 'p-disabled') && DomHandler.hasClass(item, 'p-menuitem') ? item : null;
        else
            return null;
    }

    private findValidItem(item) {

        const valid = this.checkValidItem(item)

        if (valid) {
            return valid;
        } else {
            const nextItem = item.nextElementSibling;
            if (nextItem) {
                return this.findValidItem(item.nextElementSibling);
            }
            return null;
        }            
    }
}