import { DomainModelCollection } from './domain-model-collection';
import { BaseMultiLanguageModel } from './base-multi-language-model';
import { MultiLanguageIdentityInterface } from './multi-language-identity.interface';
import { BaseIdentity } from './base-identity';

export abstract class MultiLanguageCollection<
    TMlModel extends BaseMultiLanguageModel<TMlIdentity>,
    TMlIdentity extends BaseIdentity & MultiLanguageIdentityInterface> extends DomainModelCollection<TMlModel, TMlIdentity> {

}
