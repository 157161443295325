import { OCCAuditDeactivableModel } from './../../../../domain-models/occ-audit-deactivable-model';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, SimpleChanges, OnChanges, EventEmitter, Output, AfterViewInit, OnDestroy, ChangeDetectorRef, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { ExternalViewModelInterface } from '../../../../../lib/view-models/external-view-model.interface';
import { PropertyViewModelInterface } from '../../../../../lib/view-models/property-view-model.interface';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subject, throttleTime } from 'rxjs';
import { tap, catchError, takeUntil, map, startWith } from 'rxjs/operators';
import { BaseExternalPropertyTextBox } from '../base-external-property-text-box';
import { DropdownPosition, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { UICommandInterface } from '../../../../../lib/view-models/commands/ui-command.interface';
import { CommandFactory } from '../../../../../lib/view-models/commands/command-factory';
import { UICommandSettingsManager } from '../../../../../lib/view-models/commands/ui-command-settings-manager';
import { CommandTypes } from '../../../../../lib/view-models/commands/command-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { PopperHelper } from '@nts/std/utility';
import { NNumericPropertyViewModel } from '../../../../../lib/view-models/base-type/nnumeric-property-view-model';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NTSTranslatePipe } from '../../../pipe/nts-translation-pipe';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TextButtonComponent } from '../../../shared/buttons/text-button/text-button.component';
import { BaseExternalBoxComponent } from '../base/base-external-box/base-external-box.component';


@UntilDestroy()
@Component({
    selector: 'nts-ext-enum-text-box',
    templateUrl: './ext-enum-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./ext-enum-text-box.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgxPopperjsModule,
        NgSelectModule,
        FormsModule,
        AsyncPipe,
        NTSTranslatePipe,
        NgOptionHighlightModule,
        TextButtonComponent,
        NgFor,
        BaseSecurityTextBoxComponent,
        BaseExternalBoxComponent
    ]
})
export class ExtEnumTextBoxComponent extends
    BaseExternalPropertyTextBox<string> implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input() override externalPropertyViewModel: ExternalViewModelInterface;
    @Input() appendTo = 'app-root' //'.nts-document-content';
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() code: PropertyViewModelInterface;
    @Input() decodeDescription: PropertyViewModelInterface;
    @Input() autogenerateCodeDescription = false;
    @Input() enableOutsideClickListener = false;
    @Input() showCodeInDescription = true;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() isLoading = false;
    @Input() isLocked = false;
    @Input() isLockedMessage = 'Campo bloccato';

    @Output() onFinishEditing = new EventEmitter();
    @Output() propertyViewModelInitialized = new EventEmitter<void>();

    @ViewChild('popperInfo', { static: false }) popperInfo: NgxPopperjsDirective;
    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;
    @ViewChild(NgSelectComponent, { static: false }) inputRef: NgSelectComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    dropDownClosed = new Subject<void>();
    dropdownPosition: DropdownPosition = 'bottom';
    itemLoading = false;
    items$: Observable<{ description: string, identity: any, all: any }[]>;
    language: { twoLetterISOLanguageName, cultureName, description };
    languages: Array<{ twoLetterISOLanguageName, cultureName, description }>;
    selectedItem: { description: string, identity: any, all: any } = null;
    selectedItemChanged: Subject<void> = new Subject();
    searchFinished: Subject<void> = new Subject<void>();
    addItemCommand: UICommandInterface;
    viewItemCommand: UICommandInterface;
    copyCommand: UICommandInterface;
    documentClickListener: any;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;
    onExternalBoxValueChange = new Subject<{ identity: { [key: string]: string | number }, description?: string, isValid?: boolean }>();
    updatedExternalValue = new BehaviorSubject<{ identity: { [key: string]: string | number }, description?: string, isValid?: boolean } | null>(null);

    get inputValue(): string {
        return this.code.getValue();
    }

    get input(): HTMLInputElement {
        return this.inputRef.searchInput.nativeElement;
    }

    private dropdownOpening = false;
    private resizeEvent = null;

    constructor(
        cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2
    ) {
        super(cd);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override ngOnChanges(changes: SimpleChanges) {

        this.destroyOnChange$.next(true);

        this.externalPropertyViewModel.externalDomainModelChanged.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkAsyncSelected();
            // this.typeaheadLoading = false;
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.decodeCompleted.pipe(
            this.untilDestroyedThis(),
            takeUntil(this.destroyOnChange$)
        ).subscribe(() => {
            this.checkAsyncSelected();
            this.itemLoading = false;
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.codeProperties.forEach(pvm => {
            
            merge(
                pvm.onErrorStatusChanged,
                pvm.propertyViewModelChanged
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                PopperHelper.hide(this.popperInfo);
                this.cd.detectChanges();

                // this.checkAsyncSelected();
            });

            pvm.decodePendingUpdated.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe((decodePending: boolean) => {
                this.itemLoading = decodePending;
                this.cd.detectChanges();

                // this.checkAsyncSelected();
            });
        });

        this.checkAsyncSelected();

        if (this.autogenerateCodeDescription) {
            this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
            this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
        }

        this.propertyViewModelInitialized.emit();
    }

    ngOnDestroy() {
        this.unbindListener();
    }

    override startPresentation(event: any, showRead: boolean) {
        if (!this.isDisabled && (showRead ? this.externalPropertyViewModel.zoomViewIsEnabled : this.externalPropertyViewModel.zoomAddIsEnabled)) {
            this.inputRef.close();
            super.startPresentation(event, showRead);
        }
    }

    override updateModel() {
        // Override update model
    }

    override async zoom(): Promise<void> {
        this.inputRef.close();
        await super.zoom();
    }

    handleOverridesColors() {
        if (!this.externalPropertyViewModel.defaultColor || !this.externalPropertyViewModel.activeColor || !this.externalPropertyViewModel.hoverColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.externalPropertyViewModel.defaultColor;
        if (this.isActive && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
            this.overrideBorderColor = this.externalPropertyViewModel.activeColor;
        }
        if (this.isHover && !this.isDisabled && this.propertyViewModel.isEnabled && this.externalPropertyViewModel.isEnabled) {
            this.overrideBorderColor = this.externalPropertyViewModel.hoverColor;
        }

        const container = this.inputRef.element.querySelector(".ng-select-container") as any;
        if (container){
            container.style.borderColor = this.overrideBorderColor 
        }

        const dropdown = (this.inputRef.dropdownPanel as any)?._dropdown;
        if (dropdown){
            dropdown.style.borderColor = this.overrideBorderColor;
        }

        const dropdownFooter = (this.inputRef.dropdownPanel as any)?._dropdown?.querySelector(".ng-dropdown-footer");
        if (dropdownFooter){
            dropdownFooter.style.borderColor = this.overrideBorderColor; 
        }
    }

    ngOnInit() {

        const manager = new UICommandSettingsManager();
        this.addItemCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand(
                async (x) => this.startPresentation(null, false),
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null), 
                    map(() =>this.externalPropertyViewModel.zoomAddIsEnabled)),
                null,
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null), 
                    map(() =>this.externalPropertyViewModel.zoomAddIsVisible)),
            ));

        this.viewItemCommand = manager.setUICommand(CommandTypes.ViewItem,
            CommandFactory.createUICommand(
                async (x) => this.startPresentation(null, true),
                () => merge(
                    this.selectedItemChanged,
                    this.externalPropertyViewModel.propertyChanged
                ).pipe(
                    startWith(null), 
                    map(() =>this.selectedItem != null && this.externalPropertyViewModel.zoomViewIsEnabled)),
                null,
                () => this.externalPropertyViewModel.propertyChanged.pipe(
                    startWith(null), 
                    map(() =>this.externalPropertyViewModel.zoomViewIsVisible)),
            ));

        this.copyCommand = manager.setUICommand(CommandTypes.Copy,
            CommandFactory.createUICommand(
                async (x) => {
                    this.copyMessage(this.showCodeInDescription ? this.getCodeAndDescription() : [this.code?.getValue(), this.decodeDescription?.getValue()].filter(s => s?.length > 0). join(' - '));
                    this.inputRef.close();
                },
                () => this.selectedItemChanged.pipe(
                    startWith(null),
                    map(() => this.selectedItem != null)),
                null,
                () => of(true)
            ));

        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }

        if (this.autogenerateCodeDescription) {
            this.code = this.externalPropertyViewModel.codeProperties.values().next().value;
            this.decodeDescription = this.externalPropertyViewModel.automaticDecodeDescription;
        }

        if (!this.code) { throw new Error('Missing viewModel for code!'); }
        if (!this.decodeDescription) { throw new Error('Missing viewModel for decodeDescription!'); }

        this.loadItems();

        if (this.enableOutsideClickListener) {
            this.bindDocumentClickListener();
        }

        this.cd.detectChanges();
    }

    ngAfterViewInit(): void {
        this.init();

        this.handleOverridesColors();
    }

    onDropdownOpen(): void {
        
        fromEvent(document.getElementsByClassName(this.scrollElementClass), 'scroll').pipe(
            this.untilDestroyedThis(),
            takeUntil(this.dropDownClosed),
            throttleTime(1000)
        ).subscribe((e: Event) =>  {
            //this.onFocus(null);
            this.inputRef.close();
        });

        setTimeout(() => {
            this.handleOverridesColors();
        })        
    }

    onDropdownClose(): void {
        this.dropDownClosed.next();
    }

    onClear(e) {
        this.inputRef.close();
        this.onSelectedItem(null)
    }

    isSelectedItemDeactivated(): boolean {
        const dm = this.externalPropertyViewModel.getDomainModel();
        return dm instanceof OCCAuditDeactivableModel ? !dm.isActive : false;
    }

    isItemDeactivated(item): boolean {
        const dm = new this.externalPropertyViewModel.externalDomainModelType();
        if (dm instanceof OCCAuditDeactivableModel) {
            if (item.all) {
                const obj = item?.all.find(i => i.hasOwnProperty('IsActive'));
                if (obj) {
                    return !obj.IsActive;
                }
            }
        }
        return false;
    }

    override onBlur(e: any) {
        super.onBlur(e);
        this.isActive = false;
        this.handleOverridesColors();

        this.popperError?.hide();
        this.popperInfo?.hide();
        
        // if (this.lastInputText != null &&
        //     this.lastInputText !== (this.showCodeInDescription ? this.code.getValue(): (this.decodeDescription.getValue() ?? '')) &&
        //     this.lastInputText !== ''
        // ) {
        //     // Solo nel caso in cui il dropdown non è visibile o se è visibile non ha un elemento preselezionato
        //     if (this.inputRef?.dropdownPanel?.markedItem == null) {
        //         this.selectedItem = null;
        //         this.code.setValue(this.lastInputText);
        //         this.valueChange.emit(this.lastInputText);
        //     } else {
        //         // devo resettare il last input text
        //         this.lastInputText = null;
        //     }
        // }
    }

    private onSelectionInProgress = false;

    async onSelectedItem(selectedItem: { description: string, identity: any, all: any }) {
        this.onSelectionInProgress = true;
        if (selectedItem && selectedItem.identity != null) {
            if (this.code.value !== selectedItem?.identity) {
                await this.externalPropertyViewModel.setCodeValue(selectedItem.identity);  
                this.valueChange.emit(selectedItem?.identity);
                this.selectedItemChanged.next();
            } else {
                this.checkAsyncSelected()
            }

        } else {
            await this.externalPropertyViewModel.setCodeValue(null);
            this.externalPropertyViewModel.reset();
            this.selectedItem = null;
            this.valueChange.emit(selectedItem?.identity);
            this.selectedItemChanged.next();
        }
        this.onSelectionInProgress = false;
    }

    trackByFn(item: { description: string, identity: any, all: any }) {
        return item.identity;
    }

    keyDownFn(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            // Necessario per forzare il blur nella griglia

            // Se ho un elemento marcato nel dropdown
            if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                const markedItem = this.inputRef.dropdownPanel.markedItem.value as { description: string, identity: any, all: any }

                // Se l'identity è diversa da quella selezionata
                if (this.code.getValue()?.toString() !== markedItem.identity.toString()) {
                    this.onSelectedItem(markedItem);
                }

            }

            this.onBlur(null);

            // if (this)
            // setTimeout(() => this.onFocus(null))
        } else if (event.key === 'Del') {
            // TODO se si trova alla poszione zero con il cursore ed esiste un modello cancellare il modello
            // if (this.inputRef.)
        } else if (event.key === 'F6' && event.ctrlKey && this.externalPropertyViewModel.isEnabled) {
            this.zoom();
        }
        return true;
    }

    onFocus(e) {
        this.isActive = true;
        this.handleOverridesColors();
    }

    showErroTooltip(): void {
        if (this.popperError) {
            PopperHelper.show(this.popperError);
        }
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    private isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.isDropDownClicked(event) || this.isSearchInputClicked(event) || this.dropdownOpening ||
            this.isDropdownItem(event) || this.el.nativeElement.contains(event.target));
    }

    private unbindListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }    

    private isDropDownClicked(event: Event): boolean {
        if (this.inputRef.dropdownPanel?.scrollElementRef?.nativeElement) {
            return (this.inputRef?.dropdownPanel as any)?._dropdown.contains(event.target);
        } else {
            return false;
        }
    }

    private isSearchInputClicked(event: Event): boolean {
        if (this.inputRef.searchInput?.nativeElement) {
            return (this.inputRef.searchInput?.nativeElement as any)?.contains(event.target);
        } else {
            return false;
        }
    }

    private isDropdownItem(event): boolean {
        return event?.target?.className?.includes(this.inputRef?.dropdownId);
    }

    private bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();
                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private getCodeAndDescription() {

        return this.decodeDescription.getValue() == null ?
            `${this.code.getValue()}` :
            this.showCodeInDescription ? [this.code?.getValue(), this.decodeDescription?.getValue()].filter(v => v?.length > 0).join(' - ') : this.decodeDescription.getValue();
    }

    private copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private checkAsyncSelected(): void {
        const codeValue = this.code?.getValue();
        const isNullable = this.code instanceof NNumericPropertyViewModel;
        if (this.code?.hasErrors && this.selectedItem?.identity === codeValue) {
            // Se il codice ha errori, e ho già selezionato un elemento con lo stesso codice,
            // non faccio niente
        } else if (codeValue != null && (isNullable ? true : (codeValue !== 0))) {
            this.selectedItem = {
                identity: this.code.getValue(),
                description: this.getCodeAndDescription(),
                all: null
            };

        } else {
            this.selectedItem = null;
        }
        PopperHelper.hide(this.popperInfo);
        this.cd.detectChanges();
    }

    private loadItems() {

        // Quando termina la ricerca
        this.searchFinished.pipe(
            this.untilDestroyedThis(),
        ).subscribe(() => {
            // se esiste un marked item
            if (this.inputRef?.dropdownPanel?.markedItem) {
                // // resetto lastInputText
                if (this.inputRef?.dropdownPanel?.markedItem?.value) {
                    const identity = (this.inputRef?.dropdownPanel?.markedItem?.value as { description: string, identity: any, all: any })?.identity;
                    if (identity) {
                        this.valueChange.emit(identity);
                    }
                }
            }
        });

        // this.items$ = this.externalPropertyViewModel.execExternalList(this.showCodeInDescription)
        //     .pipe(
        //                 map((items) => items.sort((x, y) => {

        //                     // const lowerTerm = term.toLowerCase();

        //                     // if (x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
        //                     //     return x.description.localeCompare(y.description);
        //                     // } else if (x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
        //                     //     return -1;
        //                     // } else if (!x.description.toLowerCase().startsWith(lowerTerm) && y.description.toLowerCase().startsWith(lowerTerm)) {
        //                     //     return 1;
        //                     // } else {
        //                     //     // (!x.description.toLowerCase().startsWith(lowerTerm) && !y.description.toLowerCase().startsWith(lowerTerm)) {
        //                     //     if (x.description.toLowerCase().indexOf(lowerTerm) > -1 && y.description.toLowerCase().indexOf(lowerTerm) == 0) {
        //                     //         return -1;
        //                     //     } else if (x.description.toLowerCase().indexOf(lowerTerm) == 0 && y.description.toLowerCase().indexOf(lowerTerm) > -1) {
        //                     //         return 1;
        //                     //     } else {
        //                     //         return x.description.localeCompare(y.description);
        //                     //     }
        //                     // }
        //                     return x.description.localeCompare(y.description);
        //                 })),
        //                 catchError(() => of([])), // empty list on error
        //                 tap(
        //                     () => {
        //                         this.itemLoading = false;
        //                         this.cd.detectChanges();
        //                         this.searchFinished.next();
        //                     }
        //                 )
        //     )
    }
}
