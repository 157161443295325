import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from '../base-identity';
import { IdentityTypeNameDecorator } from '../decorators/identity-type-name.decorator';

@IdentityTypeNameDecorator('UserLoginIdentity')
export class UserLoginIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}

