import { Component } from "@angular/core";
import { FieldTypes } from "../../../../layout-meta-data";
import { BaseFieldComponent } from "../base-field/base-field.component";

@Component({
    template: '',
    selector: 'nts-simple-field'
})
export abstract class SimpleFieldComponent extends BaseFieldComponent {
    override type = FieldTypes.Simple;
    abstract get multiline(): boolean;
}
