import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import { MaskedPattern } from 'imask';
import { FormsModule } from "@angular/forms";
import { IMaskModule } from "angular-imask";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { RibbonButtonComponent } from "../../../../shared/buttons/ribbon-button/ribbon-button.component";
import { PopperHelper } from "@nts/std/utility";

export interface BaseTextBoxMaskSettings {
    mask: string;
    placeholderChar: string;
    overwrite?: boolean | 'shift';
    lazy?: boolean;
    eager?: boolean;
    hideMaskOnBlur?: boolean;
    validateMaskOnBlurFunc?: (valueToValidate: string) => boolean;
}

@Component({
    selector: 'nts-base-text-box',
    templateUrl: './base-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-text-box.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        IMaskModule,
        NgxPopperjsModule,
        NgClass,
        NgIf,
        RibbonButtonComponent,
        AsyncPipe,
        NgFor
    ]
})
export class BaseTextBoxComponent implements OnInit, OnDestroy, OnChanges {
    @Input() maxLength = 524288;
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() value = '';
    @Input() isReadonly = false;
    @Input() placeholder = '';
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;
    @Input() listenClickOutside = false;
    @Input() maskSettings: BaseTextBoxMaskSettings;

    @Output() onFinishEditing = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onKeyDown = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() onFocusOut = new EventEmitter();
    @Output() onValueChange = new EventEmitter();
    @Output() inputChange = new EventEmitter();
    @Output() maskObject = new EventEmitter();

    @ViewChild('textBox', { static: true }) textBox: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError: NgxPopperjsDirective;

    documentClickListener: any;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;
    mask = null;

    constructor(
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
    ) {}

    initMaskSettings(maskSettings: BaseTextBoxMaskSettings): void {
        if (maskSettings) {

            const mask = new MaskedPattern({
                mask: maskSettings.mask,
                overwrite: maskSettings.overwrite,
                placeholderChar: maskSettings.placeholderChar,
                lazy: maskSettings.lazy,
                eager: maskSettings.eager,
            } as any )
            
            this.mask = { mask };
            this.maskObject.emit(mask);
        }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }

        if (changes['maskSettings'] && changes['maskSettings'].currentValue){
            this.initMaskSettings(this.maskSettings);
        }
        if (changes['errorList']) {
            this.checkPopper();
        }
        if (changes['isDisabled']) {
            this.checkMask();
        }
    }

    checkMask() {
        if (this.maskSettings && this.isDisabled) {
            this.mask.mask.updateOptions({lazy: true});
        } else if (this.maskSettings && !this.isDisabled) {
            this.mask.mask.updateOptions({lazy: this.maskSettings.lazy});
        }
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    ngOnInit() {
        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
        this.handleOverridesColors();  
    }

    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    blur(e): void {
        this.isActive = false;
        if (this.maskSettings?.hideMaskOnBlur) {
            this.mask.mask.updateOptions({lazy: true})
            if (this.maskSettings?.validateMaskOnBlurFunc && !this.maskSettings?.validateMaskOnBlurFunc(this.textBox.nativeElement.value)) {
                this.textBox.nativeElement.value = '';
            }
        }
        this.handleOverridesColors();
        this.popperError?.hide();
        this.onBlur.emit(e);
    }

    focus(e): void {
        this.isActive = true;
        this.handleOverridesColors();
        this.onFocus.emit(e);
    }

    onInputChange(value): void {
        this.inputChange.emit(value);
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    valueChange(value: any): void {
        if (this.mask) {
            this.onValueChange.emit(this.mask?.mask?.unmaskedValue);
        } else {
            this.onValueChange.emit(value);
        }
        
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}