import { Expose } from "@nts/std/serialization";
import { CoreMetaData } from './core-meta-data';
import { MetaDataDescriptions } from './meta-data-descriptions';
import { UserMetaData } from './user-meta-data';

export class PropertyMetaData extends CoreMetaData {

    @Expose()
    isRequired?: boolean;

    @Expose()
    position: number;

    protected _type: string;

    getType(): string {
        return this._type;
    }

    constructor(
        name?: string,
        descriptions?: MetaDataDescriptions,
        userMetaData?: UserMetaData,
        isRequired?: boolean,
        position?: number,
    ) {
        super(
            name,
            descriptions,
            userMetaData
        );
        this.isRequired = isRequired;
        this.position = position;
    }

    override set(data: any) {
        super.set(data);
        if (data?.isRequired != null) {
            this.isRequired = data.isRequired;
        }
        if (data?.position != null) {
            this.position = data.position;
        }        
    }
}
