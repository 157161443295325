import { TimeZoneData } from '@nts/std/timezone';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { DateTimeOffset } from '../../../../domain-models/date-time-offset';
import { NDateTimeOffsetPropertyViewModel, DateTimeOffsetPropertyViewModel } from '../../../../view-models/base-type/date-time-offset-property-view-model';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { BaseDatePropertyComponent } from '../base-date-property-component';
import { BaseDateTextBoxComponent } from '../base/base-date-text-box/base-date-text-box.component';
import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { PopperHelper } from '@nts/std/utility';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-date-offset-text-box',
    templateUrl: './date-offset-text-box.component.html',
    styleUrls: ['./date-offset-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        BaseDateTextBoxComponent,
        BaseSecurityTextBoxComponent,
        AsyncPipe
    ]
})
export class DateOffsetTextBoxComponent extends BaseDatePropertyComponent<DateTimeOffset> implements AfterViewInit, OnChanges, OnInit {

    @Input() isDisabled = false;
    @Input() showOffset = true;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandsName = [];
    @Input() appendTo: string | HTMLDivElement = 'body';
    @Input() tabIndex: number;
    @Input() tzTabIndex = 0;
    @Input() initialChar: string | null = null;
    @Input() listenClickOutside = false;
    @Input() primaryColor = null;

    @Output() onFinishEditing = new EventEmitter();
    @Output() keyDown = new EventEmitter();

    @ViewChild('baseDateTextBox', { static: false }) baseDateTextBox: BaseDateTextBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    override propertyViewModel!: NDateTimeOffsetPropertyViewModel | DateTimeOffsetPropertyViewModel;
    valueDate: Date | null = null;
    todayMidnight = moment().startOf('day').toDate();
    valueTimeZone: TimeZoneData | null = null;
    showButtonBar = false;
    maskSettings = null;

    get isNullable(): boolean {
        return this.propertyViewModel instanceof NDateTimeOffsetPropertyViewModel;
    }

    get inputValue() {
        return this.baseDateTextBox.dateMaskBox.getDateFromString(this.input.value);
    }
    get value(): DateTimeOffset | null {
        if (this.valueDate == null && this.valueTimeZone == null) {
            return null;
        }
        return new DateTimeOffset(this.valueDate, this.valueTimeZone.timeSpan);
    }

    set value(value: DateTimeOffset | null) {
        if (value == null) {
            this.valueDate = null;
            this.valueTimeZone = null;
        } else {
            this.valueDate = value.dateTime;
            this.valueTimeZone = value.timeZoneData;
        }
    }

    // Date
    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseDateTextBox.dateMaskBox.dateBox.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    get inputTimeZone(): HTMLInputElement {
        return this.baseDateTextBox.baseTimeZoneCombo.combo.el.nativeElement.children[0].children[0].children[0];
    }

    private _lastPropertyViewModelValue: Date;

    constructor(
        private readonly http: HttpClient, 
        cd: ChangeDetectorRef
    ) {
        super(cd);
    }
    
    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    ngOnInit(): void {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {

        if (changes['propertyViewModel']) {
            this.setValueFromOutside();
            
            await this.propertyViewModel.populateTimeZonesAsync(this.http);
            
            this.initTimezone(this.propertyViewModel.showTimeZone);

            this.destroyOnChange$.next(true);

            // Rimane in ascolto quando cambia il pvm e il valore
            merge(
                this.propertyViewModel.customGetterValueChanged,
                this.propertyViewModel.propertyViewModelChanged,
                this.propertyViewModel.modelChanged,
                this.propertyViewModel.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.initTimezone(this.propertyViewModel.showTimeZone);

                this.setValueFromOutside();
                // if (this.propertyViewModel.value != null && this.propertyViewModel.value.dateTime !== this.valueDate
                //     && this.propertyViewModel.value.dateTime !== this._lastPropertyViewModelValue) {
                //     this.setValueFromOutside();
                // }
            });

            // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
            this.propertyViewModel.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$),
                filter((args) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
            ).subscribe(() => {
                this.cd.detectChanges();
            });
            
            this.propertyViewModel.onErrorStatusChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$))
                .subscribe(() => {
                    this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.focus(this.propertyViewModel.hasErrors);
                this.input.select();
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.baseDateTextBox?.calendar) {
            this.input.tabIndex = this.tabIndex;

            this.input.onkeydown = (ev: KeyboardEvent) => {
                this.onKeyDown(ev);
            };

            // remove tab index from button
            const button = this.baseDateTextBox.calendar.el.nativeElement.querySelector('button');
            button.setAttribute('tabindex', -1);

            this.maskSettings = this.getSettingForInputMask(!this.propertyViewModel.isRequired);
        }
    }

    onDateSelect($event): void {
        this.valueDate = $event
        
        this.valueChange.emit(this.value);
        if (this.modelUpdate) {
            this.propertyViewModel.setValue(this.value);
        }
        this.cd.detectChanges();
    }
    
    onDateValueChange($event): void {
        this.valueDate = $event
        this.onDateBlur();
    }

    onDateFocus($event): void {
        this.input.select();
    }

    async onDateBlur(): Promise<void> {
            if (!this.isDisabled && this.propertyViewModel.isEnabled) {
                if (this.valueDate == null) {
                    this.valueChange.emit(null);
                    if (this.modelUpdate) {
                        await this.propertyViewModel.resetValue(false);
                        this._lastPropertyViewModelValue = this.propertyViewModel.value?.dateTime ?? null;

                    }
                } else {
                    this.valueChange.emit(this.value);
                    if (this.modelUpdate) {
                        await this.propertyViewModel.setValueAsync(this.value);
                        this._lastPropertyViewModelValue = this.propertyViewModel.value?.dateTime ?? null;
                    }
                }
            }
    }


    onTimeZoneChange(valueTimeZone: TimeZoneData): void {
        // TODO riabilitare per far funzionare correttamente il timezone
        // this.valueTimeZone = valueTimeZone;
        // this.valueChange.emit(this.value);
        // if (this.modelUpdate) {
        //    this.propertyViewModel.setValue(this.value);
        // }
    }

    onTimeZoneBlur(): void {
        // if (this.propertyViewModel.value != null && this.propertyViewModel.value.timeZoneData != null) {
        //     this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === this.propertyViewModel.value.timeZoneData.timeSpan);
        // }
    }

    showErroTooltip(): void {
        if (this.baseDateTextBox?.popperError) {
            PopperHelper.show(this.baseDateTextBox?.popperError)
        }        
    }

    private onKeyDown(ev: KeyboardEvent) {
        this.keyDown.emit(ev);
    }

    private setValueFromOutside(): void {
        if (this.propertyViewModel.value != null) {
            if (this.propertyViewModel.showTimeZone) {
                this._lastPropertyViewModelValue = this.valueDate = moment(this.propertyViewModel.value.dateTime).toDate();
            } else {
                this._lastPropertyViewModelValue = this.valueDate = moment(this.propertyViewModel.value.dateTime).toDate();
            }
        } else if (this.valueDate != null) {
            this.valueDate = null;
        }
        this.cd.detectChanges();
    }

    // timeZone
    private initTimezone(showTimeZone: boolean): void {
        if (this.propertyViewModel.value != null) {
            this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === this.propertyViewModel.value.offset);
        } else {
            this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === this.propertyViewModel.currentOffset);
        }
    }
}
