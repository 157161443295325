import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { CoreModel } from '../../domain-models/core-model';
import { ActionHttpCommunication } from '../action-http-communication';
import { Observable } from 'rxjs';
import { GenericServiceRequest } from '../../requests/generic-service-request';
import { WebApiServiceAgent } from '../web-api-service-agent';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { DraftScheduleInfoDto } from '../../schedule/dto/draft-schedule-info.dto';

export class BaseScheduleLongOpHttpCommunication<
    TBaseLongOpModel extends BaseLongOpModel<TIdentity, TParams, TResult>,
    TIdentity extends BaseIdentity,
    TParams extends CoreModel<TIdentity>,
    TResult extends CoreModel<TIdentity>> extends ActionHttpCommunication {

    private static readonly createDraftScheduleRequestUri: string = 'CreateDraftSchedule';

    constructor(
        agent: WebApiServiceAgent,
        protected longOpModelType: any,
        protected longOpResultType: any,

    ) {
        super(agent);
    }

    createDraftSchedule(model: TBaseLongOpModel): Observable<GenericServiceResponse<DraftScheduleInfoDto>> {
        const request: GenericServiceRequest<TBaseLongOpModel> = new GenericServiceRequest<TBaseLongOpModel>();
        request.requestData = model;
        const response: GenericServiceResponse<DraftScheduleInfoDto> = new GenericServiceResponse<DraftScheduleInfoDto>(DraftScheduleInfoDto);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<TBaseLongOpModel>, GenericServiceResponse<DraftScheduleInfoDto>>(
            BaseScheduleLongOpHttpCommunication.createDraftScheduleRequestUri, request, response);
    }
}
