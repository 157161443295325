import { Expose, Type } from '@nts/std/serialization';
import { AvailableLayoutDto } from "./available-layout-dto";

export class AvailableLayoutsInfoDto {

    constructor() {
        this.availableLayouts = [];
    }

    @Expose()
    @Type(() => AvailableLayoutDto)
    availableLayouts: AvailableLayoutDto[];

}

