import { IdentityTypeDecorator } from '../decorators/identity-type.decorator';
import { Expose, Transform, TransformFnParams } from '@nts/std/serialization';
import { CoreModel } from './core-model';
import { DateTimeOffset } from './date-time-offset';
import { DateDecorator } from './decorators/date.decorator';
import { ModelTypeNameDecorator } from './decorators/model-type-name.decorator';
import { NumberDecorator } from './decorators/number.decorator';
import { StringDecorator } from './decorators/string.decorator';
import { SnapShotFrameIdentity } from './snap-shot-frame-identity';


@IdentityTypeDecorator(SnapShotFrameIdentity)
@ModelTypeNameDecorator('SnapShotFrame')
export class SnapShotFrame extends CoreModel<SnapShotFrameIdentity> {

    private _identity: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'SnapShotFrame_Identity' })
    get identity(): number {
        return this.getValueForIdentityValue<number>(() => this._identity, 'identity');
    }
    set identity(value: number) {
        this.setValueForIdentityValue<number>(() => { this._identity = value; }, value, 'identity');
    }

    private _updateUser: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'std_OCCAuditDeactivableModel_UpdateUser' })
    get updateUser(): string {
        return this.getValue<string>(() => this._updateUser, 'updateUser');
    }
    set updateUser(value: string) {
        this.setValue<string>(() => { this._updateUser = value; }, value, 'updateUser');
    }

    private _updateDate: Date;

    @Expose()
    @DateDecorator({ displayNameKey: 'std_OCCAuditDeactivableModel_UpdateDate' })
    @Transform((params: TransformFnParams) => {
        return DateTimeOffset.transform('updateDate', params);
    })
    get updateDate(): Date {
        return this.getValue<Date>(() => this._updateDate, 'updateDate');
    }
    set updateDate(value: Date) {
        this.setValue<Date>(() => { this._updateDate = value; }, value, 'updateDate');
    }

    private _updateUserCode: number;

    @Expose()
    @StringDecorator({ displayNameKey: 'std_SnapShotFrame_UpdateUserCode' })
    get updateUserCode(): number {
        return this.getValue<number>(() => this._updateUserCode, 'updateUserCode');
    }
    set updateUserCode(value: number) {
        this.setValue<number>(() => { this._updateUserCode = value; }, value, 'updateUserCode');
    }

    private _creationUser: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'std_OCCAuditDeactivableModel_CreationUser' })
    get creationUser(): string {
        return this.getValue<string>(() => this._creationUser, 'creationUser');
    }
    set creationUser(value: string) {
        this.setValue<string>(() => { this._creationUser = value; }, value, 'creationUser');
    }

    private _creationDate: Date;

    @Expose()
    @DateDecorator({ displayNameKey: 'std_OCCAuditDeactivableModel_CreationDate' })
    @Transform((params: TransformFnParams) => {
        return DateTimeOffset.transform('creationDate', params);
    })
    get creationDate(): Date {
        return this.getValue<Date>(() => this._creationDate, 'creationDate');
    }
    set creationDate(value: Date) {
        this.setValue<Date>(() => { this._creationDate = value; }, value, 'creationDate');
    }

    private _creationUserCode: number;

    @Expose()
    @StringDecorator({ displayNameKey: 'std_SnapShotFrame_CreationUserCode' })
    get creationUserCode(): number {
        return this.getValue<number>(() => this._creationUserCode, 'creationUserCode');
    }
    set creationUserCode(value: number) {
        this.setValue<number>(() => { this._creationUserCode = value; }, value, 'creationUserCode');
    }

    constructor(init?: Partial<SnapShotFrame>) {
        super();
        Object.assign(this, init);
    }
}
