<nts-base-text-box 
    *ngIf="useChips == false"
    #baseTextBox
    [ngClass]="{ 'to-user-validation': propertyViewModel.isToUserValidation }"
    [isDisabled]="!filter.isEnabled || !(propertyViewModel.enabled$ | async)"
    [maxLength]="propertyViewModel.metaDataMaxLength"
    [value]="propertyViewModel.value$ | async"
    [maskSettings]="maskSettings"
    (onValueChange)="valueChange($event)"
></nts-base-text-box>
<nts-base-chips-box
    #baseChipsBox
    [isDisabled]="!filter.isEnabled || !(propertyViewModel.enabled$ | async)"
    (onValueChange)="valueChangeForChips($event)"
    [addOnBlur]="true"
    [value]="valueForChips"
    *ngIf="useChips == true"
    [validateBeforeAddFunc]="validateChipsFunc"
    [maskSettings]="maskSettings"
></nts-base-chips-box>