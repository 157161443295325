<nts-base-numeric-box #baseNumericBox
*ngIf="useChips == false" 
    [value]="propertyViewModel.value$ | async"
    [isDisabled]="!filter.isEnabled || !(propertyViewModel.enabled$ | async)"
    (onFocus)="selectAllContent($event)"
    [maskSettings]="this.inputMaskSettings" [minValue]="this.minValue"
    [maxValue]="this.maxValue"></nts-base-numeric-box>
<nts-base-chips-box
    #baseChipsBox
    [isDisabled]="!filter.isEnabled || !(propertyViewModel.enabled$ | async)"
    (onValueChange)="valueChange($event)"
    [value]="valueForChips"
    *ngIf="useChips == true">
</nts-base-chips-box>