import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from "@nts/std/serialization";
import { ServiceRequest } from './service-request';

export class RemoveRequest<TIDentity extends BaseIdentity> extends ServiceRequest {

    @Expose()
    identity: TIDentity;

}
