<ng-template 
    *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent">
</ng-template>

<ng-template #baseComponent>
    <div class="autocomplete-container"
    #popperInfo="popper"
    (mouseenter)="mouseEnter($event)"  
    (mouseleave)="mouseLeave($event)"
    [popper]="selectedItem?.description ?? ''"
    [popperDisabled]="!selectedItem?.description || selectedItem?.description?.length == 0 || (showErrorTooltip && !isDisabled && externalPropertyViewModel?.securityAccess == null && code?.securityAccess == null && code?.hasErrors == true)"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="info"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [popperDelay]="1500"
    [class.zoom-disabled]="isDisabled || !externalPropertyViewModel.zoomSearchIsEnabled || externalPropertyViewModel?.securityAccess != null">
        <ng-select  
            #popperError="popper"
            [popper]="tooltipErrTemplate"
            [popperDisabled]="!(showErrorTooltip && !isDisabled && externalPropertyViewModel?.securityAccess == null && code?.securityAccess == null && code?.hasErrors == true)"
            [popperTrigger]="ngxPopperjsTriggers.hover"
            popperApplyClass="error"
            [popperPlacement]="ngxPopperjsPlacements.TOP"
            [popperPreventOverflow]="false"
            [popperHideOnScroll]="true"
            popperAppendTo="body"
            (open)="onDropdownOpen()"
            (close)="onDropdownClose()"
            (blur)="onBlur($event)" 
            (focus)="onFocus($event)" 
            (clear)="onClear($event)" 
            (change)="onSelectedItem($event)"
            [(ngModel)]="selectedItem"
            [searchable]="false"
            [selectOnTab]="false" 
            [appendTo]="appendTo"
            [readonly]="isDisabled || !code.isEnabled || externalPropertyViewModel?.securityAccess != null" 
            [loading]="itemLoading"
            [multiple]="false"
            [keyDownFn]="keyDownFn.bind(this)" 
            [trackByFn]="trackByFn" [items]="items$ | async"
            [class.deactivated]="isSelectedItemDeactivated()"
            [class.has-error]="showErrorBorder && code.hasErrors"
            class="ext-enum-text-box"
            notFoundText="{{'NessunElementoTrovato' | NTSTranslate}}"
            clearAllText="{{'Pulisci' | NTSTranslate}}" 
            loadingText="{{'CaricamentoInProgress' | NTSTranslate}}"
            dropdownPosition="bottom"
            placeholder="{{'std_NotDefined' | NTSTranslate}}"
            bindLabel="description">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span [attr.title]="item.description" class="ng-option-label" [ngOptionHighlight]="search"
                    [class.deactivated]="isItemDeactivated(item)">{{item.description}}</span>
            </ng-template>
            <ng-template ng-footer-tmp>
                <div class="actions-container">
                    <div class="left-actions">
                        <nts-text-button 
                            *ngIf="copyCommand.isVisible$ | async"
                            [title]="copyCommand.description"
                            [additionalClasses]="[inputRef?.dropdownId]"
                            [isDisabled]="!(copyCommand.canExecute$ | async)" 
                            [icon]="copyCommand.iconClass"
                            (onClicked)="copyCommand.execute()"></nts-text-button>
                        <nts-text-button 
                            *ngIf="viewItemCommand.isVisible$ | async"
                            [title]="viewItemCommand.description"
                            [additionalClasses]="[inputRef?.dropdownId]"    
                            [isDisabled]="!(viewItemCommand.canExecute$ | async)" 
                            [icon]="viewItemCommand.iconClass"
                            (onClicked)="viewItemCommand.execute()"></nts-text-button>
                    </div>
                    <div class="right-actions">
                        <nts-text-button 
                            *ngIf="addItemCommand.isVisible$ | async"
                            [title]="addItemCommand.description" 
                            [label]="addItemCommand.displayName"
                            [additionalClasses]="[inputRef?.dropdownId]"
                            [isDisabled]="!(addItemCommand.canExecute$ | async)" 
                            [icon]="addItemCommand.iconClass"
                            (onClicked)="addItemCommand.execute()"></nts-text-button>
                    </div>
                </div>
            </ng-template>
        </ng-select>
        
    </div>

    <popper-content #tooltipErrTemplate>
        <ng-container *ngFor="let item of code.errors$ | async">
            {{ item }}
        </ng-container>
    </popper-content>
</ng-template>

<!-- <ng-template #baseComponent>
    <nts-base-external-box
        #baseExternalBox
        [errorList]="externalPropertyViewModel?.errors$ | async"
        [showDecodeError]="false"
        [appendTo]="appendTo"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [addItemCommand]="addItemCommand"
        [scrollElementClass]="scrollElementClass"
        [isDisabled]="isDisabled || !externalPropertyViewModel.isEnabled"
        [isLoading]="isLoading"
        [isLocked]="isLocked"
        [isLockedMessage]="isLockedMessage"
        [viewItemCommand]="viewItemCommand"
        [copyCommand]="copyCommand"
        [enableOutsideClickListener]="enableOutsideClickListener"
        [basePlainPascalCaseFixedIdentity]="externalPropertyViewModel.parentPlainIdentity$ | async"
        [showCodeInDescription]="showCodeInDescription"
        [getExactItemFromTermCallback]="getExactItemFromTermCallback.bind(this)"
        [getItemFromIdentityCallback]="getItemFromIdentityCallback.bind(this)"
        [getItemsFromTermCallback]="getItemsFromTermCallback.bind(this)"
        [value]="updatedExternalValue | async"
        (blur)="onBlur()"
        (onFinishEditing)="onFinishEditing.emit()"
        (valueChange)="onExternalBoxValueChange.next($event)"
        >
    </nts-base-external-box>
</ng-template> -->

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"></nts-base-security-text-box>
</ng-template>
