import { OCCModel } from './occ-model';
import { NumberDecorator } from './decorators/number.decorator';
import { Expose } from "@nts/std/serialization";
import { CompanySingleAggregateIdentity } from './company-single-aggregate-identity';

/**
 * Modello per gestire le configurazione che conterranno sempre e solo un aggregato per ditta (una riga).
 */
export class CompanySingleAggregateModel<TCompanySingleAggregateIdentity extends CompanySingleAggregateIdentity> extends OCCModel<TCompanySingleAggregateIdentity> {

    private _companySingleAggregateIdentity: number = 0;

    @Expose()
    @NumberDecorator({ displayNameKey: 'CompanySingleAggregateModel_CompanySingleAggregateIdentity' })
    get companySingleAggregateIdentity(): number {
        return this.getValueForIdentityValue<number>(() => this._companySingleAggregateIdentity, 'companySingleAggregateIdentity');
    }
    set companySingleAggregateIdentity(value: number) {
        this.setValueForIdentityValue<number>(() => { this._companySingleAggregateIdentity = value; }, value, 'companySingleAggregateIdentity');
    }

}
