import { NgModule } from '@angular/core';
import { CoreComponentsModule } from './core/core.module';
import { DecodeTextBoxComponent } from './decode-text-box/decode-text-box.component';
import { SearchTextBoxComponent } from './search-text-box/search-text-box.component';
import { SpacerBoxComponent } from './spacer-box/spacer-box.component';
import { LabelledTextBoxComponent } from './labelled-text-box/labelled-text-box.component';
import { CustomFieldContainerComponent } from './custom-field-container/custom-field-container.component';
import { ExternalListComponent } from './external-list/external-list.component';

@NgModule({
    imports: [
        CoreComponentsModule,
        CustomFieldContainerComponent,
        DecodeTextBoxComponent,
        ExternalListComponent,
        LabelledTextBoxComponent,
        SearchTextBoxComponent,
        SpacerBoxComponent,
    ],
    exports: [
        CoreComponentsModule,
        CustomFieldContainerComponent,
        DecodeTextBoxComponent,
        ExternalListComponent,        
        LabelledTextBoxComponent,
        SearchTextBoxComponent,
        SpacerBoxComponent
    ]
})
export class ControlsComponentModule {
}
