<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-checkbox-text-box 
        #baseCheckboxTextBox 
        (onChange)="change($event)" 
        (onClick)="onClick.emit()"
        [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [showTitleTooltip]="showTitleTooltip"
        [errorList]="propertyViewModel?.getErrors()"
        [isReadOnly]="isReadOnly"
        [isDisabled]="isDisabled || !(propertyViewModel.isEnabled$ | async) || propertyViewModel?.securityAccess != null"
        [label]="propertyViewModel.metadataDescription" 
        [checkStatus]="checkStatus"
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [labelText]="showLabel ? (propertyViewModel.formattedValue$ | async): null"
        [attr.data-property-name]="propertyViewModel.propertyName"
        [attr.data-check-status]="checkStatus">
    </nts-base-checkbox-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"></nts-base-security-text-box>
</ng-template>
