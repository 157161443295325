import { Expose, Type } from '@nts/std/serialization';
import { LayoutDefinitionIdentity } from "./layout-definition.identity";

export class GetAvailableLayoutsDataDto {

    constructor() {
        this.requestedMetaDataLayoutIdentity = new LayoutDefinitionIdentity();
    }

    @Expose()
    @Type(() => LayoutDefinitionIdentity)
    requestedMetaDataLayoutIdentity: LayoutDefinitionIdentity;

}
