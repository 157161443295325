import { AgEditorComponent } from '@ag-grid-community/angular';
import { AfterViewInit, Component, HostListener, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnCellEditorParams } from './column_cell_editor_params.interface';
import { BasePropertyComponent } from '../../../../src/lib/components/controls/core/base-property-component';
import { GridComponent } from '../grid.component';
import { BasePropertyViewModel } from '../../../../src/lib/view-models/base-property-view-model';
import { LogService } from '@nts/std/utility';
import { NewBasePropertyTextBox } from '../../../../src/lib/components/controls/core/new-base-property-text-box';

@Component({
    template: ''
})
export abstract class BaseCellEditorComponent<T> implements AgEditorComponent, AfterViewInit, OnInit {
    
    @Input()
    params: ColumnCellEditorParams;
    
    value: T;

    @ViewChild('textBox', { static: true })
    textBox: BasePropertyComponent<T>;

    get gridContext(): GridComponent {
        return this.params.context;
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.onKeyDown(event);
    }

    parseValue(params: ColumnCellEditorParams) {
        return params.value;
    }

    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    agInit(params: ColumnCellEditorParams): void {
        this.params = params;
        this.value = this.parseValue(params);
    }

    setFocus() {
        setTimeout(() => {
            if (this.textBox) {
                this.textBox.focus();
            }
        }, 50);
    }

    overrideOnFocusOut() {
        setTimeout(() => {
            if (this.textBox) {
                // Fix ag grid double focusout bug on click outside grid
                if (this.textBox instanceof NewBasePropertyTextBox) {
                    this.textBox.onFocusOut = () => { };
                }
            }
        }, 50);
    }

    focusOut(): void {
       
    }

    ngAfterViewInit() {
        this.setFocus();

        this.overrideOnFocusOut();
    }

    getValue() {
        return this.value;
    }

    valueChange(value: any) {
        if (this.value != value) {
            this.value = value;
            // TODO gestire stop editing
            // this.params.stopEditing();
        }

    }

    isCancelBeforeStart() {
        return false;
    }

    private _propertyViewModel: BasePropertyViewModel;

    get propertyViewModel(): BasePropertyViewModel {
        if (!this._propertyViewModel) {
            this._propertyViewModel =  this.params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    return o[i];
                }, this.params.data) as BasePropertyViewModel;
        }
        return this._propertyViewModel;
    }

    onKeyDown(event: KeyboardEvent) {
        const key = event.which || event.keyCode;
        if (key >= 37 && key <= 40) {
            // keyboard navigation
            // this.forcePropertyViewModelUpdate();
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            this.navigateToNextCell(key);
        }
    }

    protected navigateToNextCell(key: number) {
        const KEY_UP = 38;
        const KEY_DOWN = 40;
        const KEY_LEFT = 37;
        const KEY_RIGHT = 39;

        const fc = this.gridContext.api.getFocusedCell();
        LogService.debug('focused cell rowindex ' + fc.rowIndex, 'focused celll column ' + fc.column)
        if (key === KEY_DOWN) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex + 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_UP) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex - 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_LEFT) {
            this.gridContext.api.tabToPreviousCell();
        } else if (key === KEY_RIGHT) {
            this.gridContext.api.tabToNextCell();
        }
    }
}
