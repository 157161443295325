import { CoreModel } from '../domain-models/core-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { BoolPropertyViewModel, DateTimeOffsetPropertyViewModel } from './base-type';
import { OCCAuditDeactivableViewModelInterface } from './occ-audit-deactivable-view-model.interface';
import { OCCRootViewModel } from './occ-root-view-model';

export class OCCAuditDeactivableRootViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends OCCRootViewModel<TModel, TIdentity> implements OCCAuditDeactivableViewModelInterface {

    get updateUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._updateUser = value; }, this._updateUser, 'updateUser');
    }

    get updateDate(): DateTimeOffsetPropertyViewModel {
        return this.getDateTimeOffsetPropertyViewModel((value) => { this._updateDate = value; }, this._updateDate, 'updateDate');
    }

    get creationUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._creationUser = value; }, this._creationUser, 'creationUser');
    }

    get creationDate(): DateTimeOffsetPropertyViewModel {
        return this.getDateTimeOffsetPropertyViewModel((value) => { this._creationDate = value; }, this._creationDate, 'creationDate');
    }

    get isActive(): BoolPropertyViewModel {
        return this.getBoolPropertyViewModel((value) => { this._isActive = value; }, this._isActive, 'isActive');
    }

    private _updateUser: StringPropertyViewModel;
    private _updateDate: DateTimeOffsetPropertyViewModel;
    private _creationUser: StringPropertyViewModel;
    private _creationDate: DateTimeOffsetPropertyViewModel;
    private _isActive: BoolPropertyViewModel;

    override async postInit() {

        await super.postInit();

        this.updateUser.isEnabled = false;

        this.updateDate.isEnabled = false;
        this.updateDate.showTime = true;

        this.creationUser.isEnabled = false;

        this.creationDate.isEnabled = false;
        this.creationDate.showTime = true;

    }
}
