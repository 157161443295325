import { BaseValidation } from './base-validation';
import { ValidationArguments } from 'class-validator';
import { MessageCodes } from '../../../resources/message-codes';
import { LogService } from '@nts/std/utility';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { ValidationErrorCodes } from '../../../resources/validation-error-codes';
import { CodeValueMessageArg } from '../../../resources/code-value-message-arg';

export class RequiredValidator {

    private displayName: string;

    errorMessage: string;
    messageCode: string;

    constructor(displayName: string) {
        this.displayName = displayName;
    }

    validate(value: any): boolean {
        this.errorMessage = this.getMessageWithTag(this.displayName, MessageCodes.PropertyRequired_TAG_NomeCampo, MessageCodes.PropertyRequired);
        this.messageCode = ValidationErrorCodes.IsRequired;
        if (this.isNumber(value)) {
            return value.toString().length > 0;
        }
        if (this.isString(value)) {
            return value.length > 0;
        }
        if (this.isBool(value)) {
            return value === true;
        }
        if (value instanceof Object) {
            return true;
        } else if (value == null) {
            return false;
        }
        LogService.warn('ATTENZIONE: required non gestito!', value);
        return true;
    }

    private isNumber(x: any): x is number {
        return typeof x === 'number';
    }

    private isBool(x: any): x is boolean {
        return typeof x === 'boolean';
    }

    private isString(x: any): x is string {
        return typeof x === 'string';
    }

    getMessageWithTag(displayName: string, tag: string, messageCode: string): string {
        return this.getMessageWithCodeValues(messageCode, [{ code: tag, value: displayName }]);
    }

    getMessageWithCodeValues(baseMessage: string, tags: { code: string, value: string }[]): string {
        const arr = new Array<CodeValueMessageArg>();
        for (const tag of tags) {
            const arg = new CodeValueMessageArg();
            arg.code = tag.code;
            arg.value = tag.value;
            arr.push(arg);
        }
        return MessageResourceManager.Current.getMessageWithArgs(baseMessage, arr);
    }
}

export class RequiredValidation<TValue> extends BaseValidation<TValue> {

    constructor() {
        super(MessageCodes.PropertyRequired);
    }

    validateImplementation(value: TValue, args: ValidationArguments): boolean {

        const validator = new RequiredValidator(this.getDisplayName(args));
        
        if (!validator.validate(value)) {
            this.errorMessage = validator.errorMessage;
            this.messageCode = validator.messageCode;
            return false;
        }
        return true;
    }
}
