import { from, identity, Observable, of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalstorageHelper, LogService, OnlineService, SerializabledDataType, TIMEOUT_CACHE } from '@nts/std/utility';
import { VersionInterface, VersionManager } from './version-manager';

export const VERSION_LOADER_STORAGE_KEY = 'version'
@Injectable({
    providedIn: 'root',
})
export class VersionLoader {

    constructor(
        private http: HttpClient,
        private onlineService: OnlineService
    ) { }

    getVersion(): Observable<any> {

        return from(LocalstorageHelper.getStorageItem(VERSION_LOADER_STORAGE_KEY, undefined, false, false)).pipe(
            switchMap((storedVersion) => {

                let obs: Observable<SerializabledDataType> = null;

                if(this.onlineService.isOnline === false) {
                    if (storedVersion) {
                        // Caso offline e cache presente, ritorna subito l'oggetto storedVersion
                        obs = of(storedVersion)
                    } else {
                        // Caso offline e cache non presente, ritorna oggetto vuoto
                        obs = of({})
                    }
                } else {
                    obs = this.http.get('assets/std/version.json').pipe(
                        catchError(e => {
                            LogService.warn('missing std version file!', e);
                            return of({});
                        }),
                        storedVersion ?
                            // Caso online e cache presente, ritorna l'oggetto storedVersion solo dopo un timeout di TIMEOUT_CACHE
                            timeout({ 
                                first: TIMEOUT_CACHE,
                                with: () => {
                                    return of(storedVersion);
                                }
                            }) :
                            // Caso online e cache non presente, ritorna identity
                            identity
                    )
                }

                return obs.pipe(
                    map(async (version: VersionInterface) => {
                        await LocalstorageHelper.setStorageItem(VERSION_LOADER_STORAGE_KEY, version, undefined, false, false);
                        return VersionManager.Current.version = version;
                    })
                );
            })
        )
    }
}