import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { BaseCellRendererComponent } from '../base_cell_renderer.component';
import { PropertyViewModel } from '../../../../../src/lib/view-models/property-view-model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopperHelper } from '@nts/std/utility';
import { BaseCheckboxTextBoxComponent } from '../../../../../src/lib/components/controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
import { AsyncPipe, NgIf } from '@angular/common';
// create your cellEditor as a Angular component
@UntilDestroy()
@Component({
    selector: 'nts-bool-text-box-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        BaseCheckboxTextBoxComponent,
        AsyncPipe
    ],
    template: `
        <span>
        <div *ngIf="!params?.data" class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </span>
        <nts-base-checkbox-text-box *ngIf="params?.data"
            (click)="onClick($event)"
            (onChange)="change($event)"
            [showErrorBorder]="false"
            [style.height.px]="cellHeight"
            [errorList]="propertyViewModel.errors$ | async"
            [isDisabled]="isDisabled"
            [checkStatus]="propertyViewModel.value$ | async">
        </nts-base-checkbox-text-box>
        <!-- [isDisabled]="!propertyViewModel.isEnabled || params?.colDef?.editable === false || (params?.colDef?.editable != null && $any(params.colDef).editable() == false)" -->
        `,
    styleUrls: [`./bool-cell-renderer.component.scss`]
})
export class BoolCellRendererComponent extends BaseCellRendererComponent implements OnDestroy {

    @ViewChild(BaseCheckboxTextBoxComponent, { static: false }) checkbox: BaseCheckboxTextBoxComponent;

    private destroy$: Subject<boolean> = new Subject<boolean>();
    isDisabled = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);
        if (this.params?.data) {
            const pvm = this.propertyViewModel as PropertyViewModel<boolean>;
            pvm.propertyChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                untilDestroyed(this),
            ).subscribe(() => {
                this.el.nativeElement.parentElement.parentElement.scrollIntoView();
                if (this.propertyViewModel?.hasErrors) {
                    PopperHelper.show(this.checkbox.popperError);
                }
            });

            this.isDisabled = !this.propertyViewModel.isEnabled ||
                params?.colDef?.editable === false ||
                (params?.colDef?.editable != null && params.colDef.editable(params) == false)
        }
    }

    change(value): void {
        if (this.propertyViewModel.isEnabled) {
            this.propertyViewModel.value = value;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }

    onClick(event) {
        if (!this.isDisabled) { 
            event.preventDefault();
            event.stopPropagation();
            const pvm = this.propertyViewModel as PropertyViewModel<boolean>;
            if (this.params.colDef.editable && this.params.columnInfo.isEnabled && pvm.isEnabled) {
                pvm.value = !pvm.value;
            }
        } else {
            this.params.node.setSelected(true, true);
        } 
    }
}
