import { Expose } from "@nts/std/serialization";
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class CustomFieldMetaData extends FieldMetaData {

    constructor() {
        super();
        this.fieldType = FieldTypes.Custom;
    }

    @Expose()
    uniqueId: string;
}
