import { Expose, Type } from '@nts/std/serialization';
import { EnumDataItem } from './enum-data-item';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export enum SimpleFieldType {
    Undefined = 0,
    StringField = 1,
    NumericField = 2,
    DateTimeField = 3,
    DateTimeOffSetField = 4,
    BooleanField = 5,
    GuidField = 6,
    EnumField = 7
}

export interface PrimitiveTypeAwareInterface {
    primitiveType?: SimpleFieldType
}

export interface DefaultValueAwareInterface {
    defaultValue?: string;
}

export class SimpleFieldMetaData extends FieldMetaData implements PrimitiveTypeAwareInterface, DefaultValueAwareInterface {

    constructor() {
        super();
        this.fieldType = FieldTypes.Simple
        this.enumData = [];
    }

    @Expose()
    isMultiLine: boolean;

    @Expose()
    defaultValue?: string;

    @Expose()
    primitiveType?: SimpleFieldType
    
    @Expose()
    @Type(() => EnumDataItem)
    enumData: EnumDataItem[];
}