import { MessageResourceManager } from './../../../resources/message-resource-manager';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { BaseError } from '../../../messages/base-error';
import { UIStarter } from '../../../starter/ui-starter';
import { ComponentErrorInterface } from '../component-error.interface';
import { ChangeDetectionStrategy } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';
import { FilledButtonComponent } from '../../shared/buttons/filled-button/filled-button.component';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';

@Component({
    selector: 'nts-not-found-page',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        NgIf,
        TextButtonComponent,
        FilledButtonComponent,
        NTSTranslatePipe
    ]
})
export class NotFoundComponent implements ComponentErrorInterface, OnInit {

    // popolata tramite injecton
    errors: BaseError[];
    errorTitle: string;
    errorDescription: string;
    errorDetailDescription: string;
    errorDetailMoreDescription: string;
    showDetailMore = false;
    expandDetailMore = false;
    showMoreLimit = 0; // 0 disabilitato

    constructor(
        public readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly router: Router,
        private readonly cd: ChangeDetectorRef
    ) {
        this.errorTitle = MessageResourceManager.Current.getMessage('std_PageError404_Title');
        this.errorDescription = MessageResourceManager.Current.getMessage('std_PageError404_Description');
        this.errorDetailDescription = MessageResourceManager.Current.getMessage('std_PageError404_DetailDescription');
    }

    ngOnInit(): void {
        if (this.errorDetailDescription.length > this.showMoreLimit && this.showMoreLimit > 0) {
            this.errorDetailMoreDescription = this.errorDetailDescription.substring(this.showMoreLimit, this.errorDetailDescription.length);
            this.errorDetailDescription = this.errorDetailDescription.substring(0, this.showMoreLimit);
            this.showDetailMore = true;
        }
        this.cd.detectChanges();
    }

    redirectToDashBoard(): void {
        if (this.environmentConfiguration.production === true) {
            UIStarter.redirectToDashboard();
        } else {
            this.router.navigate(['/']);
        }
    }

    toggleShowMore(): void {
        this.expandDetailMore = !this.expandDetailMore;
    }
}
