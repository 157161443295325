import { Expose } from "@nts/std/serialization";
import { AllowedChars } from './allowed-chars';
import { MetaDataDescriptions } from './meta-data-descriptions';
import { PropertyMetaData } from './property-meta-data';
import { UserMetaData } from './user-meta-data';

export class StringMetaData extends PropertyMetaData {

    protected override _type = 'String';

    /**
     * Eventuali Caratteri ammessi
     */
    @Expose()
    allowedCharacters?: AllowedChars;

    /**
     * Eventuale lunghezza massima
     */
    @Expose()
    maxLen?: number;

    /**
     * Se è una e-mail
     */
    @Expose()
    isEmail?: boolean;

    /**
     * Indica che si tratta della descrizione principale di un RootModel
     */
    @Expose()
    isMainDescription?: boolean;

    /**
     * è una descrizione in lingua
     */
    @Expose()
    isMultiLanguage?: boolean;

    constructor(
        name?: string,
        descriptions?: MetaDataDescriptions,
        userMetaData?: UserMetaData,
        isRequired?: boolean,
        position?: number,
        allowedCharacters?: AllowedChars,
        maxLen?: number,
        isEmail?: boolean,
        isMainDescription?: boolean,
        isMultiLanguage?: boolean,
    ) {
        super(
            name,
            descriptions,
            userMetaData,
            isRequired,
            position,
        );
        this.allowedCharacters = allowedCharacters;
        this.maxLen = maxLen;
        this.isEmail = isEmail;
        this.isMainDescription = isMainDescription;
        this.isMultiLanguage = isMultiLanguage;
    }

    override set(data: any) {
        super.set(data);
        if (data?.allowedCharacters != null) {
            this.allowedCharacters = data.allowedCharacters;
        }
        if (data?.maxLen != null) {
            this.maxLen = data.maxLen;
        }
        if (data?.isEmail != null) {
            this.isEmail = data.isEmail;
        }
        if (data?.isMainDescription != null) {
            this.isMainDescription = data.isMainDescription;
        }
        if (data?.isMultiLanguage != null) {
            this.isMultiLanguage = data.isMultiLanguage;
        }
    }
}
