import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TimeZoneData } from './time-zone-data';
import { TimeZoneDataCollectionCache } from './time-zone-data-collection-cache';
// import { LogService } from '@nts/std/utility';

export class TimeZoneDataCollectionProvider {
    private static instance: TimeZoneDataCollectionProvider;


    static get Instance(): TimeZoneDataCollectionProvider {
        if (this.instance == null) {
            if (this.instance == null) {
                this.instance = new TimeZoneDataCollectionProvider();
            }
        }
        return this.instance;
    }

    private static async createTimeZoneDataCollection(http: HttpClient): Promise<Array<TimeZoneData>> {
        var collection = new Array<TimeZoneData>();

        // TODO dinamico in base alla lingua
        const tzTranslations: any = await firstValueFrom(http.get('assets/i18n/std/it/tz-resource.json').pipe(
            catchError(e => {
                // LogService.warn('missing std timezone translations!', e);
                return of({});
            })
        ));

        collection.push(new TimeZoneData(1, "UTC-12", -720, tzTranslations["TimeZoneDataCollectionProvider_UTC112"]));
        collection.push(new TimeZoneData(2, "DatelineStandardTime", -720, tzTranslations["TimeZoneDataCollectionProvider_DatelineStandardTime"]));
        collection.push(new TimeZoneData(3, "UTC-11", -660, tzTranslations["TimeZoneDataCollectionProvider_UTC111"]));
        collection.push(new TimeZoneData(4, "UTC-10", -600, tzTranslations["TimeZoneDataCollectionProvider_UTC110"]));
        collection.push(new TimeZoneData(5, "AleutianStandardTime", -600, tzTranslations["TimeZoneDataCollectionProvider_AleutianStandardTime"]));
        collection.push(new TimeZoneData(6, "HawaiianStandardTime", -600, tzTranslations["TimeZoneDataCollectionProvider_HawaiianStandardTime"]));
        collection.push(new TimeZoneData(7, "UTC-09:30", -570, tzTranslations["TimeZoneDataCollectionProvider_UTC10930"]));
        collection.push(new TimeZoneData(8, "MarquesasStandardTime", -570, tzTranslations["TimeZoneDataCollectionProvider_MarquesasStandardTime"]));
        collection.push(new TimeZoneData(9, "UTC-09", -540, tzTranslations["TimeZoneDataCollectionProvider_UTC109"]));
        collection.push(new TimeZoneData(10, "AlaskanStandardTime", -540, tzTranslations["TimeZoneDataCollectionProvider_AlaskanStandardTime"]));
        collection.push(new TimeZoneData(11, "UTC-08", -480, tzTranslations["TimeZoneDataCollectionProvider_UTC108"]));
        collection.push(new TimeZoneData(12, "PacificStandardTime(Mexico)", -480, tzTranslations["TimeZoneDataCollectionProvider_PacificStandardTime_Mexico"]));
        collection.push(new TimeZoneData(13, "PacificStandardTime", -480, tzTranslations["TimeZoneDataCollectionProvider_PacificStandardTime"]));
        collection.push(new TimeZoneData(14, "UTC-07", -420, tzTranslations["TimeZoneDataCollectionProvider_UTC107"]));
        collection.push(new TimeZoneData(15, "US MountainStandardTime", -420, tzTranslations["TimeZoneDataCollectionProvider_USMountainStandardTime"]));
        collection.push(new TimeZoneData(16, "MountainStandardTime(Mexico)", -420, tzTranslations["TimeZoneDataCollectionProvider_MountainStandardTime_Mexico"]));
        collection.push(new TimeZoneData(17, "MountainStandardTime", -420, tzTranslations["TimeZoneDataCollectionProvider_MountainStandardTime"]));
        collection.push(new TimeZoneData(18, "UTC-06", -360, tzTranslations["TimeZoneDataCollectionProvider_UTC106"]));
        collection.push(new TimeZoneData(19, "Central AmericaStandardTime", -360, tzTranslations["TimeZoneDataCollectionProvider_CentralAmericaStandardTime"]));
        collection.push(new TimeZoneData(20, "CentralStandardTime", -360, tzTranslations["TimeZoneDataCollectionProvider_CentralStandardTime"]));
        collection.push(new TimeZoneData(21, "Easter IslandStandardTime", -360, tzTranslations["TimeZoneDataCollectionProvider_EasterIslandStandardTime"]));
        collection.push(new TimeZoneData(22, "CentralStandardTime(Mexico)", -360, tzTranslations["TimeZoneDataCollectionProvider_CentralStandardTime_Mexico"]));
        collection.push(new TimeZoneData(23, "Canada CentralStandardTime", -360, tzTranslations["TimeZoneDataCollectionProvider_CanadaCentralStandardTime"]));
        collection.push(new TimeZoneData(24, "UTC-05", -300, tzTranslations["TimeZoneDataCollectionProvider_UTC105"]));
        collection.push(new TimeZoneData(25, "SA PacificStandardTime", -300, tzTranslations["TimeZoneDataCollectionProvider_SAPacificStandardTime"]));
        collection.push(new TimeZoneData(26, "EasternStandardTime(Mexico)", -300, tzTranslations["TimeZoneDataCollectionProvider_EasternStandardTime_Mexico"]));
        collection.push(new TimeZoneData(27, "EasternStandardTime", -300, tzTranslations["TimeZoneDataCollectionProvider_EasternStandardTime"]));
        collection.push(new TimeZoneData(28, "HaitiStandardTime", -300, tzTranslations["TimeZoneDataCollectionProvider_HaitiStandardTime"]));
        collection.push(new TimeZoneData(29, "CubaStandardTime", -300, tzTranslations["TimeZoneDataCollectionProvider_CubaStandardTime"]));
        collection.push(new TimeZoneData(30, "US EasternStandardTime", -300, tzTranslations["TimeZoneDataCollectionProvider_USEasternStandardTime"]));
        collection.push(new TimeZoneData(31, "UTC-04", -240, tzTranslations["TimeZoneDataCollectionProvider_UTC104"]));
        collection.push(new TimeZoneData(32, "ParaguayStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_ParaguayStandardTime"]));
        collection.push(new TimeZoneData(33, "AtlanticStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_AtlanticStandardTime"]));
        collection.push(new TimeZoneData(34, "VenezuelaStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_VenezuelaStandardTime"]));
        collection.push(new TimeZoneData(35, "Central BrazilianStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_CentralBrazilianStandardTime"]));
        collection.push(new TimeZoneData(36, "SA WesternStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_SAWesternStandardTime"]));
        collection.push(new TimeZoneData(37, "Pacific SAStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_PacificSAStandardTime"]));
        collection.push(new TimeZoneData(38, "Turks And CaicosStandardTime", -240, tzTranslations["TimeZoneDataCollectionProvider_TurksAndCaicosStandardTime"]));
        collection.push(new TimeZoneData(39, "UTC-03:30", -210, tzTranslations["TimeZoneDataCollectionProvider_UTC10330"]));
        collection.push(new TimeZoneData(40, "NewfoundlandStandardTime", -210, tzTranslations["TimeZoneDataCollectionProvider_NewfoundlandStandardTime"]));
        collection.push(new TimeZoneData(41, "UTC-03", -180, tzTranslations["TimeZoneDataCollectionProvider_UTC103"]));
        collection.push(new TimeZoneData(42, "TocantinsStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_TocantinsStandardTime"]));
        collection.push(new TimeZoneData(43, "E. South AmericaStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_ESouthAmericaStandardTime"]));
        collection.push(new TimeZoneData(44, "SA EasternStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_SAEasternStandardTime"]));
        collection.push(new TimeZoneData(45, "ArgentinaStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_ArgentinaStandardTime"]));
        collection.push(new TimeZoneData(46, "GreenlandStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_GreenlandStandardTime"]));
        collection.push(new TimeZoneData(47, "MontevideoStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_MontevideoStandardTime"]));
        collection.push(new TimeZoneData(48, "MagallanesStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_MagallanesStandardTime"]));
        collection.push(new TimeZoneData(49, "Saint PierreStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_SaintPierreStandardTime"]));
        collection.push(new TimeZoneData(50, "BahiaStandardTime", -180, tzTranslations["TimeZoneDataCollectionProvider_BahiaStandardTime"]));
        collection.push(new TimeZoneData(51, "UTC-02", -120, tzTranslations["TimeZoneDataCollectionProvider_UTC102"]));
        collection.push(new TimeZoneData(52, "Mid-AtlanticStandardTime", -120, tzTranslations["TimeZoneDataCollectionProvider_MidAtlanticStandardTime"]));
        collection.push(new TimeZoneData(53, "UTC-01", -60, tzTranslations["TimeZoneDataCollectionProvider_UTC101"]));
        collection.push(new TimeZoneData(54, "AzoresStandardTime", -60, tzTranslations["TimeZoneDataCollectionProvider_AzoresStandardTime"]));
        collection.push(new TimeZoneData(55, "Cape VerdeStandardTime", -60, tzTranslations["TimeZoneDataCollectionProvider_CapeVerdeStandardTime"]));
        collection.push(new TimeZoneData(56, "UTC", 0, tzTranslations["TimeZoneDataCollectionProvider_UTC"]));
        collection.push(new TimeZoneData(57, "MoroccoStandardTime", 0, tzTranslations["TimeZoneDataCollectionProvider_MoroccoStandardTime"]));
        collection.push(new TimeZoneData(58, "GMTStandardTime", 0, tzTranslations["TimeZoneDataCollectionProvider_GMTStandardTime"]));
        collection.push(new TimeZoneData(59, "GreenwichStandardTime", 0, tzTranslations["TimeZoneDataCollectionProvider_GreenwichStandardTime"]));
        collection.push(new TimeZoneData(60, "UTC+01", 60, tzTranslations["TimeZoneDataCollectionProvider_UTC001"]));
        collection.push(new TimeZoneData(61, "W. EuropeStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_WEuropeStandardTime"]));
        collection.push(new TimeZoneData(62, "Central EuropeStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_CentralEuropeStandardTime"]));
        collection.push(new TimeZoneData(63, "RomanceStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_RomanceStandardTime"]));
        collection.push(new TimeZoneData(64, "Central EuropeanStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_CentralEuropeanStandardTime"]));
        collection.push(new TimeZoneData(65, "W. Central AfricaStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_WCentralAfricaStandardTime"]));
        collection.push(new TimeZoneData(66, "NamibiaStandardTime", 60, tzTranslations["TimeZoneDataCollectionProvider_NamibiaStandardTime"]));
        collection.push(new TimeZoneData(67, "UTC+02", 120, tzTranslations["TimeZoneDataCollectionProvider_UTC002"]));
        collection.push(new TimeZoneData(68, "JordanStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_JordanStandardTime"]));
        collection.push(new TimeZoneData(69, "GTBStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_GTBStandardTime"]));
        collection.push(new TimeZoneData(70, "Middle EastStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_MiddleEastStandardTime"]));
        collection.push(new TimeZoneData(71, "EgyptStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_EgyptStandardTime"]));
        collection.push(new TimeZoneData(72, "E. EuropeStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_EEuropeStandardTime"]));
        collection.push(new TimeZoneData(73, "SyriaStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_SyriaStandardTime"]));
        collection.push(new TimeZoneData(74, "West BankStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_WestBankStandardTime"]));
        collection.push(new TimeZoneData(75, "South AfricaStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_SouthAfricaStandardTime"]));
        collection.push(new TimeZoneData(76, "FLEStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_FLEStandardTime"]));
        collection.push(new TimeZoneData(77, "IsraelStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_IsraelStandardTime"]));
        collection.push(new TimeZoneData(78, "KaliningradStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_KaliningradStandardTime"]));
        collection.push(new TimeZoneData(79, "SudanStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_SudanStandardTime"]));
        collection.push(new TimeZoneData(80, "LibyaStandardTime", 120, tzTranslations["TimeZoneDataCollectionProvider_LibyaStandardTime"]));
        collection.push(new TimeZoneData(81, "UTC+03", 180, tzTranslations["TimeZoneDataCollectionProvider_UTC003"]));
        collection.push(new TimeZoneData(82, "ArabicStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_ArabicStandardTime"]));
        collection.push(new TimeZoneData(83, "TurkeyStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_TurkeyStandardTime"]));
        collection.push(new TimeZoneData(84, "ArabStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_ArabStandardTime"]));
        collection.push(new TimeZoneData(85, "BelarusStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_BelarusStandardTime"]));
        collection.push(new TimeZoneData(86, "RussianStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_RussianStandardTime"]));
        collection.push(new TimeZoneData(87, "E. AfricaStandardTime", 180, tzTranslations["TimeZoneDataCollectionProvider_EAfricaStandardTime"]));
        collection.push(new TimeZoneData(88, "UTC+03:30", 210, tzTranslations["TimeZoneDataCollectionProvider_UTC00330"]));
        collection.push(new TimeZoneData(89, "IranStandardTime", 210, tzTranslations["TimeZoneDataCollectionProvider_IranStandardTime"]));
        collection.push(new TimeZoneData(90, "UTC+04", 240, tzTranslations["TimeZoneDataCollectionProvider_UTC004"]));
        collection.push(new TimeZoneData(91, "ArabianStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_ArabianStandardTime"]));
        collection.push(new TimeZoneData(92, "AstrakhanStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_AstrakhanStandardTime"]));
        collection.push(new TimeZoneData(93, "AzerbaijanStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_AzerbaijanStandardTime"]));
        collection.push(new TimeZoneData(94, "Russia Time Zone 3", 240, tzTranslations["TimeZoneDataCollectionProvider_RussiaTimeZone3"]));
        collection.push(new TimeZoneData(95, "MauritiusStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_MauritiusStandardTime"]));
        collection.push(new TimeZoneData(96, "SaratovStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_SaratovStandardTime"]));
        collection.push(new TimeZoneData(97, "GeorgianStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_GeorgianStandardTime"]));
        collection.push(new TimeZoneData(98, "CaucasusStandardTime", 240, tzTranslations["TimeZoneDataCollectionProvider_CaucasusStandardTime"]));
        collection.push(new TimeZoneData(99, "UTC+04:30", 270, tzTranslations["TimeZoneDataCollectionProvider_UTC00430"]));
        collection.push(new TimeZoneData(100, "AfghanistanStandardTime", 270, tzTranslations["TimeZoneDataCollectionProvider_AfghanistanStandardTime"]));
        collection.push(new TimeZoneData(101, "UTC+05", 300, tzTranslations["TimeZoneDataCollectionProvider_UTC005"]));
        collection.push(new TimeZoneData(102, "West AsiaStandardTime", 300, tzTranslations["TimeZoneDataCollectionProvider_WestAsiaStandardTime"]));
        collection.push(new TimeZoneData(103, "EkaterinburgStandardTime", 300, tzTranslations["TimeZoneDataCollectionProvider_EkaterinburgStandardTime"]));
        collection.push(new TimeZoneData(104, "PakistanStandardTime", 300, tzTranslations["TimeZoneDataCollectionProvider_PakistanStandardTime"]));
        collection.push(new TimeZoneData(105, "UTC+05:30", 330, tzTranslations["TimeZoneDataCollectionProvider_UTC00530"]));
        collection.push(new TimeZoneData(106, "IndiaStandardTime", 330, tzTranslations["TimeZoneDataCollectionProvider_IndiaStandardTime"]));
        collection.push(new TimeZoneData(107, "Sri LankaStandardTime", 330, tzTranslations["TimeZoneDataCollectionProvider_SriLankaStandardTime"]));
        collection.push(new TimeZoneData(108, "UTC+05:45", 345, tzTranslations["TimeZoneDataCollectionProvider_UTC00545"]));
        collection.push(new TimeZoneData(109, "NepalStandardTime", 345, tzTranslations["TimeZoneDataCollectionProvider_NepalStandardTime"]));
        collection.push(new TimeZoneData(110, "UTC+06", 360, tzTranslations["TimeZoneDataCollectionProvider_UTC006"]));
        collection.push(new TimeZoneData(111, "Central AsiaStandardTime", 360, tzTranslations["TimeZoneDataCollectionProvider_CentralAsiaStandardTime"]));
        collection.push(new TimeZoneData(112, "BangladeshStandardTime", 360, tzTranslations["TimeZoneDataCollectionProvider_BangladeshStandardTime"]));
        collection.push(new TimeZoneData(113, "OmskStandardTime", 360, tzTranslations["TimeZoneDataCollectionProvider_OmskStandardTime"]));
        collection.push(new TimeZoneData(114, "UTC+06:30", 390, tzTranslations["TimeZoneDataCollectionProvider_UTC00630"]));
        collection.push(new TimeZoneData(115, "MyanmarStandardTime", 390, tzTranslations["TimeZoneDataCollectionProvider_MyanmarStandardTime"]));
        collection.push(new TimeZoneData(116, "UTC+07", 420, tzTranslations["TimeZoneDataCollectionProvider_UTC007"]));
        collection.push(new TimeZoneData(117, "SE AsiaStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_SEAsiaStandardTime"]));
        collection.push(new TimeZoneData(118, "AltaiStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_AltaiStandardTime"]));
        collection.push(new TimeZoneData(119, "W. MongoliaStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_WMongoliaStandardTime"]));
        collection.push(new TimeZoneData(120, "North AsiaStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_NorthAsiaStandardTime"]));
        collection.push(new TimeZoneData(121, "N. Central AsiaStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_NCentralAsiaStandardTime"]));
        collection.push(new TimeZoneData(122, "TomskStandardTime", 420, tzTranslations["TimeZoneDataCollectionProvider_TomskStandardTime"]));
        collection.push(new TimeZoneData(123, "UTC+08", 480, tzTranslations["TimeZoneDataCollectionProvider_UTC008"]));
        collection.push(new TimeZoneData(124, "ChinaStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_ChinaStandardTime"]));
        collection.push(new TimeZoneData(125, "North Asia EastStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_NorthAsiaEastStandardTime"]));
        collection.push(new TimeZoneData(126, "SingaporeStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_SingaporeStandardTime"]));
        collection.push(new TimeZoneData(127, "W. AustraliaStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_WAustraliaStandardTime"]));
        collection.push(new TimeZoneData(128, "TaipeiStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_TaipeiStandardTime"]));
        collection.push(new TimeZoneData(129, "UlaanbaatarStandardTime", 480, tzTranslations["TimeZoneDataCollectionProvider_UlaanbaatarStandardTime"]));
        collection.push(new TimeZoneData(130, "UTC+08:30", 510, tzTranslations["TimeZoneDataCollectionProvider_UTC00830"]));
        collection.push(new TimeZoneData(131, "North KoreaStandardTime", 510, tzTranslations["TimeZoneDataCollectionProvider_NorthKoreaStandardTime"]));
        collection.push(new TimeZoneData(132, "UTC+08:45", 525, tzTranslations["TimeZoneDataCollectionProvider_UTC00845"]));
        collection.push(new TimeZoneData(133, "Aus Central W.StandardTime", 525, tzTranslations["TimeZoneDataCollectionProvider_AusCentralWStandardTime"]));
        collection.push(new TimeZoneData(134, "UTC+09", 540, tzTranslations["TimeZoneDataCollectionProvider_UTC009"]));
        collection.push(new TimeZoneData(135, "TransbaikalStandardTime", 540, tzTranslations["TimeZoneDataCollectionProvider_TransbaikalStandardTime"]));
        collection.push(new TimeZoneData(136, "TokyoStandardTime", 540, tzTranslations["TimeZoneDataCollectionProvider_TokyoStandardTime"]));
        collection.push(new TimeZoneData(137, "KoreaStandardTime", 540, tzTranslations["TimeZoneDataCollectionProvider_KoreaStandardTime"]));
        collection.push(new TimeZoneData(138, "YakutskStandardTime", 540, tzTranslations["TimeZoneDataCollectionProvider_YakutskStandardTime"]));
        collection.push(new TimeZoneData(139, "UTC+09:30", 570, tzTranslations["TimeZoneDataCollectionProvider_UTC00930"]));
        collection.push(new TimeZoneData(140, "Cen. AustraliaStandardTime", 570, tzTranslations["TimeZoneDataCollectionProvider_CenAustraliaStandardTime"]));
        collection.push(new TimeZoneData(141, "AUS CentralStandardTime", 570, tzTranslations["TimeZoneDataCollectionProvider_AUSCentralStandardTime"]));
        collection.push(new TimeZoneData(142, "UTC+10", 600, tzTranslations["TimeZoneDataCollectionProvider_UTC010"]));
        collection.push(new TimeZoneData(143, "E. AustraliaStandardTime", 600, tzTranslations["TimeZoneDataCollectionProvider_EAustraliaStandardTime"]));
        collection.push(new TimeZoneData(144, "AUS EasternStandardTime", 600, tzTranslations["TimeZoneDataCollectionProvider_AUSEasternStandardTime"]));
        collection.push(new TimeZoneData(145, "West PacificStandardTime", 600, tzTranslations["TimeZoneDataCollectionProvider_WestPacificStandardTime"]));
        collection.push(new TimeZoneData(146, "TasmaniaStandardTime", 600, tzTranslations["TimeZoneDataCollectionProvider_TasmaniaStandardTime"]));
        collection.push(new TimeZoneData(147, "VladivostokStandardTime", 600, tzTranslations["TimeZoneDataCollectionProvider_VladivostokStandardTime"]));
        collection.push(new TimeZoneData(148, "UTC+10:30", 630, tzTranslations["TimeZoneDataCollectionProvider_UTC01030"]));
        collection.push(new TimeZoneData(149, "Lord HoweStandardTime", 630, tzTranslations["TimeZoneDataCollectionProvider_LordHoweStandardTime"]));
        collection.push(new TimeZoneData(150, "UTC+11", 660, tzTranslations["TimeZoneDataCollectionProvider_UTC011"]));
        collection.push(new TimeZoneData(151, "BougainvilleStandardTime", 660, tzTranslations["TimeZoneDataCollectionProvider_BougainvilleStandardTime"]));
        collection.push(new TimeZoneData(152, "Russia Time Zone 10", 660, tzTranslations["TimeZoneDataCollectionProvider_RussiaTimeZone10"]));
        collection.push(new TimeZoneData(153, "MagadanStandardTime", 660, tzTranslations["TimeZoneDataCollectionProvider_MagadanStandardTime"]));
        collection.push(new TimeZoneData(154, "NorfolkStandardTime", 660, tzTranslations["TimeZoneDataCollectionProvider_NorfolkStandardTime"]));
        collection.push(new TimeZoneData(155, "SakhalinStandardTime", 660, tzTranslations["TimeZoneDataCollectionProvider_SakhalinStandardTime"]));
        collection.push(new TimeZoneData(156, "Central PacificStandardTime", 660, tzTranslations["TimeZoneDataCollectionProvider_CentralPacificStandardTime"]));
        collection.push(new TimeZoneData(157, "UTC+12", 720, tzTranslations["TimeZoneDataCollectionProvider_UTC012"]));
        collection.push(new TimeZoneData(158, "Russia Time Zone 11", 720, tzTranslations["TimeZoneDataCollectionProvider_RussiaTimeZone11"]));
        collection.push(new TimeZoneData(159, "New ZealandStandardTime", 720, tzTranslations["TimeZoneDataCollectionProvider_NewZealandStandardTime"]));
        collection.push(new TimeZoneData(160, "FijiStandardTime", 720, tzTranslations["TimeZoneDataCollectionProvider_FijiStandardTime"]));
        collection.push(new TimeZoneData(161, "KamchatkaStandardTime", 720, tzTranslations["TimeZoneDataCollectionProvider_KamchatkaStandardTime"]));
        collection.push(new TimeZoneData(162, "UTC+12:45", 765, tzTranslations["TimeZoneDataCollectionProvider_UTC01245"]));
        collection.push(new TimeZoneData(163, "Chatham IslandsStandardTime", 765, tzTranslations["TimeZoneDataCollectionProvider_ChathamIslandsStandardTime"]));
        collection.push(new TimeZoneData(164, "UTC+13", 780, tzTranslations["TimeZoneDataCollectionProvider_UTC013"]));
        collection.push(new TimeZoneData(165, "TongaStandardTime", 780, tzTranslations["TimeZoneDataCollectionProvider_TongaStandardTime"]));
        collection.push(new TimeZoneData(166, "SamoaStandardTime", 780, tzTranslations["TimeZoneDataCollectionProvider_SamoaStandardTime"]));
        collection.push(new TimeZoneData(167, "UTC+14", 840, tzTranslations["TimeZoneDataCollectionProvider_UTC014"]));
        collection.push(new TimeZoneData(168, "Line IslandsStandardTime", 840, tzTranslations["TimeZoneDataCollectionProvider_LineIslandsStandardTime"]));

        return collection;
    }

    static async getTimeZoneDataCollection(http: HttpClient): Promise<Array<TimeZoneData>> {
        let timeZoneDataCollection = TimeZoneDataCollectionCache.Instance.getTimeZoneDataCollection();
        if (timeZoneDataCollection == null) {
            timeZoneDataCollection = await this.createTimeZoneDataCollection(http);

            TimeZoneDataCollectionCache.Instance.addTimeZoneDataCollection(timeZoneDataCollection);
        }

        return timeZoneDataCollection;
    }
}
