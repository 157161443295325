import { BaseRowNumberModel } from '../domain-models/base-row-number-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { ItemViewModel } from './item-view-model';
import { NumericPropertyViewModel } from './base-type/numeric-property-view-model';


export abstract class BaseRowNumberItemViewModel<
    TModel extends BaseRowNumberModel<TIdentity>,
    TIdentity extends BaseIdentity> extends ItemViewModel<TModel, TIdentity> {


    private _rowNumber: NumericPropertyViewModel;

    get rowNumber(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._rowNumber = value; }, this._rowNumber, 'rowNumber');
    }
}
