import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { LogService } from '@nts/std/utility';
import { UIStarter } from '../../../starter/ui-starter';
import { NgIf } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { ToastMessageComponent } from '../toast-message/toast-message.component';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nts-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    ToastMessageComponent,
    NgIf,
  ]
})
export class MainContainerComponent implements OnInit {

  isLoading = true;
  showMenu: string;
  inIframe = false;

  _opened: boolean = false;
  _modeNum: number = 0;
  _positionNum: number = 0;
  _dock: boolean = false;
  _closeOnClickOutside: boolean = false;
  _closeOnClickBackdrop: boolean = false;
  _showBackdrop: boolean = false;
  _animate: boolean = true;
  _trapFocus: boolean = true;
  _autoFocus: boolean = true;
  _keyClose: boolean = false;
  _autoCollapseHeight: number = null;
  _autoCollapseWidth: number = null;

  _MODES: Array<string> = ['over', 'push', 'slide'];
  _POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
  ) {

  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['iframe'] != null) {
      this.inIframe = this.route.snapshot.queryParams['iframe'] === 'true';
    }
  }

  reloadCurrentRoute() {
    window.location.reload();
  }

  _toggleOpened(): void {
    this._opened = !this._opened;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  _toggleMode(): void {
    this._modeNum++;

    if (this._modeNum === this._MODES.length) {
      this._modeNum = 0;
    }
  }

  _toggleAutoCollapseHeight(): void {
    this._autoCollapseHeight = this._autoCollapseHeight ? null : 500;
  }

  _toggleAutoCollapseWidth(): void {
    this._autoCollapseWidth = this._autoCollapseWidth ? null : 500;
  }

  _togglePosition(): void {
    this._positionNum++;

    if (this._positionNum === this._POSITIONS.length) {
      this._positionNum = 0;
    }
  }

  _toggleDock(): void {
    this._dock = !this._dock;
  }

  _toggleCloseOnClickOutside(): void {
    this._closeOnClickOutside = !this._closeOnClickOutside;
  }

  _toggleCloseOnClickBackdrop(): void {
    this._closeOnClickBackdrop = !this._closeOnClickBackdrop;
  }

  _toggleShowBackdrop(): void {
    this._showBackdrop = !this._showBackdrop;
  }

  _toggleAnimate(): void {
    this._animate = !this._animate;
  }

  _toggleTrapFocus(): void {
    this._trapFocus = !this._trapFocus;
  }

  _toggleAutoFocus(): void {
    this._autoFocus = !this._autoFocus;
  }

  _toggleKeyClose(): void {
    this._keyClose = !this._keyClose;
  }

  _onOpenStart(): void {
    LogService.debug('Sidebar opening');
  }

  _onOpened(): void {
    LogService.debug('Sidebar opened');
  }

  _onCloseStart(): void {
    LogService.debug('Sidebar closing');
  }

  _onClosed(): void {
    LogService.debug('Sidebar closed');
  }

  _onTransitionEnd(): void {
    LogService.debug('Transition ended');
  }

  _onBackdropClicked(): void {
    LogService.debug('Backdrop clicked');
  }

  redirectToDashboard(): void {
    UIStarter.redirectToDashboard();
  }
}
