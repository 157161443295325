<div class="toolbar-section main-toolbar" [class.has-additional-toolbar]="zoomParametersViewModel.showSelectionsFilterOptions">
  <div class="toolbar-control-content">
    <div class="horizontal-container">
      <div class="root-container">
        <div class="inline-command-bar">
          <div class="action-list-control-container">
            <div class="horizontal-flex-container rounded-actions">

              <!-- Toggle filter with populated operator -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showFilterWithPopulatedOperator"
                  [title]="zoomParametersViewModel.filterWithPopulatedOperatorButtonTitle" icon="task-list"
                  (onClicked)="zoomParametersViewModel.toggleFilterWithPopulatedOperator()">
                </nts-text-button>
              </div>

              <!-- Toggle selection in filter -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showSelectionsFilterOptions"
                  [title]="zoomParametersViewModel.selectionsFiltersOptionsButtonTitle" icon="eye-empty"
                  (onClicked)="zoomParametersViewModel.toggleSelectionsFilterOptions()">
                </nts-text-button>
              </div>

              <!-- Toggle advanced command -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showAdvancedFilters"
                  [title]="zoomParametersViewModel.advancedButtonTitle" icon="fx-rounded"
                  (onClicked)="zoomParametersViewModel.toggleAdvancedFilters()"></nts-text-button>
              </div>

              <!-- Toggle sorting -->
              <div class="command-bar-button-container">
                <nts-text-button [class.active]="zoomParametersViewModel.showSortingGroups"
                  [title]="zoomParametersViewModel.sortingGroupButtonTitle" icon="sort"
                  (onClicked)="zoomParametersViewModel.toggleSortingGroups()"></nts-text-button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Clear filters command -->
      <div class="horizontal-flex-container-item-layout">
        <div class="command-bar-button-container">
          <nts-text-button [title]="zoomParametersViewModel.clearCommand.tooltip"
            [label]="zoomParametersViewModel.clearCommand.displayName"
            [isDisabled]="!(zoomParametersViewModel.clearCommand.canExecute$ | async)"
            (onClicked)="zoomParametersViewModel.clearCommand.execute()"></nts-text-button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Gestione comandi avanzati per la selezione dei campi -->
<div class="toolbar-section additional-toolbar" *ngIf="zoomParametersViewModel.showSelectionsFilterOptions">
  <div class="toolbar-control-content">
    <div class="horizontal-container">
      <div class="root-container">
        <div class="inline-command-bar">
          <div class="action-list-control-container">
            <div class="horizontal-flex-container">

              <div class="command-bar-button-container">
                <nts-text-button
                  [title]="'std_CMD_SelectAll_Tooltip' | NTSTranslate" [label]="'std_CMD_SelectAll_DisplayName' | NTSTranslate" [icon]="'add-selection'"
                  (onClicked)="zoomParametersViewModel.selectAll()">
                </nts-text-button>
              </div>

              <div class="command-bar-button-container">
                <nts-text-button
                  [title]="'std_CMD_DeselectAll_Tooltip' | NTSTranslate" [label]="'std_CMD_DeselectAll_DisplayName' | NTSTranslate" [icon]="'remove-selection'"
                  (onClicked)="zoomParametersViewModel.deselectAll()">
                </nts-text-button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Fine Gestione comandi avanzati per la selezione dei campi -->

<div class="zoom-parameters-content" [class.is-hidden]="zoomParametersViewModel.showSortingGroups" [class.has-additional-toolbar]="zoomParametersViewModel.showSelectionsFilterOptions">
  <div #parameterLabels class="parameter-column parameter-column-labels">
    <!-- <div class="zoom-parameter-label">{{ zoomParametersViewModel.headerPropertyName }}</div> -->
    <div
      *ngFor="let filter of (zoomParametersViewModel.filters | zoomAvailablesFilter: zoomParametersViewModel.showFilterWithPopulatedOperator)"
      class="zoom-filter-name level-{{filter.metaData.level}}">
      <div class="zoom-filter-name-container">
        <nts-base-checkbox-text-box 
          *ngIf="zoomParametersViewModel.showSelectionsFilterOptions"
          (onChange)="zoomParametersViewModel.notifyModified()"
          [(checkStatus)]="filter.isSelected"
          [label]="filter.propertyNameMap.displayName" 
          [isDisabled]="!filter.isSelectable">
        </nts-base-checkbox-text-box>

        <nts-label-box (click)="findExternal($event, filter)"
          [class.label-multiline]="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
          [class.zoom-label]="filter.externalDomainModelNameToZoom !== ''" [title]="filter.propertyNameMap.displayName"
          [label]="filter.propertyNameMap.displayName"></nts-label-box>
        <div class="white-space-container" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In && isFilterEnum(filter.filterValue)">
          <div *ngFor="let filterIn of filter.filterValues" class="white-space"></div>
        </div>
        <div class="white-space-container" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between">
          <div class="white-space"></div>
          <div class="white-space"></div>
        </div>
        <div class="white-space-container" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In && (isFilterDateTimeOffset(filter.filterValue) || isFilterDate(filter.filterValue))">
          <div *ngFor="let filterIn of filter.filterDateTimeValues" class="white-space"></div>
        </div>
        
      </div>
      
      <nts-text-button *ngIf="filter.externalDomainModelNameToZoom !== ''"
        [icon]="'search'" (onClicked)="findExternal($event, filter)">
      </nts-text-button>

      <nts-text-button *ngIf="isExpandableFilter(filter) && !zoomParametersViewModel.showFilterWithPopulatedOperator"
        [title]="(filter.isCollapsed ? 'std_ExpandableShowMore_Description' : 'std_ExpandableShowLess_Description') | NTSTranslate"
        [icon]="filter.isCollapsed ? 'eye-alt' : 'eye-close'" (onClicked)="toggleExpandableFilter(filter)">
      </nts-text-button>
    </div>
  </div>
  <div #parameterOperators class="parameter-column parameter-column-operators">
    <!-- <div class="zoom-parameter-label">{{ zoomParametersViewModel.headerOperatorName }}</div> -->
    <div
      *ngFor="let filter of (zoomParametersViewModel.filters | zoomAvailablesFilter: zoomParametersViewModel.showFilterWithPopulatedOperator)"
      class="filter-operator">
      <nts-zoom-filter-operator-text-box [operator]="filter.operator"></nts-zoom-filter-operator-text-box>
      <ng-container *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In && (isFilterDateTimeOffset(filter.filterValue) || isFilterDate(filter.filterValue))">
        <div *ngFor="let filterIn of filter.filterDateTimeValues | slice:0:-1" class="white-space"></div>
      </ng-container>
      <ng-container *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In && isFilterEnum(filter.filterValue)">
        <div *ngFor="let filterIn of filter.filterValues | slice:0:-1" class="white-space"></div>
      </ng-container>
      <ng-container *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between">
        <div class="white-space"></div>
      </ng-container>
    </div>
  </div>
  <div #parameterValues class="parameter-column parameter-values">
    <!-- <div class="zoom-parameter-label">{{ zoomParametersViewModel.headerValue }}</div> -->
    <div
      *ngFor="let filter of (zoomParametersViewModel.filters | zoomAvailablesFilter: zoomParametersViewModel.showFilterWithPopulatedOperator)"
      class="filter-value-container" [class.filter-value-between]="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between">
      <div class="filter-value" *ngIf="filter.operator.currentValue != zoomParametersViewModel.filterOperators.In" #filters
        [ngClass]="{'advanced-filters-show': zoomParametersViewModel.showAdvancedFilters }">
        <div class="filter-value-list" *ngIf="!filter.showVariables" @openClose>
          <nts-zoom-filter-string-text-box *ngIf="isFilterString(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
            (keyDown)="keyDown($event)">
          </nts-zoom-filter-string-text-box>
          <nts-zoom-filter-string-text-box *ngIf="isFilterGuid(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [validateChipsFunc]="guidMaskSettings.validateMaskOnBlurFunc"
            [maskSettings]="guidMaskSettings"
            [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
            (keyDown)="keyDown($event)">
          </nts-zoom-filter-string-text-box>
          <nts-zoom-filter-numeric-text-box *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue" [updateOnBlur]="true" (focus)="parameterFocus($event, filter)"
            (keyDown)="keyDown($event)">
          </nts-zoom-filter-numeric-text-box>
          <nts-zoom-filter-external-text-box *ngIf="isFilterExternal(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue" [updateOnBlur]="true" (focus)="parameterFocus($event, filter)"
            (keyDown)="keyDown($event)">
          </nts-zoom-filter-external-text-box>
          <nts-zoom-filter-bool-text-box *ngIf="isFilterBool(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue">
          </nts-zoom-filter-bool-text-box>
          <nts-zoom-filter-date-text-box *ngIf="isFilterDate(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue">
          </nts-zoom-filter-date-text-box>
          <nts-zoom-filter-date-offset-text-box
            *ngIf="isFilterDateTimeOffset(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue">
          </nts-zoom-filter-date-offset-text-box>
          <nts-zoom-filter-enum-text-box *ngIf="isFilterEnum(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue">
          </nts-zoom-filter-enum-text-box>
        </div>
        <div class="variables-container" *ngIf="filter.showVariables" @openClose>

          <nts-base-enum-text-box *ngIf="filter.showVariables" [value]="filter.filterVariableValue" optionLabel="label"
            dataKey="'value'" [options$]="filter.filterVariables$" [appendTo]="'body'"
            [isDisabled]="filter.OperatorDoesNotRequireValue"
            [placeholder]="zoomParametersViewModel.variablesPlaceholderTitle"
            (onChange)="zoomParametersViewModel.onFilterVariableChange(filter)"
            (onBlur)="zoomParametersViewModel.onFilterVariableBlur(filter)" [isNullable]="true">
          </nts-base-enum-text-box>

        </div>
        <div class="add-in-button-container"
                *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In">
          <nts-text-button
            [title]="zoomParametersViewModel.addInFieldButtonTitle" [icon]="'plus'"
            (onClicked)="zoomParametersViewModel.addInField(filter)">
          </nts-text-button>
        </div>
        <div class="variables-button-container"
          *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.filterVariables.length > 0">

          <nts-text-button *ngIf="zoomParametersViewModel.showAdvancedFilters"
            [title]="zoomParametersViewModel.variablesButtonTitle" [icon]="'filter'"
            [class.var-visible]="filter.showVariables" (onClicked)="zoomParametersViewModel.showVariables(filter)">
          </nts-text-button>
        </div>
      </div>
      <!-- Between -->
      <div class="filter-value" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
        #filters
        [ngClass]="{'advanced-filters-show': zoomParametersViewModel.showAdvancedFilters, 'filter-value-between':filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between }">
        <div class="filter-value-list" *ngIf="!filter.showVariables2" @openClose>
          <ng-container
            *ngIf="isFilterString(filter.filterValue) && !filter.showVariables2">
            <nts-zoom-filter-string-text-box [filter]="filter" [propertyViewModel]="filter.filterValue2" [updateOnBlur]="true"
              (focus)="parameterFocus($event, filter)" (keyDown)="keyDown($event)"
              [ngClass]="'filter-value-text-between'">
            </nts-zoom-filter-string-text-box>
          </ng-container>
          <ng-container
            *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables2">
            <nts-zoom-filter-numeric-text-box [filter]="filter" [propertyViewModel]="filter.filterValue2" [updateOnBlur]="true"
              (focus)="parameterFocus($event, filter)" (keyDown)="keyDown($event)"
              [ngClass]="'filter-value-text-between'">
            </nts-zoom-filter-numeric-text-box>
          </ng-container>
          <ng-container
            *ngIf="isFilterDate(filter.filterValue) && !filter.showVariables2">
            <nts-zoom-filter-date-text-box [propertyViewModel]="filter.filterValue2"
              [ngClass]="'filter-value-text-between'">
            </nts-zoom-filter-date-text-box>
          </ng-container>
          <ng-container
            *ngIf="isFilterDateTimeOffset(filter.filterValue) && !filter.showVariables2">
            <nts-zoom-filter-date-offset-text-box [propertyViewModel]="filter.filterValue2"
              [ngClass]="'filter-value-text-between'">
            </nts-zoom-filter-date-offset-text-box>
          </ng-container>
        </div>
        <div class="variables-container" *ngIf="filter.showVariables2" @openClose>
          <div class="mt-1"
            *ngIf="filter.showVariables2 && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
            @openClose>
            <nts-base-enum-text-box *ngIf="filter.showVariables2" [value]="filter.filterVariable2Value"
              optionLabel="'label'" dataKey="'value'" [options$]="filter.filterVariables$" [appendTo]="'body'"
              [isDisabled]="filter.OperatorDoesNotRequireValue"
              [placeholder]="zoomParametersViewModel.variablesPlaceholderTitle"
              (onChange)="zoomParametersViewModel.onFilterVariable2Change(filter)" [isNullable]="true"
              (onBlur)="zoomParametersViewModel.onFilterVariable2Blur(filter)" [isNullable]="true">
            </nts-base-enum-text-box>
          </div>
        </div>
        <div class="variables-button-container"
          *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.filterVariables.length > 0">
          <nts-text-button
            *ngIf="zoomParametersViewModel.showAdvancedFilters && filter.operator.currentValue == zoomParametersViewModel.filterOperators.Between"
            [title]="zoomParametersViewModel.variablesButtonTitle" [icon]="'filter'"
            [class.var-visible]="filter.showVariables2" (onClicked)="zoomParametersViewModel.showVariables2(filter)">
          </nts-text-button>
        </div>
      </div>
      <!-- In -->
      <div class="filter-value" *ngIf="filter.operator.currentValue == zoomParametersViewModel.filterOperators.In" #filters>
        <div class="filter-value-list" *ngIf="!filter.showVariables" @openClose>
          <ng-container *ngIf="isFilterString(filter.filterValue) && !filter.showVariables">
            <nts-zoom-filter-string-text-box *ngIf="isFilterString(filter.filterValue) && !filter.showVariables"
              [filter]="filter"
              [propertyViewModel]="filter.filterValue" (focus)="parameterFocus($event, filter)"
              (keyDown)="keyDown($event)">
            </nts-zoom-filter-string-text-box>
          </ng-container>
          <ng-container *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables">
            <nts-zoom-filter-numeric-text-box *ngIf="isFilterNumeric(filter.filterValue) && !filter.showVariables"
            [filter]="filter"
            [propertyViewModel]="filter.filterValue" [updateOnBlur]="true" (focus)="parameterFocus($event, filter)"
            (keyDown)="keyDown($event)">
           </nts-zoom-filter-numeric-text-box>
          </ng-container>
          <ng-container *ngIf="isFilterEnum(filter.filterValue) && !filter.showVariables">
            <nts-zoom-filter-enum-text-box [filter]="filter" [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterValues">
            </nts-zoom-filter-enum-text-box>
          </ng-container>
          <ng-container *ngIf="isFilterDate(filter.filterValue) && !filter.showVariables">
            <nts-zoom-filter-date-text-box [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterDateTimeValues"
              [ngClass]="'filter-value-text-in'">
            </nts-zoom-filter-date-text-box>
          </ng-container>
          <ng-container *ngIf="isFilterDateTimeOffset(filter.filterValue) && !filter.showVariables">
            <nts-zoom-filter-date-offset-text-box [propertyViewModel]="filterIn" *ngFor="let filterIn of filter.filterDateTimeValues"
              [ngClass]="'filter-value-text-in'">
            </nts-zoom-filter-date-offset-text-box>
          </ng-container>
        </div>
        <div class="in-buttons-container" *ngIf="isFilterDate(filter.filterValue) || isFilterDateTimeOffset(filter.filterValue)">
          <div class="filter-value-actions" *ngFor="let filterIn of filter.filterDateTimeValues; let last = last; let first = first">
            <nts-text-button
              [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="last" [icon]="'plus'"
              (onClicked)="zoomParametersViewModel.addInField(filter)">
            </nts-text-button>
            <nts-text-button
              [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="!first" [icon]="'trash'"
              (onClicked)="zoomParametersViewModel.deleteInField(filter, filterIn)">
            </nts-text-button>            
          </div>
        </div>
        <div class="in-buttons-container" *ngIf="isFilterEnum(filter.filterValue)">
          <div class="filter-value-actions" *ngFor="let filterIn of filter.filterValues; let last = last; let first = first">
            <nts-text-button
              [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="last" [icon]="'plus'"
              (onClicked)="zoomParametersViewModel.addInField(filter)">
            </nts-text-button>
            <nts-text-button
              [title]="zoomParametersViewModel.addInFieldButtonTitle" *ngIf="!first" [icon]="'trash'"
              (onClicked)="zoomParametersViewModel.deleteInField(filter, filterIn)">
            </nts-text-button>            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nts-zoom-parameters-sorting-groups *ngIf="zoomParametersViewModel.filters"
  [class.is-hidden]="!zoomParametersViewModel.showSortingGroups"
  [parameters]="zoomParametersViewModel"
  (onSortingGroupsChanged)="zoomParametersViewModel.notifyModified()">
</nts-zoom-parameters-sorting-groups>