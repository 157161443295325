import { NTSReflection } from '@nts/std/utility';
import { NotString } from '../../../types';


const NM_ITEMS_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'nmItemsViewModelTypeDecoratorMetaDataKey';

export function NmItemsViewModelTypeDecorator<T>(nmItemsType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(NM_ITEMS_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, nmItemsType, target);
    };
}

export class NmItemsViewModelTypeInspector {

    static META_DATA_KEY = NM_ITEMS_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@NmItemsViewModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            NM_ITEMS_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            NM_ITEMS_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
