import { Expose } from "@nts/std/serialization";
import { AutoCompleteOptions } from './auto-complete-options';

export class AutoCompleteExternalOptions extends AutoCompleteOptions {

    /**
     * Full name dell'external per cui si vuole eseguire l'autocomplete
     */
    @Expose()
    fullRootModelName: string;

}
