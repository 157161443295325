import { NTSReflection } from '@nts/std/utility';

const IGNORE_AUTOMATIC_DECORATOR_WARNING_DECORATOR_DATA_IDENTIFICATION_KEY = 'ignoreAutomaticDecoratorWarningDecoratorMetaDataKey';

export function IgnoreAutomaticDecoratorWarningDecorator(keyValueObj: {[propertyName:string]:string}) {
    return (target: any) => {
        Reflect.defineMetadata(IGNORE_AUTOMATIC_DECORATOR_WARNING_DECORATOR_DATA_IDENTIFICATION_KEY, keyValueObj, target);
    };
}

export class IgnoreAutomaticDecoratorWarningInspector {

    static META_DATA_KEY = IGNORE_AUTOMATIC_DECORATOR_WARNING_DECORATOR_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@IgnoreAutomaticDecoratorWarningDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            IGNORE_AUTOMATIC_DECORATOR_WARNING_DECORATOR_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string {
        return NTSReflection.getClassMetadata(
            IGNORE_AUTOMATIC_DECORATOR_WARNING_DECORATOR_DATA_IDENTIFICATION_KEY, subject);
    }
}
