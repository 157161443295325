import { AfterContentInit, ChangeDetectionStrategy, Component, ComponentFactoryResolver, HostBinding, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LogService } from "@nts/std/utility";
import { CustomFieldMetaData } from "../../../layout-meta-data/custom-field-meta-data";
import { OrchestratorViewModelInterface } from "../../../view-models/orchestrator-view-model.interface";
import { ComponentLocator } from "../../component-locator";
import { CustomFieldComponentInterface } from "../core/custom-field/custom-field-component.interface";

@UntilDestroy()
@Component({
    selector: 'nts-custom-field-container',
    templateUrl: './custom-field-container.component.html',
    styleUrls: ['./custom-field-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        
    ]
})
export class CustomFieldContainerComponent implements OnInit, AfterContentInit {

    @ViewChild('customComponentPlaceHolder', { read: ViewContainerRef, static: true })
    customComponentPlaceHolder: ViewContainerRef;

    @HostBinding('class.additional-field')
    @Input() additionalField = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    @HostBinding('class.is-hidden')
    @Input() isHidden = false;

    @Input() metaData: CustomFieldMetaData;

    @Input() ovm: OrchestratorViewModelInterface;
   
    @Input() 
    set isDisabled(value: boolean) {
        this.customComponentInstance.isDisabled = value
    }
    get isDisabled() {
        return this.customComponentInstance.isDisabled;
    }

    customComponentType: any;
    customComponentInstance: CustomFieldComponentInterface;

    get uniqueId() {
        return this.customComponentInstance.uniqueId;
    }

    get type() {
        return this.customComponentInstance.type;
    }

    get path() {
        return this.customComponentInstance.path;
    }

    get name() {
        return this.customComponentInstance.name;
    }

    get description() {
        return this.customComponentInstance.description;
    }

    get displayName() {
        return this.customComponentInstance.displayName;
    }

    constructor(
        private readonly componentLocator: ComponentLocator,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
    ) {

    }

    ngOnInit(): void {
        this.customComponentType = this.componentLocator.getCustomComponentType(this.metaData.uniqueId);
    }

    async ngAfterContentInit() {
        this.customComponentPlaceHolder.clear();
        if (this.customComponentType) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.customComponentType);
            const componentRef = this.customComponentPlaceHolder.createComponent(componentFactory);
            this.customComponentInstance = componentRef.instance as CustomFieldComponentInterface;
            this.customComponentInstance.initComponentFromMetaData(this.metaData, this.ovm);
        } else {
            LogService.warn('customComponentType con codice ' + this.metaData.uniqueId + ' non trovato!')
        }

    }
}
