import { NTSReflection } from '@nts/std/utility';

const GENERICS_PROPERTIES_TYPE_META_DATA_IDENTIFICATION_KEY = 'genericsPropertiesTypeDecoratorMetaDataKey';

export interface GenericsPropertiesTypeInterface {
    getGenericPropertyType(propertyName: string): any
}

export type GenericsPropertiesType<T> = {
    [P in keyof T]?: Exclude<string | object, string>;
}

export function GenericsPropertiesTypeDecorator<T>(genericsPropertiesType: GenericsPropertiesType<T>) {
    return (target: any) => {
        Reflect.defineMetadata(GENERICS_PROPERTIES_TYPE_META_DATA_IDENTIFICATION_KEY, genericsPropertiesType, target);
    };
}

export class GenericsPropertiesTypeInspector {

    static META_DATA_KEY = GENERICS_PROPERTIES_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@GenericsPropertiesTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            GENERICS_PROPERTIES_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            GENERICS_PROPERTIES_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
