import { UIInfo } from '../../domain-models/ui-finder/ui-info';
import { Expose, Type } from '@nts/std/serialization';

export class DraftScheduleInfoDto {

    @Expose()
    draftId: string;

    @Expose()
    @Type(() => UIInfo)
    schedulePlanUIInfo: UIInfo;

}