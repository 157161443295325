import { HttpClient } from '@angular/common/http';
import { DateTimeOffset } from '../../../domain-models/date-time-offset';
import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { TimeZoneData } from '@nts/std/timezone';
import { MessageContainer } from '../../message-container';
import { DateTimeMetaData } from '../../../meta-data/date-time-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { TimeZoneDataCollectionProvider } from '@nts/std/timezone';

export class DateTimeOffsetZoomPropertyViewModel extends ZoomPropertyViewModel<DateTimeOffset>  {

  showTime = true;
  showTimeZone = false;
  advancingCaret = true;
  timeZones: Array<TimeZoneData>;
  currentOffset: Number;

  private _isTimeZoneEnabled = true;

  constructor(propertyMetaData: DateTimeMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);
    this.timeZones = new Array<TimeZoneData>();
    this.currentOffset = - (new Date()).getTimezoneOffset();
  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  setValue(value: DateTimeOffset|null) {
    if (value != null) {
      if (value.timeZoneData == null) {
        // Se uno seleziona solo la data, imposto timezone corrente di default
        value.timeZoneData = this.findTimeZone(value.offset != null ? value.offset : this.getOffset());
      }

      if (value.dateTime == null) {
        // Se uno seleziona solo la timezone, imposto data corrente di default
        value.dateTime = new Date();
      }
    }
    if (value === null) {
      this.typedValue = null;
    } else {
      this.typedValue = value // moment(value.dateTime, 'YYYY-MM-DD').toDate();
    }
    this.modifiedSubscriber.notifyModified();
  }

  findTimeZone(timeSpan: Number): TimeZoneData |null {
    return this.timeZones.find(t =>
      t.timeSpan === timeSpan) ?? null;
  }

  async populateTimeZonesAsync(http: HttpClient) {
    const timeZones = await TimeZoneDataCollectionProvider.getTimeZoneDataCollection(http);

    timeZones.forEach(tz => {
      this.timeZones.push(new TimeZoneData(tz.key, tz.timeZoneInfoId, tz.timeSpan, tz.description));
    });
  }

  private getOffset() {
    return this.currentOffset;
  }

  private internalDateTimeOffsetValues!: Array<DateTimeOffset>|null;

  setDateTimeOffsetValues(valuesToSet: Array<DateTimeOffset>) {
      if (this.internalDateTimeOffsetValues != valuesToSet) {
          this.internalDateTimeOffsetValues = valuesToSet;
          this.modifiedSubscriber.notifyModified();
      }   
  }

  get dateTimeOffsetValues(): Array<DateTimeOffset>|null {
      return this.internalDateTimeOffsetValues;
  }
}
