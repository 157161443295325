import { map, Observable, of, takeUntil } from 'rxjs';
import { CommandFactory } from '../commands/command-factory';
import { CommandTypes } from '../commands/command-types';
import { UICommandSettingsManager } from '../commands/ui-command-settings-manager';
import { UICommandSettingsManagerInterface } from '../commands/ui-command-settings-manager.interface';
import { UICommandInterface } from '../commands/ui-command.interface';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { StringPropertyViewModel } from './string-property-view-model';

export class PhonePropertyViewModel extends StringPropertyViewModel {

    callPhoneCommand: UICommandInterface;
    internalUiCommandDefaultSettingsManager: UICommandSettingsManagerInterface;

    protected getUICommandDefaultSettingsManager(): UICommandSettingsManagerInterface {
        if (!this.internalUiCommandDefaultSettingsManager) {
            this.internalUiCommandDefaultSettingsManager = new UICommandSettingsManager();
        }
        return this.internalUiCommandDefaultSettingsManager;
    }

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo);

        const manager = this.getUICommandDefaultSettingsManager();
        this.callPhoneCommand = manager.setUICommand(
            CommandTypes.CallPhone,
            CommandFactory.createUICommand(
                () => this.callPhone(), 
                () => this.canCallPhone(), 
            )
        );
        this.customCommandList.set('call-phone', this.callPhoneCommand);
        this.customCommandsName = ['call-phone'];
    }

    async callPhone() {
        if(this.value?.length >= 1) {
            window.location.href = `tel:${this.value}`
        }
    }

    canCallPhone(): Observable<boolean> {
        return of(this.value?.length >= 1)        
    }

}