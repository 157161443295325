import { NTSReflection } from '@nts/std/utility';
import { NotString } from '../types';

const LONG_OP_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY = 'longOpResultTypeDecoratorMetaDataKey';

export function LongOpResultTypeDecorator<T>(longOpResultType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(LONG_OP_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, longOpResultType, target);
    };
}

export class LongOpResultTypeInspector {

    static META_DATA_KEY = LONG_OP_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@LongOpResultTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            LONG_OP_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            LONG_OP_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
