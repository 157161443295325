import { ServiceResponse } from './service-response';
import { ResponseResultTypeInspector } from './decorators/response-result-type.decorator';
import { Expose, Type } from '@nts/std/serialization';


export class GenericArrayServiceResponse<TResult>
    extends ServiceResponse {

    @Expose()
    @Type((options) => {
        return (options?.newObject as GenericArrayServiceResponse<TResult>)?._resultType;
    })
    result: Array<TResult>;

    private _resultType: any;

    constructor(resultType?: any) {

        super();

        if (resultType === undefined) {

            this._resultType = ResponseResultTypeInspector.getValue(this);
            if (this._resultType === undefined) {
                throw new Error(
                    `MetaData ${ResponseResultTypeInspector.META_DATA_KEY} not defined. You must use ${ResponseResultTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
                );
            }

        } else {
            this._resultType = resultType;
        }
    }
}
