import { Expose, Type } from '@nts/std/serialization';
import { UIInfo } from "../ui-finder/ui-info";
import { DraftLayoutInfoDto } from "./draft-layout-info.dto";

export class DraftLayoutUIInfoDto extends DraftLayoutInfoDto {
    @Expose()
    @Type(() => UIInfo)
    uiInfo: UIInfo
}

