import { EventEmitter, Output, Input, Component } from '@angular/core';
import { BasePropertyComponent } from './base-property-component';

@Component({
    template: ''
})
export abstract class NewBasePropertyTextBox<T> extends BasePropertyComponent<T> {

    @Input() override modelUpdate: boolean = true;
    @Input() updateOnBlur: boolean;
    @Input() updateOnFocusOut: boolean;
    @Input() placeholder: string = '';
    @Input() tabIndex: number;
    @Input() isDisabled: boolean = false;
    
    @Output() onInputChange = new EventEmitter();
    @Output() keyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
    @Output() focusOut = new EventEmitter();
    @Output() blur = new EventEmitter();

    abstract get inputValue(): T;
    
    override init(): void {
        super.init();
        this.input.tabIndex = this.tabIndex;
    }

    updateModel() {
        this.valueChange.emit(this.inputValue);
        if (!this.modelUpdate ||
            (this.propertyViewModel.value == null &&
                (this.inputValue == null || (typeof this.inputValue === 'string' && this.inputValue === '')
                )
            ) || (this.propertyViewModel.value === this.inputValue)) {
            return;
        }

        this.propertyViewModel.value = this.inputValue;
    }
   
    onInput(e: Event) {
        if (!this.updateOnBlur) {
            this.updateModel();
        }
        this.onInputChange.emit(e)
    }

    onFocusOut(e: Event) {
        if (this.updateOnFocusOut) {
            this.updateModel();
        }
        this.focusOut.emit(e);
    }

    onBlur(e) {
        if (!this.isDisabled && this.propertyViewModel.isEnabled) {
            if (this.updateOnBlur) {
                this.updateModel();
            }
            this.propertyViewModel.validate();
        }
        this.blur.emit(e);
    }

    protected onKeyDown(ev: KeyboardEvent) {
        this.keyDown.emit(ev);
    }
}
