import { Expose, Type } from '@nts/std/serialization';
import { LayoutDefinitionIdentity } from './layout-definition.identity';

export class BaseLayoutDataDto {

    @Expose()
    @Type(() => LayoutDefinitionIdentity)
    layoutIdentity?: LayoutDefinitionIdentity;

    @Expose()
    serviceFullName?: string;

}
