import moment from 'moment';
import { UICommandInterface } from './../../../../../view-models/commands/ui-command.interface';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { Calendar, CalendarModule } from 'primeng/calendar';
import _Inputmask from 'inputmask';
import { BaseEnumTextBoxComponent } from '../base-enum-text-box/base-enum-text-box.component';
import { TimeZoneData, TimeZoneInterface } from '@nts/std/timezone';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { BaseDateMaskTextBoxComponent, DateMaskSettings } from '../base-date-mask-text-box/base-date-mask-text-box.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { PopperHelper } from '@nts/std/utility';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'nts-base-date-text-box',
    templateUrl: './base-date-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-date-text-box.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        CalendarModule,
        FormsModule,
        BaseEnumTextBoxComponent,
        NgFor,
        NgClass,
        AsyncPipe,
        BaseDateMaskTextBoxComponent,
        NgIf
    ]
})
export class BaseDateTextBoxComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
    @Input() dateTabIndex?: number;
    @Input() valueDate = null;
    @Input() dateFormat = null;
    @Input() appendTo: string | HTMLDivElement = 'body';
    @Input() isRequired = false;
    @Input() isDisabled = false;
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() showTime = false;
    @Input() showButtonBar = false;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() showOnFocus = false;
    @Input() maskSettings: DateMaskSettings;
    @Input() showIcon = true;
    @Input() hideOnDateTimeSelect = true;
    @Input() selectOtherMonths = true;
    @Input() yearNavigator = true;
    @Input() monthNavigator = true;
    @Input() title = '';
    @Input() defaultDate = moment().startOf('day').toDate();
    @Input() initialChar: string | null = null;
    @Input() listenClickOutside = false;

    @Input() timeZoneTabIndex!: number;
    @Input() showOffset = false;
    @Input() showTimeZone = false;
    @Input() valueTimeZone: TimeZoneData | null = null;
    @Input() timeZoneOptions: Array<TimeZoneInterface>;
    @Input() timeZonePlaceholder: string;
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;

    @Output() onDateValueChange = new EventEmitter();
    @Output() onDateFocus = new EventEmitter();
    @Output() onDateBlur = new EventEmitter();
    @Output() onDateSelect = new EventEmitter();
    @Output() onFinishEditing = new EventEmitter();

    @Output() onTimeZoneChange = new EventEmitter();
    @Output() onTimeZoneBlur = new EventEmitter();
    @Output() keyDown = new EventEmitter();

    @ViewChild('dateMaskBox', { static: true }) dateMaskBox: BaseDateMaskTextBoxComponent;
    @ViewChild('calendar', { static: true }) calendar: Calendar;
    @ViewChild('timeZoneCombo', { static: true }) baseTimeZoneCombo?: BaseEnumTextBoxComponent;
    @ViewChild('dateContainer', { static: true }) dateContainer?: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError?: NgxPopperjsDirective;
    
    //originOnUserInput;
    mask = null;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overrideBorderColor = null;
    timezonOptions$ = new BehaviorSubject<Array<TimeZoneInterface>>([]);

    private documentClickListener: any;

    constructor(
        private el: ElementRef,
        private zone: NgZone,
        private renderer: Renderer2,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        
        if (!this.dateFormat) {
            let inputFormat = moment().localeData().longDateFormat('L').toLowerCase();
            this.dateFormat = inputFormat.substring(0, inputFormat.length - 2);
        }

        this.initMaskSettings(!this.isRequired);
        this.timezonOptions$.next(this.timeZoneOptions);
    }
    
    ngOnChanges(changes: SimpleChanges) {

        if (changes['maskSettings'] && changes['maskSettings'].currentValue){
            this.initMaskSettings(!this.isRequired);
        }

        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }

        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    ngAfterViewInit(): void {
        this.bindDocumentClickListener();
        this.handleOverridesColors(); 
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;

        const button = this.calendar.el.nativeElement.querySelector(".p-datepicker-trigger");
        if (button){
            button.style.borderColor = this.overrideBorderColor
        }
    }
    
    calendarDateValueChange(value: any)
    {
        //if (moment(this.valueDate, this.dateFormat, true).isValid()){
            this.valueDate = value;
            //this.onDateValueChange.emit(value)
       // }
    }

    inputDateValueChange(value: any)
    {
        //if (moment(this.valueDate, this.dateFormat, true).isValid()){
            this.valueDate = value;
            this.onDateValueChange.emit(value)
        //}
    }

    // Date
    get input(): HTMLInputElement {
        return this.dateMaskBox.dateBox.nativeElement;
    }

    protected initMaskSettings(nullable: boolean): any {

        let inputFormat = moment().localeData().longDateFormat('L');
        if (this.showTime === true) {
            inputFormat += ' HH:mm';
        }
        
        this.maskSettings = {
            format: inputFormat,
            min: this.minDate,
            max: this.maxDate,
            showTime: this.showTime,
            nullable: this.isRequired
        }
    }

    onSelect($event){
        this.onDateSelect.emit($event)
        this.input.focus();
    }

    onClickOutside($event): void {
        if (this.listenClickOutside) {
            this.onDateValueChange.emit(this.valueDate);
            this.onFinishEditing.emit();
        }
    }

    onBlur(event) {
        this.popperError?.hide();
        this.onDateBlur.emit(event);
    }

    onInput(event) {
        if (this.calendar.overlayVisible) {
            this.calendar.hideOverlay();
        }
    }

    onClose(event): void {
        if (!this.documentClickListener && this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
    }

    getValueDate(): Date{
        return moment(this.valueDate, this.dateFormat, true).isValid() ? this.valueDate: null;
    }

    onShow(event) {
        this.unbindDocumentClickListener();
    }

    timeZoneChange($event): void {
        this.valueTimeZone = $event.value;
        this.onTimeZoneChange.emit(this.valueTimeZone);
    }
    
    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    private bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private isOutsideClicked(event: Event) {
        return !this.dateContainer?.nativeElement.contains(event.target);
        //return !(this.calendar.el.nativeElement.isSameNode(event.target) || this.isDatePickerClicked(event) || this.isDropDownClicked(event) ||
        //    this.calendar.el.nativeElement.contains(event.target));
    }

    private unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    private isDatePickerClicked(event: Event) {
        return this.calendar.el.nativeElement.contains(event.target) || (this.calendar.overlay && this.calendar.overlay.contains(<Node>event.target));
    }

    private isDropDownClicked(event: Event) {
        return this.baseTimeZoneCombo ? this.baseTimeZoneCombo.combo.el.nativeElement.contains(event.target) : false;
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}
