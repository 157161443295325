import { Expose, Type } from '@nts/std/serialization';
import { CustomReportItemIdentity } from "./custom-report/custom-report-item.identity";
import { StandardReportIdentity } from "./standard-report/standard-report.identity";

export class ReportFileSelectionDto {

    constructor() {
        this.standardReportIdentity = new StandardReportIdentity();
        this.customReportItemIdentity = new CustomReportItemIdentity();
    }

    @Expose()
    @Type(() => StandardReportIdentity)
    standardReportIdentity: StandardReportIdentity;

    @Expose()
    @Type(() => CustomReportItemIdentity)
    customReportItemIdentity: CustomReportItemIdentity;
}
