import { Expose } from "@nts/std/serialization";

export class OutputDataOrderDto {

    @Expose()
    public position: number;

    @Expose()
    public propertyName: string;
    
    @Expose()
    public isVisible: boolean;
    
    @Expose()
    public caption: string;
}