import { NTSReflection } from '@nts/std/utility';

const ROOT_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'rootModelTypeDecoratorMetaDataKey';

/**
 * Attenzione! Utilizzare la classe e non il nome della classe! (non deve essere una stringa)
 */
export function RootModelTypeDecorator(rootModelType: any) {
    return (target: any) => {
        Reflect.defineMetadata(ROOT_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, rootModelType, target);
    };
}

export class RootModelTypeInspector {

    static META_DATA_KEY = ROOT_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@RootModelTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            ROOT_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            ROOT_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
