import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from './base-identity';
import { IdentityTypeNameDecorator } from './decorators/identity-type-name.decorator';

/**
 * Identity per i modelli di configurazione che conterranno sempre e solo un aggregato per ditta(una riga).
 */
@IdentityTypeNameDecorator('CompanySingleAggregateIdentity')
export class CompanySingleAggregateIdentity extends BaseIdentity {

    private _companyId: number = 0;

    @Expose()
    get companyId(): number {
        return this.getValue<number>(() => this._companyId, 'companyId');
    }
    set companyId(value: number) {
        this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
    }

}
