import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from './base-identity';
import { IdentityTypeNameDecorator } from './decorators/identity-type-name.decorator';

@IdentityTypeNameDecorator('SingleAggregateIdentity')
export class SingleAggregateIdentity extends BaseIdentity {

    private _fixedIdentity: number;

    @Expose()
    get fixedIdentity(): number {
        return this.getValue<number>(() => this._fixedIdentity, 'fixedIdentity');
    }
    set fixedIdentity(value: number) {
        this.setValue<number>(() => { this._fixedIdentity = value; }, value, 'fixedIdentity');
    }

}
