import { UserLayoutGridMetaData } from '../../layout-meta-data/user-layout-grid-meta-data';
import { Expose, Type } from '@nts/std/serialization';
import { BaseLayoutDataDto } from './base-layout-data.dto';

export class GridUserLayoutDataDto extends BaseLayoutDataDto {

    constructor() {
        super();
        this.gridMetaData = new UserLayoutGridMetaData();
    }

    @Expose()
    @Type(() => UserLayoutGridMetaData)
    gridMetaData: UserLayoutGridMetaData;
}
