import { Component, ChangeDetectionStrategy, ViewChild, Input, OnInit } from '@angular/core';
import { LogService } from '@nts/std/utility';
import { BoolPropertyViewModel } from '../../../../../src/lib/view-models/base-type/bool-property-view-model';
import { BaseCheckboxTextBoxComponent } from '../../../../../src/lib/components/controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
import { GridComponent } from '../../grid.component';
import { ColumnCellEditorParams } from '../column_cell_editor_params.interface';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
// create your cellEditor as a Angular component

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 9;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;
@Component({
    selector: 'nts-bool-text-box-editor-cell',
    styleUrls: ['./bool-cell-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseCheckboxTextBoxComponent,
        AsyncPipe
    ],
    template: `
        <nts-base-checkbox-text-box
            #checkBox
            (onChange)="valueChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [showErrorBorder]="false"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [listenClickOutside]="true"
            [isDisabled]="!(propertyViewModel.isEnabled$ | async)"
            [checkStatus]="propertyViewModel.value$ | async">
        </nts-base-checkbox-text-box>
    `
})
export class BoolCellEditorComponent implements OnInit {

    @Input()
    params: ColumnCellEditorParams;

    @ViewChild('checkBox', { static: true }) checkBox: BaseCheckboxTextBoxComponent;

    value: boolean;
    private cancelBeforeStart = false;

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    agInit(params: ColumnCellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        this.cancelBeforeStart = false;
        // if (params.eventKey > 0 || params.eventKey?.length > 0) {
        if (params.eventKey?.length > 0) {
            this.cancelBeforeStart = true;
        }

    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.checkBox.checkboxBox.nativeElement.focus();
        })
    }

    onFinishEditing() {
        this.params.stopEditing();
    }

    setInitialState(params: ColumnCellEditorParams) {
        // let startValue;
        // let highlightAllOnFocus = true;

        // if (params.keyPress === KEY_ENTER || params.keyPress === KEY_DELETE) {
        //     // if backspace or delete pressed, we clear the cell
        //     startValue = '';
        // } else

        // if (params.eventKey) {
        //     // if a letter was pressed, we start with the letter
        //     startValue = params.eventKey;
        //     highlightAllOnFocus = false;
        // } else {
        // otherwise we start with the current value
        const startValue = params.columnInfo.fieldName.split('.').reduce(
            (o, i) => {
                if (i === 'value') {
                    return o[i];
                }
                return o[i];
            }, this.params.data) as boolean;

        // }

        this.value = startValue;
    }

    // for testing
    setValue(newValue: any) {
        this.checkBox.checkboxBox.nativeElement.checked = newValue;
        this.checkBox.checkboxBox.nativeElement.dispatchEvent(new Event('change'));
    }

    valueChange(value: any) {
        if (this.value != value) {
            this.value = value;
        }
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    onKeyDown(event: any): void {
        // if (this.isLeftOrRight(event)) {
        //     event.stopPropagation();
        //     return;
        // }

        // if (
        //     !this.finishedEditingPressed(event) &&
        //     !this.isKeyPressedString(event)
        // ) {
        //     if (event.preventDefault) { event.preventDefault(); }
        // }

        if (this.isUpOrDown(event) || this.isLeftOrRight(event)) {
            this.navigateToNextCell(event);
        }
    }

    private getCharCodeFromEvent(event: any): any {
        event = event || window.event;
        return typeof event.which == 'undefined' ? event.keyCode : event.which;
    }

    private isLeftOrRight(event: any) {
        return [KEY_LEFT, KEY_RIGHT].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private isUpOrDown(event: any) {
        return [KEY_UP, KEY_DOWN].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    get propertyViewModel(): BoolPropertyViewModel {
        return this.params.node.data[this.params.columnInfo.propertyName];
    }

    get gridContext(): GridComponent {
        return this.params.context;
    }

    blur($event) {
        this.propertyViewModel.validate();
    }

    private navigateToNextCell(event: any) {

        const key = this.getCharCodeFromEvent(event);

        const fc = this.gridContext.api.getFocusedCell();
        LogService.debug('focused cell rowindex ' + fc.rowIndex, 'focused celll column ' + fc.column)
        if (key === KEY_DOWN) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex + 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_UP) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex - 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_LEFT) {
            this.gridContext.api.tabToPreviousCell();
        } else if (key === KEY_RIGHT) {
            this.gridContext.api.tabToNextCell();
        }
    }
}
