import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { DateTimeOffsetPropertyViewModel } from './base-type';

export class AuditViewModelInterface {

    updateUser: StringPropertyViewModel;

    updateDate: DateTimeOffsetPropertyViewModel;

    creationUser: StringPropertyViewModel;

    creationDate: DateTimeOffsetPropertyViewModel;
}
