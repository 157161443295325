import { ToastMessageService } from './layout/toast-message/toast-message.service';
import { Injectable, StaticProvider } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewModelLocator } from '../view-models/view-model-locator';
import { ProvidersForViewModel } from '../view-models/providers-for-view-model';
import { ModalService } from '../view-models/modal/modal.service';
import { AuthService } from '../auth/auth.service';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { OnlineService } from '@nts/std/utility';
import { ResponseCacheService } from '../responses/response-cache.service';
import { WebApiServiceAgent } from '../api-clients/web-api-service-agent';

@Injectable()
export class ComponentLocator {

    private _registeredViewModelComponenteAssociations = new Map<any, any>();

    private _registeredCustomComponentAssociations = new Map<string, any>();

    constructor(
        private modalService: ModalService,
        protected httpClient: HttpClient,
        protected environmentConfiguration: EnvironmentConfiguration,
        protected authService: AuthService,
        protected toastMessageService: ToastMessageService,
        protected onlineService: OnlineService,
        protected responseCacheService: ResponseCacheService,
        protected webApiServiceAgent: WebApiServiceAgent
    ) {
        ViewModelLocator.registerZoomOrchestratorViewModelProviders(
            this.getStaticProvidersForZoomOrchestratorViewModel(modalService)
        );
    }

    protected getStaticProvidersForZoomOrchestratorViewModel(modalService: ModalService) {
        return ProvidersForViewModel.staticProvidersForZoomOrchestratorViewModel(
            modalService,
            this.httpClient,
            this.environmentConfiguration,
            this.authService,
            this.toastMessageService,
            this.onlineService,
            this.responseCacheService,
            this.webApiServiceAgent
        );
    }

    getComponentType(viewModelType: any): any {
        return this._registeredViewModelComponenteAssociations.get(viewModelType);
    }

    getCustomComponentType(customComponentUniqueId: string): any {
        return this._registeredCustomComponentAssociations.get(customComponentUniqueId);
    }

    registerAllForDomainModelType(
        domainModelName: string,
        orchestratorDocumentViewModelType: any,
        rootViewModelType: any,
        componentType: any,
        apiClientType: any,
        wingViewModelType?: any,
        wingComponentType?: any
    ) {

        this.registerAllForDomainModelTypeWithProviders(
            domainModelName,
            orchestratorDocumentViewModelType,
            rootViewModelType,
            componentType,
            [
                {
                    provide: HttpClient,
                    useValue: this.httpClient
                },
                ...ProvidersForViewModel.staticProvidersForOrchestratorViewModel(
                    orchestratorDocumentViewModelType,
                    apiClientType,
                    this.modalService,
                    this.httpClient,
                    this.environmentConfiguration,
                    this.authService,
                    this.toastMessageService,
                    this.onlineService,
                    this.responseCacheService,
                    this.webApiServiceAgent
                )
            ],
            wingViewModelType,
            wingComponentType
        );

    }

    registerViewModelComponentAssociation(viewModelType: any, componentType: any) {
        this._registeredViewModelComponenteAssociations.set(viewModelType, componentType);
    }

    registerCustomComponentAssociation(customComponentUniqueId: string, customComponentType: any) {
        this._registeredCustomComponentAssociations.set(customComponentUniqueId, customComponentType);
    }

    registerAllForDomainModelTypeWithProviders(
        domainModelName: string,
        orchestratorViewModelType: any,
        rootViewModelType: any,
        componentType: any,
        orchestratorViewModelProviders: StaticProvider[],
        wingViewModelType?: any,
        wingComponentType?: any
    ) {

        this.registerViewModelComponentAssociation(
            rootViewModelType, componentType);

        ViewModelLocator.registerDomainModelNameOrchestratorViewModel(
            domainModelName.toLowerCase(),
            orchestratorViewModelType,
            [
                {
                    provide: HttpClient,
                    useValue: this.httpClient
                },
                ...orchestratorViewModelProviders,
            ]
        );

        if (wingViewModelType) {
            // Wing
            this.registerViewModelComponentAssociation(
                wingViewModelType, wingComponentType);

            ViewModelLocator.registerOrchestratorViewModelWingViewModel(
                orchestratorViewModelType,
                wingViewModelType
            );
        }
    }
}
