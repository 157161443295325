import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, ViewChild, ElementRef, ViewContainerRef, Output, EventEmitter, ChangeDetectorRef, NgZone, Renderer2, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { BaseDateTimePropertyViewModel, NDateTimePropertyViewModel } from '../../../../view-models/base-type/date-time-property-view-model';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseDatePropertyComponent } from '../base-date-property-component';
import { Observable, merge } from 'rxjs';
import { BaseDateTextBoxComponent } from '../base/base-date-text-box/base-date-text-box.component';
import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { PopperHelper } from '@nts/std/utility';
import { PropertyViewModelPropertyChangedEventArgs } from '../../../../view-models/property-view-property-changed-event-args';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'nts-date-text-box',
    templateUrl: './date-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./date-text-box.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        BaseDateTextBoxComponent,
        BaseSecurityTextBoxComponent,
        AsyncPipe
    ]
})
export class DateTextBoxComponent extends BaseDatePropertyComponent<Date> implements AfterViewInit, OnChanges, OnInit {

    @Input() isDisabled = false;
    @Input() tabIndex!: number;
    @Input() appendTo: string | HTMLDivElement = 'body';
    @Input() customCommandsName = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() initialChar: string | null = null;
    @Input() listenClickOutside = false
    @Input() primaryColor = null;

    @Output() onFinishEditing = new EventEmitter();
    @Output() keyDown = new EventEmitter();

    @ViewChild('baseDateTextBox', { static: false }) baseDateTextBox!: BaseDateTextBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox!: BaseSecurityTextBoxComponent;

    override propertyViewModel!: BaseDateTimePropertyViewModel;
    valueDate: Date | null = null;
    todayMidnight = moment().startOf('day').toDate();
    showButtonBar = false;
    maskSettings: any;

    get isNullable(): boolean {
        return this.propertyViewModel instanceof NDateTimePropertyViewModel;
    }

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseDateTextBox.dateMaskBox.dateBox.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    get inputValue() {
        return this.baseDateTextBox.dateMaskBox.getDateFromString(this.input.value);
    }

    showErroTooltip(): void {
        if (this.baseDateTextBox?.popperError) {
            PopperHelper.show(this.baseDateTextBox.popperError);
        }        
    }

    private _lastPropertyViewModelValue!: Date;

    constructor(
        cd: ChangeDetectorRef,
    ) {
        super(cd);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {

        if (changes['propertyViewModel']) {
            this.destroyOnChange$.next(true);

            // Rimane in ascolto quando cambia il pvm e il valore
            merge(
                this.propertyViewModel.customGetterValueChanged,
                this.propertyViewModel.propertyViewModelChanged,
                this.propertyViewModel.modelChanged,
                this.propertyViewModel.propertyChanged.pipe(filter((args: PropertyViewModelPropertyChangedEventArgs) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.setValueFromOutside();
            });

            // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
            this.propertyViewModel.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$),
                filter((args: PropertyViewModelPropertyChangedEventArgs) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
            ).subscribe(() => {
                this.cd.detectChanges();
            });
            this.propertyViewModel.onErrorStatusChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$))
                .subscribe(() => {
                    this.cd.detectChanges();
            });
            this.setValueFromOutside();

            this.propertyViewModel.onFocusRequested.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.focus(this.propertyViewModel.hasErrors);
                this.input.select();
            });
        }
    }

    ngOnInit(): void {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }    

    ngAfterViewInit(): void {

        if (this.baseDateTextBox?.calendar) {
            this.input.tabIndex = this.tabIndex;

            this.input.onkeydown = (ev: KeyboardEvent) => {
                this.onKeyDown(ev);
            };

            // remove tab index from button
            const button = this.baseDateTextBox.calendar.el.nativeElement.querySelector('button');
            button.setAttribute('tabindex', -1);

            this.maskSettings = this.getSettingForInputMask(!this.propertyViewModel.isRequired);
        }
    }

    onDateSelect($event: any): void {
        this.valueDate = $event

        this.valueChange.emit(this.valueDate);
        if (this.modelUpdate) {
            this.propertyViewModel.value = this.valueDate as any;
        }
    }

    onDateFocus($event: any): void {
        this.input.select();
    }

    onDateValueChange($event: any): void {
        this.valueDate = $event
        this.onDateBlur();
    }

    async onDateBlur(): Promise<void> {
            if (!this.isDisabled && this.propertyViewModel.isEnabled) {
                if (this.valueDate == null) {
                    this.valueChange.emit(null);
                    if (this.modelUpdate) {
                        await this.propertyViewModel.resetValue(false);
                        this._lastPropertyViewModelValue = this.propertyViewModel.value;

                    }
                } else {
                    this.valueChange.emit(this.valueDate);
                    if (this.modelUpdate) {
                        await this.propertyViewModel.setValueAsync(this.valueDate);
                        this._lastPropertyViewModelValue = this.propertyViewModel.value;
                    }
                }
            }
        
    }

    private onKeyDown(ev: KeyboardEvent) {
        this.keyDown.emit(ev);
    }

    private setValueFromOutside(): void {
        this._lastPropertyViewModelValue = this.valueDate = this.propertyViewModel.value;
        this.cd.detectChanges();
    }
}
