import { Expose } from "@nts/std/serialization";
import { SimpleFieldType } from './simple-field-meta-data';

export enum ExternalFieldInputMode {
    Simple = 0,
    Autocomplete = 1,
    ExternalList = 2
}

export class  ExternalFieldMetaData {

    constructor(path?: string, name?: string, isAutocomplete?: boolean) {
        this.path = path ?? null;
        this.name = name ?? null;
        this.inputMode = isAutocomplete ? ExternalFieldInputMode.Autocomplete : ExternalFieldInputMode.Simple;
    }

    @Expose()
    path: string;

    @Expose()
    name: string;

    @Expose()
    displayName: string;

    @Expose()
    public inputMode: ExternalFieldInputMode;

    public get isAutocomplete() {
        return this.inputMode === ExternalFieldInputMode.Autocomplete;
    };

    public get isExternalList() {
        return this.inputMode === ExternalFieldInputMode.ExternalList;
    };

    @Expose()
    defaultValue?: string;

    @Expose()
    primitiveType?: SimpleFieldType
}
