import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/zoom-property-view-model';

@Component({
  template: '',
  standalone: true,
})
export abstract class BaseZoomFilterPropertyComponent<T> {

  @Input()
  propertyViewModel: ZoomPropertyViewModel<T>;

  protected abstract get input(): HTMLInputElement;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected destroyOnChange$: Subject<boolean> = new Subject<boolean>();

  protected init() {
    // if (!this.input.classList.contains('form-control-danger')) {
    //   this.input.classList.add('form-control-danger');
    // }

    this.propertyViewModel.focus.subscribe(() => {
      this.input.focus();
    })
  }

}
