import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from './base-identity';
import { IdentityTypeNameDecorator } from './decorators/identity-type-name.decorator';

@IdentityTypeNameDecorator('LongOpIdentity')
export class LongOpIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get longOpId(): number {
        return this.getValue<number>(() => this._id, 'longOpId');
    }
    set longOpId(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'longOpId');
    }
}

