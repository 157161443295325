import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { FrameworkServiceApiClient } from '../api-clients/framework-service-api-client';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { LogService } from '@nts/std/utility';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';

@UntilDestroy()
@Injectable()
export class TokenService {

    private onSessionRefreshingNeededInProgress = false;
    private onUserProfileRefreshingNeededInProgress = false;
    private onTenantProfileRefreshingNeededInProgress = false;

    constructor(
        private authService: AuthService,
        private frameworkServiceApiClient: FrameworkServiceApiClient
    ) {
        authService.onSessionRefreshingNeeded.pipe(
            untilDestroyed(this),
            filter(() => !this.onSessionRefreshingNeededInProgress)
        ).subscribe(async (refreshToken) => {
            this.onSessionRefreshingNeededInProgress = true;
            // Check if refresh token is valid
            if (this.authService.isTokenValid(refreshToken)) {
                const result = await firstValueFrom(this.frameworkServiceApiClient.refreshToken(refreshToken, 10));
            
                if (result.operationSuccedeed) {
                    LogService.debug('onSessionRefreshingNeeded ok', result);
                    await this.authService.setRefreshToken(result.result.refresh_token);
                    await this.authService.setAccessToken(result.result.access_token);
                } else {
                    LogService.warn('onSessionRefreshingNeeded failed', result);
                    await this.authService.clearAuthData();
                    this.authService.onSessionRefreshingError.next(result);
                }
            } else { // refresh token is not valid
                LogService.warn('onSessionRefreshingNeeded failed, refreshToken is not valid!');
                await this.authService.clearAuthData();
                this.authService.onSessionRefreshingError.next(null);
            }
            
            this.onSessionRefreshingNeededInProgress = false;
        });

        authService.onSessionExpired.pipe(untilDestroyed(this), take(1)).subscribe(() => {
            const msg = MessageResourceManager.Current.getMessage('std_SessionExpired');
            alert(msg); // TODO usare il modal service
            authService.logIn();
        });

        authService.onUserProfileRefreshingNeeded
            .pipe(
                untilDestroyed(this),
                filter((need) => need === true),
                filter(() => !this.onUserProfileRefreshingNeededInProgress)
            )
            .subscribe(async (need: boolean) => {
                this.onUserProfileRefreshingNeededInProgress = true;
                authService.onUserProfileRefreshingNeeded.next(false);

                const isUserAuthenticated = await firstValueFrom(authService.isUserAuthenticated());
                if (isUserAuthenticated) {
                    const result = await firstValueFrom(frameworkServiceApiClient.getUserProfile());
                    if (result.operationSuccedeed) {
                        LogService.debug('onUserProfileRefreshingNeeded ok', result);
                        authService.setUserProfile(result.result);
                    } else {
                        LogService.warn('onUserProfileRefreshingNeeded failed', result);
                        authService.onUserProfileRefreshingError.next(result);
                    }
                } else {
                    LogService.warn('onUserProfileRefreshingNeeded failed: impossibile autenticarsi');
                    authService.onUserProfileRefreshingError.next(null);
                }

                authService.onUserProfileRefreshingNeeded.next(false);
                this.onUserProfileRefreshingNeededInProgress = false;
            });

        authService.onTenantProfileRefreshingNeeded
            .pipe(
                untilDestroyed(this),
                filter((need) => need === true),
                filter(() => !this.onTenantProfileRefreshingNeededInProgress)
            )
            .subscribe(async (need: boolean) => {
                this.onTenantProfileRefreshingNeededInProgress = true;
                authService.onTenantProfileRefreshingNeeded.next(false);
                authService.onTenantProfileRefreshingInProgress.next(true);

                const isUserAuthenticated = await firstValueFrom(authService.isUserAuthenticated());
                if (isUserAuthenticated) {
                    const result = await firstValueFrom(frameworkServiceApiClient.getTenantProfile());
                    if (result.operationSuccedeed) {
                        LogService.debug('onTenantProfileRefreshingNeeded ok', result);
                        authService.setTenantProfile(result.result);
                    } else {
                        LogService.warn('onTenantProfileRefreshingNeeded failed', result);
                        authService.onTenantProfileRefreshingError.next(result);
                    }
                } else {
                    LogService.warn('onTenantProfileRefreshingNeeded failed: impossibile autenticarsi');
                    authService.onTenantProfileRefreshingError.next(null);
                }

                authService.onTenantProfileRefreshingNeeded.next(false);
                this.onTenantProfileRefreshingNeededInProgress = false;
                authService.onTenantProfileRefreshingInProgress.next(false);
            });
    }
}
