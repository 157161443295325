import { CoreModel } from '../domain-models/core-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { MasterDetailRootViewModel } from './master-detail-root-view-model';
import { VersionPropertyViewModel } from './base-type/version-property-view-model';

export class OCCMasterDetailRootViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends MasterDetailRootViewModel<TModel, TIdentity> {

    private _occ: VersionPropertyViewModel;

    get occ(): VersionPropertyViewModel {
        return this.getVersionPropertyViewModel((value) => { this._occ= value; }, this._occ, 'occ');
    }
}
