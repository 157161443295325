export class AssociationOptionsInterface {

    principalPName1?: string;
    dependantPName1?: string;
    principalPName2?: string;
    dependantPName2?: string;
    principalPName3?: string;
    dependantPName3?: string;
    principalPName4?: string;
    dependantPName4?: string;
}
