import { LayoutMetaData } from "../../layout-meta-data/layout-meta-data";
import { Expose, Type } from '@nts/std/serialization';
import { LayoutDefinitionIdentity } from "./layout-definition.identity";

export class AvailableLayoutDto {

    constructor() {
        this.identity = new LayoutDefinitionIdentity();
        this.metaData = new LayoutMetaData();
    }

    @Expose()
    @Type(() => LayoutDefinitionIdentity)
    identity: LayoutDefinitionIdentity;

    @Expose()
    isPreferred: boolean;

    @Expose()
    displayName: string;

    @Expose()
    @Type(() => LayoutMetaData)
    metaData: LayoutMetaData

}
