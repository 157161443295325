import { Expose, Transform, TransformFnParams } from '@nts/std/serialization';
import { DateTimeOffset } from './date-time-offset';

export class SnapShotFrameItem {

    @Expose()
    identity: number;

    @Expose()
    updateUser: string;

    @Expose()
    @Transform((params: TransformFnParams) => {
        return DateTimeOffset.transform('updateDate', params);
    })
    updateDate: Date;

    @Expose()
    creationUser: string;

    @Expose()
    @Transform((params: TransformFnParams) => {
        return DateTimeOffset.transform('creationDate', params);
    })
    creationDate: Date;

    @Expose()
    tenantId: number;
}
