import { Expose, Type } from '@nts/std/serialization';
import { ExtraLayoutMetaData } from "./extra-layout-meta-data";
import { PanelMetaData } from "./panel-meta-data";

export class LayoutMetaData {

    constructor() {
        this.panels = [];
        this.extraFields = new ExtraLayoutMetaData();
    }

    @Expose()
    @Type(() => PanelMetaData)
    panels: PanelMetaData[];

    @Expose()
    @Type(() => ExtraLayoutMetaData)
    extraFields: ExtraLayoutMetaData;

}
