import { NTSReflection } from '@nts/std/utility';
import { ExternalViewModel } from '../external-view-model';

const EXTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY = 'externalViewModelTypeDecoratorMetadataKey';

export function ExternalViewModelTypeDecorator(externalViewModelType: any) {
    return (target: any, propertyKey: any) => {
        Reflect.defineMetadata(EXTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, externalViewModelType, target, propertyKey);
    };
}

export class ExternalViewModelTypeInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        return NTSReflection.hasPropertyMetadata(
            EXTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);
    }

    static getValue(subject: any, propertyName: string): any {
        let value = NTSReflection.getPropertyMetadata(
            EXTERNAL_VIEW_MODEL_TYPE_META_DATA_IDENTIFICATION_KEY, subject, propertyName);

        // Verifica che sia una funzione con due parametri
        if (typeof value === 'function' && value.length === 2) {
            value = value(subject, propertyName);
        }
        return value;
    }
}
