<ng-template 
    *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent">
</ng-template>

<ng-template #baseComponent>
    <nts-base-time-span-text-box 
        #baseTimeSpanBox 
        (onValueChange)="onValueChange($event)"
        (onFocus)="onFocus($event)"
        (onBlur)="onBlur($event)"
        (onFinishEditing)="onFinishEditing.emit($event)"
        [min]="propertyViewModel.metadataMinValue"
        [max]="propertyViewModel.metadataMaxValue" 
        [initialChar]="initialChar" 
        [isRequired]="!isNullable" 
        [isDisabled]="isDisabled || !(propertyViewModel.isEnabled$ | async) || propertyViewModel?.securityAccess != null"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [errorList]="propertyViewModel?.errors$ | async"
        [customCommandList]="customCommandsName" 
        [listenClickOutside]="listenClickOutside"
        [tabIndex]="tabIndex"
        [showSeconds]="propertyViewModel.showSeconds"
        [showDays]="propertyViewModel.showDays"
        [value]="initialValue != null ? initialValue : propertyViewModel.value$ | async"
        [placeholder]="placeholder">
    </nts-base-time-span-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box 
        #baseSecurityTextBox 
        [accessMode]="propertyViewModel.securityAccess"
        [value]="propertyViewModel.formattedValue$ | async">
    </nts-base-security-text-box>
</ng-template>
