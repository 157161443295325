import { CanActivateFn, CanMatchFn, DefaultExport, LoadChildren, Route, Routes } from '@angular/router';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { authGuard } from '../auth/auth.guard';
import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { authMatchGuard } from '../auth/auth-match.guard';

export class BaseRoutes {

    static getBaseErrorsRoute() {
        return [
            {
                path: '**',
                loadComponent: () => import('../components/errors/not-found/not-found.component').then(mod => mod.NotFoundComponent),
            }
        ]
    }

    static getBaseAuthentictedRoutes(
        environment: EnvironmentConfiguration,
        children: Routes,
        loadComponent: () => Type<unknown> | Observable<Type<unknown> | DefaultExport<Type<unknown>>> | Promise<Type<unknown> | DefaultExport<Type<unknown>>> = () => import('../components/layout/main-container/main-container.component').then(mod => mod.MainContainerComponent),
        canActivate: CanActivateFn[] = [authGuard],
        canActivateChild: CanActivateFn[] = [authGuard],
        canMatch: CanMatchFn[] = [authMatchGuard]
    ): Route[] {
        return [
            {
                path: '',
                canActivate,
                canActivateChild,
                canMatch,
                data: {
                  title: environment.appTitle,
                },
                children: [
                  {
                    path: '',
                    loadComponent,
                    children,
                    canActivate,
                    canActivateChild
                  }
                ],
                runGuardsAndResolvers: 'always'
            } as Route
        ]
    }

    static getBaseRoutes(additionalDataProperties = {}, overrideLoadComponent?: () => Type<unknown> | Observable<Type<unknown> | DefaultExport<Type<unknown>>> | Promise<Type<unknown> | DefaultExport<Type<unknown>>>): Routes {
        const defaultLoadComponent =  () => import('../components/model-container/model-container.component').then(mod => mod.ModelContainerComponent);
        return [
            {
                loadComponent: overrideLoadComponent ?? defaultLoadComponent,
                path: ':identityToLookFor',
            },
            {
                loadComponent: overrideLoadComponent ?? defaultLoadComponent,
                path: '',
            }
        ];
    }
    static getEntityRoutes(title: string, entityName: string, loadChildren: LoadChildren, additionalDataProperties = {}) {
        return [
            {
                path: `manage/${entityName}`,
                data: {
                    title,
                    ...additionalDataProperties
                },
                loadChildren,
            }
        ];
    }
    static getDefaultRoutes(title: string, environment: EnvironmentConfiguration, debugMode = false) {
        return [
            debugMode ?
                {
                    path: '',
                    loadComponent: () => import('../components/entry-page/entry-page.component').then(mod => mod.EntryPageComponent),
                    data: {
                        title: environment.appTitle
                    }
                } : {}
        ];
    }
}
