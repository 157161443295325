import { Expose } from "@nts/std/serialization";

export class MetaDataDescriptions {

    @Expose()
    displayNameKey: string;

    @Expose()
    shortNameKey: string;

    @Expose()
    descriptionKey: string;

    @Expose()
    displayName: string;

    @Expose()
    shortName: string;

    @Expose()
    description: string;

}
