import { BaseIdentity } from '../domain-models/base-identity';
import { BaseRowNumberModel } from '../domain-models/base-row-number-model';
import { BaseRowNumberItemViewModel } from './base-row-number-item-view-model';
import { BaseRowNumberCollectionViewModel } from './base-row-number-collection-view-model';

export class BaseSerialRowNumberCollectionViewModel<
    TItemViewModel extends BaseRowNumberItemViewModel<TModel, TIdentity>,
    TModel extends BaseRowNumberModel<TIdentity>,
    TIdentity extends BaseIdentity
    > extends BaseRowNumberCollectionViewModel<TItemViewModel, TModel, TIdentity> {
}
