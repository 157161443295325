import { Expose, Type } from '@nts/std/serialization';
import { LayoutDefinitionIdentity } from "../layout/layout-definition.identity";

export class GridUserLayoutIdentityDto {
    @Expose()
    gridFullPathName: string

    @Expose()
    @Type(() => LayoutDefinitionIdentity)
    layoutIdentity: LayoutDefinitionIdentity;

    @Expose()
    serviceFullName: string;
}
