import { Expose, Type } from '@nts/std/serialization';
import { Filter } from '../find-options/filter';

export class AutoCompleteBaseOptions {

    constructor() {
        this.propertySearchList = [];
        this.orderByPropertyNames = [];
        this.additionalFilters = [];
    }

    @Expose()
    searchValue: string;

    @Expose()
    propertySearchList: string[];

    @Expose()
    @Type(() => OrderBy)
    orderByPropertyNames: OrderBy[];

    /**
     * Lista di filtri che sarà considerata in AND rispetto al resto
     */
    @Expose()
    @Type(() => Filter)
    additionalFilters: Filter[];
}

export class AutoCompleteModelOptions extends AutoCompleteBaseOptions {

    constructor() {
        super();
        this.includedModelPath = [];
    }

    /**
     * Indica quanti elementi recuperare per la paginazione
     */
    @Expose()
    take?: number;

    /**
     * Indica quanti elementi saltare per la paginazione
     */
    @Expose()
    skip?: number;

    /**
     * Indicare il path dei modelli da includere nel risultato dell'autocomplete, così da poter caricare internal o external locali.
     */
    @Expose()
    includedModelPath?: string[];

}

export class AutoCompleteOptions extends AutoCompleteBaseOptions {

    constructor() {
        super();
        this.outputProperties = [];
    }

    @Expose()
    outputProperties: string[];
}

export class OrderBy {

    constructor(inputData: OrderBy) {
        Object.assign(this, inputData);
    }

    @Expose()
    propertyName: string;

    @Expose()
    sortType?: OrderByType;
}

export enum OrderByType {
    Descending = 0,
    Ascending = 1
}


export class AutoCompleteFilter {

    @Expose()
    propertyPathName: string;

    @Expose()
    @Type(() => Object)
    propertyValue: any;
}

export class Property {

    @Expose()
    name: string;

    @Expose()
    @Type(() => Object)
    value: any;
}
