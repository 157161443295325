import { ExternalFieldInputMode } from "../layout-meta-data/external-field-meta-data";

export class ExternalColumnMapInfo {
    externalPropertyName: string;
    externalCodesPropertyName: { 
        propertyName: string, 
        inputMode: ExternalFieldInputMode,
        showCodeInDescription: boolean
    }[];
    externalDecodesPropertyName: string[];
}
