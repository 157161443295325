import { NTSReflection } from '@nts/std/utility';
import { NotString } from '../../../types';

const NM_ROOT_TYPE_META_DATA_IDENTIFICATION_KEY = 'nmRootTypeDecoratorMetaDataKey';

export function NmRootTypeDecorator<T>(nmRootType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(NM_ROOT_TYPE_META_DATA_IDENTIFICATION_KEY, nmRootType, target);
    };
}

export class NmRootTypeInspector {

    static META_DATA_KEY = NM_ROOT_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@NmRootTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            NM_ROOT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            NM_ROOT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
