import { Expose } from "@nts/std/serialization";

export class VersionDataDto {

    //#region Backend
    /**
     * Backend Framework version
     */
    @Expose()
    frameworkVersion: string;

    /**
     * Backend Framework commit
     */
    @Expose()
    frameworkInformativeVersion: string;

    /**
     * Backend version
     */
    @Expose()
    version: string;

    /**
     * Backend commit
     */
    @Expose()
    informativeVersion: string;
    //#endregion Backend

    //#region Frontend
    /**
     * Frontend Framework version
     */
    @Expose()
    frameworkJsVersion: string;

    /**
     * Frontend Framework commit
     */
    @Expose()
    frameworkJsInformativeVersion: string;

    /**
     * Frontend version
     */
    @Expose()
    jsVersion: string;

    /**
     * Frontend commit
     */
    @Expose()
    jsInformativeVersion: string;
    //#endregion Frontend

    //#region Microservice
    /**
     * Microservice version
     */
    @Expose()
    msVersion: string;

    /**
     * Microservice commit
     */
    @Expose()
    msInformativeVersion: string;
    //#endregion Microservice
}
