import { Observable, of } from 'rxjs';
import { CommandFactory } from '../commands/command-factory';
import { CommandTypes } from '../commands/command-types';
import { UICommandSettingsManager } from '../commands/ui-command-settings-manager';
import { UICommandSettingsManagerInterface } from '../commands/ui-command-settings-manager.interface';
import { UICommandInterface } from '../commands/ui-command.interface';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { StringPropertyViewModel } from './string-property-view-model';

export class EmailPropertyViewModel extends StringPropertyViewModel {

    sendEmailCommand: UICommandInterface;
    internalUiCommandDefaultSettingsManager: UICommandSettingsManagerInterface;

    protected getUICommandDefaultSettingsManager(): UICommandSettingsManagerInterface {
        if (!this.internalUiCommandDefaultSettingsManager) {
            this.internalUiCommandDefaultSettingsManager = new UICommandSettingsManager();
        }
        return this.internalUiCommandDefaultSettingsManager;
    }

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo);

        const manager = this.getUICommandDefaultSettingsManager();
        this.sendEmailCommand = manager.setUICommand(
            CommandTypes.SendEmail,
            CommandFactory.createUICommand(
                () => this.sendEmail(), 
                () => this.canSenEmail(), 
            )
        );
        this.customCommandList.set('send-email', this.sendEmailCommand);
        this.customCommandsName = ['send-email'];
    }

    async sendEmail() {
        if (this.value?.length >= 3) {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.value.match(validRegex)) {
                window.location.href = `mailto:${this.value}`
            }
        }
    }

    canSenEmail(): Observable<boolean> {
        return of(this.value?.length >= 3)        
    }

}