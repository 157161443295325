import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { WingViewModelInterface } from "../../view-models/wing-view-model.interface";
import { WingViewComponentInterface } from "../wing-view-component.interface";

@UntilDestroy()
@Component({
    template: '',
    standalone: true,
})
export class BaseWingComponent implements WingViewComponentInterface, OnInit {

    wingViewModel: WingViewModelInterface;

    constructor(protected readonly cd: ChangeDetectorRef) { }

    ngOnInit(): void {
    }

    async initialize(): Promise<void> {
        this.wingViewModel.wingInfoChanged.pipe(untilDestroyed(this)).subscribe((wingInfo) => {
            this.cd.detectChanges();
        })
        this.wingViewModel.orchestratorViewModel.eventDispatcher.onWingCollapsed.pipe(untilDestroyed(this)).subscribe((isCollapsed) => {
            this.onWingCollapsedChanged(isCollapsed);
            this.cd.detectChanges();
        })
    }

    async onWingCollapsedChanged(isCollapsed: boolean): Promise<void> {

    }

}
