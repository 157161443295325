import { Expose } from "@nts/std/serialization";
import { ServiceDataAccessInterface } from "./service-data-access.interface";

export class ServiceDataAccessDto implements ServiceDataAccessInterface {
    
    @Expose()
    objectName: string;
    
    @Expose()
    canAccess: boolean;

}