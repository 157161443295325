import { MultiLanguageCollectionViewModel } from '../multi-language-collection-view-model';
import { BaseMultiLanguageModel } from '../../domain-models/base-multi-language-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { MultiLanguageIdentityInterface } from '../../domain-models/multi-language-identity.interface';
import { BaseMultiLanguageViewModel } from '../base-multi-language-view-model';
import { ViewModelInterface } from '../view-model.interface';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { StringPropertyViewModel } from './string-property-view-model';
import { takeUntil } from 'rxjs';
import { ExternalViewModelInterface } from '../external-view-model.interface';
import { ExternalViewModel } from '../external-view-model';

export class CurrentMultiLanguageDescriptionPropertyViewModel extends StringPropertyViewModel {

    override canNotifyModified = false;

    override get value(): string {
        this.initCollectionViewModel();
        return this.internalValue;
    }
    
    override set value(value: string) {
        this.internalValue = value
        
        const currenLanguageItemViewModel = this.getCurrenLanguageItemViewModel();
        if (currenLanguageItemViewModel) {
            currenLanguageItemViewModel.getProperty(this.collectionItemPropertyName).value = value;
        }
        const model = this.getModel();
        model.setPropertyValue(this.collectionItemPropertyName, value);
        this.formattedValue$.next(value)
        this.value$.next(value)
    }

    get currentLanguageCode() {
        return this.internalCurrentLanguageCode;
    }

    get currentLanguageDescription() {
        return this.internalCurrentLanguageDescription;
    }

    private internalCurrentLanguageDescription = '';
    private internalCurrentLanguageCode = '';
    private internalValue: string;
    private collectionPropertyName: string;
    private collectionItemPropertyName: string;
    private parentViewModel: ViewModelInterface;
    private fallbackLanguages = ['it', 'en', 'es', 'de'];
    private collectionViewModel: MultiLanguageCollectionViewModel<
        BaseMultiLanguageViewModel<BaseMultiLanguageModel<BaseIdentity & MultiLanguageIdentityInterface>,
            BaseIdentity & MultiLanguageIdentityInterface>,
        BaseMultiLanguageModel<BaseIdentity & MultiLanguageIdentityInterface>, BaseIdentity & MultiLanguageIdentityInterface>;

    constructor(
        init: PropertyViewModelInitializationInfo,
        collectionPropertyName: string,
        collectionItemPropertyName = 'description'
    ) {
        super(init);
        this.parentViewModel = init.parent;
        this.isEnabled = false;
        this.collectionPropertyName = collectionPropertyName;
        this.collectionItemPropertyName = collectionItemPropertyName;
    }

    private getCurrenLanguageItemViewModel(): BaseMultiLanguageViewModel<BaseMultiLanguageModel<BaseIdentity & MultiLanguageIdentityInterface>, BaseIdentity & MultiLanguageIdentityInterface> | null {
        const currentLanguage = navigator.language.slice(0, 2).toLowerCase();
        const foundCurrentLanguageItemViewModel = this.collectionViewModel?.find((item) =>
            item.languageCode?.value?.toLowerCase() === currentLanguage
        );

        if (foundCurrentLanguageItemViewModel) {
            return foundCurrentLanguageItemViewModel;
        }

        const foundFallbackLanguageItemViewModel = this.fallbackLanguages.filter((lang) =>
            this.collectionViewModel?.find((item) =>
                item.languageCode?.value?.toLowerCase() === lang.toLowerCase()
            )
        ).map((lang) =>
            this.collectionViewModel?.find((item) =>
                item.languageCode?.value?.toLowerCase() === lang
            )
        ).shift();

        if (foundFallbackLanguageItemViewModel) {
            return foundFallbackLanguageItemViewModel;
        }

        return this.collectionViewModel?.length > 0 ? this.collectionViewModel[0] : null;
    }

    private initCollectionViewModel() {
        if (!this.collectionViewModel) {
            this.collectionViewModel = this.parentViewModel.relationViewModels.get(this.collectionPropertyName) as any;
            if (this.collectionViewModel) {
                this.collectionViewModel.collectionChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe(() => {
                    this.isEnabled = this.collectionViewModel.length > 0;
                    if (this.collectionViewModel.length == 0) {
                        this.internalCurrentLanguageCode = '';
                        this.internalCurrentLanguageDescription = '';
                        this.onPropertyChanged('currentLanguageCode');
                    }
                })
                this.isEnabled = this.collectionViewModel.length > 0;
                if (this.collectionViewModel.length == 0) {
                    this.internalCurrentLanguageCode = '';
                    this.internalCurrentLanguageDescription = '';
                    this.onPropertyChanged('currentLanguageCode');
                }

                this.collectionViewModel.languageChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe(() => this.calculateValueFromMultiLanguageCollection());
                this.calculateValueFromMultiLanguageCollection();
            }           
        }       
    }

    private calculateValueFromMultiLanguageCollection() {
        let currentDescription = '';
        const model = this.getModel();
        
        const currenLanguageItemViewModel = this.getCurrenLanguageItemViewModel();

        if (currenLanguageItemViewModel) {
            currentDescription = currenLanguageItemViewModel.getProperty(this.collectionItemPropertyName)?.value;
            
            let relatedExternal: ExternalViewModelInterface = null;
            for (const [key, relation] of currenLanguageItemViewModel.relationViewModels) {
                if (relation instanceof ExternalViewModel) {
                    if(relation.getCodePropertiesList().indexOf('LanguageCode') > -1) {
                        relatedExternal = relation;
                        break;
                    }
                }
            }
            
            if (relatedExternal) {
                this.internalCurrentLanguageDescription = relatedExternal.getProperty('displayName')?.value;
            }

            if (relatedExternal && relatedExternal.hasErrors == true) {
                this.internalCurrentLanguageCode = '';
            } else {
                this.internalCurrentLanguageCode = currenLanguageItemViewModel.languageCode?.value;
            }
            
            this.onPropertyChanged('currentLanguageCode');
        }
        
        this.internalValue = currentDescription;
        // force update of domain model property
        model.setPropertyValue(this.collectionItemPropertyName, currentDescription);
        this.formattedValue$.next(currentDescription)
        this.value$.next(currentDescription)
    }
}
