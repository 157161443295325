import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EnumZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/enum-zoom-property-view-model';
import { BaseZoomFilterPropertyComponent } from '../base-zoom-filter-property-component';
import { EnumPropertyViewModelItem } from '../../../../view-models/base-type/enum-property-view-model';
import { MessageResourceManager } from '../../../../resources/message-resource-manager';
import { BaseEnumTextBoxComponent } from '../../../controls/core/base/base-enum-text-box/base-enum-text-box.component';
import { BehaviorSubject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/enum-zoom-filter-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-enum-text-box',
  templateUrl: './zoom-filter-enum-text-box.component.html',
  styleUrls: ['./zoom-filter-enum-text-box.component.scss'],
  standalone: true,
  imports: [
    BaseEnumTextBoxComponent
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomFilterEnumTextBoxComponent extends BaseZoomFilterPropertyComponent<number> implements OnInit, AfterViewInit {

  @Input() filter: EnumZoomFilterViewModel;
  @ViewChild('baseEnumTextBox', { static: false }) baseEnumTextBox: BaseEnumTextBoxComponent;

  override propertyViewModel: EnumZoomPropertyViewModel;
  value: EnumPropertyViewModelItem;
  filteredValues$ = new BehaviorSubject<Array<EnumPropertyViewModelItem>>([]);

  get placeholder() {
    return MessageResourceManager.Current.getMessage('std_ComboEmptyItem');
  }

  get input(): HTMLInputElement {
    return this.baseEnumTextBox.combo.el.nativeElement.children[0].children[0].children[0];
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    this.filteredValues$.next(this.propertyViewModel.valueDescriptions);
    this.value = this.filteredValues$.value.find(x => x.key === this.propertyViewModel.value);
    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit(): void {   
    super.init();
  }  

  onBlur() {
    if (this.value == null) {
      this.propertyViewModel.setValue(null);
    } else {
      setTimeout(() => {
        this.value = this.filteredValues$.value.find(x => x.key === this.propertyViewModel.value);
      });
    }
  }

  onChange(event) {
    this.value = event.value;
    this.propertyViewModel.setValue(this.value.key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.propertyViewModel) {

      this.destroyOnChange$.next(true);

      // Rimane in ascolto quando cambia il pvm e lo stato degli errori
      merge(
        this.propertyViewModel.propertyViewModelChanged,
        this.propertyViewModel.onErrorStatusChanged
      ).pipe(
        takeUntil(this.destroy$),
        takeUntil(this.destroyOnChange$)
      ).subscribe((x) => {
        this.cd.detectChanges();
      });

      this.propertyViewModel.propertyChanged.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.destroyOnChange$)
      ).subscribe((arg) => {
        if (arg.propertyName === this.propertyViewModel.bindedValuePropertyName) {
          this.filteredValues$.next(this.propertyViewModel.valueDescriptions);
          this.value = this.filteredValues$.value.find(x => x.key === this.propertyViewModel.value);
        }        
        this.cd.detectChanges();
      })

      this.filteredValues$.next(this.propertyViewModel.valueDescriptions);
      this.value = this.filteredValues$.value.find(x => x.key === this.propertyViewModel.value);
      this.cd.detectChanges();
    }
  }
}
