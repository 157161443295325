import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ZoomResultsColumnCellRendererParams } from './zoom-results-column-cell-renderer-params.interface';
import { NgIf } from '@angular/common';
// create your cellEditor as a Angular component
@Component({
    template: `
        <div *ngIf="this.params?.data; else loading">
            {{ value }}
        </div>
        <ng-template #loading><div class="lds-ring"><div></div><div></div><div></div><div></div></div></ng-template>
    `,
    standalone: true,
    selector: 'nts-zoom-results-default-cell-renderer',
    imports: [
        NgIf,
    ]
})
export class ZoomResultsDefaultCellRendererComponent implements AgRendererComponent {
    params: ZoomResultsColumnCellRendererParams;
    refresh(params: any): boolean {
        return true;
    }
    agInit(params: ZoomResultsColumnCellRendererParams): void {
        this.params = params;
    }
    get value() {
        return this.params.data[Number.parseInt(this.params.colDef.field, 10)];
    }
}
