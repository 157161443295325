import { Expose } from "@nts/std/serialization";
import { ClientTypes } from '../client-types';

export class FindUIInfo {
    @Expose()
    objectFullName: string;

    @Expose()
    clientType: ClientTypes;
}
