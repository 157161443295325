import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { CoreModel } from '../../domain-models/core-model';
import { ActionHttpCommunication } from '../action-http-communication';
import { CreateResponse } from '../../responses/create-response';
import { Observable } from 'rxjs';
import { GenericServiceRequest } from '../../requests/generic-service-request';
import { CacheOptionsInterface, WebApiServiceAgent } from '../web-api-service-agent';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { ServiceRequest } from '../../requests/service-request';
import { ServiceResponse } from '../../responses/service-response';

export class LongOpHttpCommunication<
    TBaseLongOpModel extends BaseLongOpModel<TIdentity, TParams, TResult>,
    TIdentity extends BaseIdentity,
    TParams extends CoreModel<TIdentity>,
    TResult extends CoreModel<TIdentity>> extends ActionHttpCommunication {

    private static readonly createRequestUri: string = 'Create';
    private static readonly executeLongOpRequestUri: string = 'ExecuteLongOp';

    constructor(
        agent: WebApiServiceAgent,
        protected longOpModelType: any,
        protected longOpResultType: any,

    ) {
        super(agent);
    }

    create(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<CreateResponse<TBaseLongOpModel, TIdentity>> {
        const responseInstance = new CreateResponse<TBaseLongOpModel, TIdentity>(this.longOpModelType);
        return this.agent.invokePostWithOutBodyWithInstance<CreateResponse<TBaseLongOpModel, TIdentity>>(
            LongOpHttpCommunication.createRequestUri, 
            responseInstance,
            false,
            null,
            null,
            null,
            cacheOptions
        );
    }

    executeLongOp(
        model: TBaseLongOpModel,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GenericServiceResponse<TResult>> {
        const request: GenericServiceRequest<TBaseLongOpModel> = new GenericServiceRequest<TBaseLongOpModel>();
        request.requestData = model;
        const responseInstance = new GenericServiceResponse<TResult>(this.longOpResultType);
        return this.agent.invokePostWithResponseWithInstance<GenericServiceRequest<TBaseLongOpModel>, GenericServiceResponse<TResult>>(
            LongOpHttpCommunication.executeLongOpRequestUri, 
            request,
            responseInstance,
            false,
            null,
            null,
            null,
            cacheOptions
        );
    }

    getByObject<TRequest extends ServiceRequest, TResponse extends ServiceResponse>(
        requestInstance: TRequest,
        responseInstance: TResponse,
        uri: string,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<TResponse> {
        return this.agent.invokePostWithResponseWithInstance<TRequest, TResponse>(
            uri, 
            requestInstance, 
            responseInstance,
            false,
            null,
            null,
            null,
            cacheOptions
        );
    }
}
