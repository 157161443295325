import { DateTimeMetaData } from './date-time-meta-data';
import { EnumMetaData } from './enum-meta-data';
import { ModelVersionMetaData } from './model-version-meta-data';
import { StringMetaData } from './string-meta-data';
import { AssociationPropertyMetaData } from './association-property-meta-data';
import { CoreMetaData } from './core-meta-data';
import { BoolMetaData } from './bool-meta-data';
import { NumericMetaData } from './numeric-meta-data';
import { PropertyMetaData } from './property-meta-data';
import { Expose, Type } from '@nts/std/serialization';
import { MetaDataDescriptions } from './meta-data-descriptions';
import { MetaDataUtils } from './meta-data-utils';
import { GuidMetaData } from './guid-meta-data';
import { UserMetaData } from './user-meta-data';
import { TimeSpanMetaData } from './time-span-meta-data';
import { AggregateMetaData } from './aggregate-meta-data';
import { CloneTypes } from '../domain-models/clone-types.enum';

export class DomainModelMetaData extends CoreMetaData {

    @Expose()
    fullName: string;

    @Expose()
    @Type(() => InternalRelationMetaData)
    internalRelations: InternalRelationMetaData[];

    @Expose()
    @Type(() => ExternalMetaData)
    externals: ExternalMetaData[];

    @Expose()
    @Type(() => InternalCollectionMetaData)
    internalCollections: InternalCollectionMetaData[];

    @Expose()
    identityNames: string[];

    @Expose()
    @Type(() => BoolMetaData)
    bools: BoolMetaData[];

    @Expose()
    @Type(() => StringMetaData)
    strings: StringMetaData[];

    @Expose()
    @Type(() => GuidMetaData)
    guids: GuidMetaData[];

    @Expose()
    @Type(() => DateTimeMetaData)
    dateTimes: DateTimeMetaData[];

    @Expose()
    @Type(() => TimeSpanMetaData)
    timeSpans: TimeSpanMetaData[];

    @Expose()
    @Type(() => NumericMetaData)
    numerics: NumericMetaData[];

    @Expose()
    @Type(() => EnumMetaData)
    enums: EnumMetaData[];

    @Expose()
    @Type(() => ModelVersionMetaData)
    version: ModelVersionMetaData;

    @Expose()
    propertyNames: string[];

    @Expose()
    allPropertyNames: string[];

    /**
     * è una descrizione in lingua
     */
    @Expose()
    isMultiLanguage?: boolean

    constructor() {
        super();

        this.internalRelations = [];
        this.externals = [];
        this.internalCollections = [];

        this.identityNames = [];

        this.bools = [];
        this.strings = [];
        this.dateTimes = [];
        this.numerics = [];
        this.enums = [];
        this.guids = [];
        this.timeSpans = [];

        this.propertyNames = [];
        this.allPropertyNames = [];
    }

    getPropertyMetaData(propertyName: string): PropertyMetaData {
        return [
            ...this.bools,
            ...this.strings,
            ...this.guids,
            ...this.dateTimes,
            ...this.numerics,
            ...this.enums,
            ...this.timeSpans,
            this.version
        ].find((propertyMetaData: PropertyMetaData) =>
            MetaDataUtils.toCamelCase(propertyMetaData?.name) === MetaDataUtils.toCamelCase(propertyName)
        );
    }

    getMainDescriptionProperty(): PropertyMetaData {
        return this.strings.find((s) => s.isMainDescription === true);
    }
}

export interface DescriptionsMetaData {
    descriptions: MetaDataDescriptions;
}

export interface NameMetaData {
    name: string;
}

export class AssociationMetaData implements DescriptionsMetaData, NameMetaData {

    context?: any;

    /**
     * MetaDati che dipendono dall'utente corrente
     */
    @Expose()
    @Type(() => UserMetaData)
    userMetaData?: UserMetaData;

    @Expose()
    @Type(() => MetaDataDescriptions)
    descriptions: MetaDataDescriptions;

    @Expose()
    principalPropertyName: string;

    get name() {
        return this.principalPropertyName;
    }

    @Expose()
    @Type(() => DomainModelMetaData)
    principalMetaData: DomainModelMetaData;

    @Expose()
    @Type(() => AssociationPropertyMetaData)
    associationProperties: AssociationPropertyMetaData[];

    @Expose()
    position: number;

    constructor(
        userMetaData?: UserMetaData,
        descriptions?: MetaDataDescriptions,
        principalPropertyName?: string,
        principalMetaData?: DomainModelMetaData,
        associationProperties?: AssociationPropertyMetaData[],
        position?: number,
    ) {
        this.userMetaData = userMetaData;
        this.descriptions = descriptions ?? new MetaDataDescriptions();
        this.principalPropertyName = principalPropertyName;
        this.principalMetaData = principalMetaData ?? new DomainModelMetaData();
        this.associationProperties = associationProperties ?? [];
        this.position = position;
    }
}
export class ExternalMetaData extends AssociationMetaData {

    @Expose()
    @Type(() => AggregateMetaData)
    dependentAggregateMetaData: AggregateMetaData;

    // Indica se l'external è obbligatoria
    @Expose()
    isRequired: boolean;

    @Expose()
    isRemote: boolean;

    @Expose()
    public cloneType: CloneTypes;

    /**
    * Se una external dipende da un'altra external o property, è il nome della property da cui dipende
    * Es.: External Zona ed external SottoZona se Zona e padre di SottoZona e si vogliono attivare degli automatismi allora ParentExternalPropertyName dovrà essere ZonaRef (valorizzato con il path a partire dalla root).
    */ 
    @Expose()
    parentIdentityPropertyPathName?: string;

    constructor(
        userMetaData?: UserMetaData,
        descriptions?: MetaDataDescriptions,
        principalPropertyName?: string,
        principalMetaData?: DomainModelMetaData,
        associationProperties?: AssociationPropertyMetaData[],
        position?: number,
        dependentAggregateMetaData?: AggregateMetaData,
        isRequired?: boolean,
        isRemote?: boolean,
        cloneType?: CloneTypes
    ) {
        super(
            userMetaData,
            descriptions,
            principalPropertyName,
            principalMetaData,
            associationProperties,
            position
        )
        this.dependentAggregateMetaData = dependentAggregateMetaData;
        this.isRequired = isRequired;
        this.isRemote = isRemote;
        this.cloneType = cloneType;
    }
}

export class InternalAssociationMetaData extends AssociationMetaData {

    @Expose()
    @Type(() => DomainModelMetaData)
    dependentMetaData: DomainModelMetaData;
}

export class InternalCollectionMetaData extends InternalAssociationMetaData {
    isUniqueFunc?: (val) => any;
    uniqueFields?: string[];
}

export class InternalRelationMetaData extends InternalAssociationMetaData {

}

