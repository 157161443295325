import { PropertyMetaData } from './property-meta-data';
import { EnumResource } from './enum-resource';
import { Expose, Type } from '@nts/std/serialization';

/**
 * Metadati relativi a proeprty di tipo enum
 */
export class EnumMetaData<T = number> extends PropertyMetaData {

    /**
     *  Elenco dei valori dell'enum associati alle relative chiavi per recuperare le descrizioni dalle risorse
     */
    @Expose()
    @Type(() => EnumResource)
    valuesResource: EnumResource<T>[];

    protected override _type = 'Enum';

    /**
     * Costruttore di default
     */
    constructor() {
        super();
    }

    override set(data: any) {
        super.set(data);
        if (data?.valuesResource != null) {
            this.valuesResource = data.valuesResource;
        }
    }

    // TODO Tommy: verificare il serializzatore se gestisce corettamente l'enum
}
