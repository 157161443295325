import { Expose } from "@nts/std/serialization";

export class ZoomResult {

  @Expose()
  result: string;

  static empty(): ZoomResult {
    return new ZoomResult('');
  }

  constructor(result: string) {
    this.result = result;
  }
}
