import { Expose, Type } from '@nts/std/serialization';
import { EnumDataItem } from './enum-data-item';
import { ExternalLayoutMetaData } from './external-layout-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';
import { DefaultValueAwareInterface, PrimitiveTypeAwareInterface, SimpleFieldType } from './simple-field-meta-data';

export class GridFieldMetaData extends FieldMetaData implements PrimitiveTypeAwareInterface, DefaultValueAwareInterface {

    constructor() {
        super();
        this.fieldType = FieldTypes.Simple
    }

    @Expose()
    isExternalCode: boolean;

    // Da non usare! Usa il riferimento dentro externalRef
    // @Expose()
    // isAutocomplete: boolean;

    // Nelle griglie non è necessario utilizzarlo
    // @Expose()
    // showCode: boolean;

    @Expose()
    @Type(() => ExternalLayoutMetaData)
    externalRef: ExternalLayoutMetaData;

    @Expose()
    isAutoSize: boolean;

    @Expose()
    width: number;

    @Expose()
    defaultValue?: string;

    @Expose()
    primitiveType?: SimpleFieldType

    @Expose()
    @Type(() => EnumDataItem)
    enumData: EnumDataItem[];

}
