import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from "../../base-identity";
import { IdentityTypeNameDecorator } from "../../decorators/identity-type-name.decorator";

@IdentityTypeNameDecorator('StandardReportIdentity')
export class GeneratedStandardReportIdentity extends BaseIdentity {

	private _code: string;

	@Expose()
	get code(): string {
		return this.getValue<string>(() => this._code, 'code');
	}
	set code(value: string) {
		this.setValue<string>(() => { this._code = value; }, value, 'code');
	}
}
