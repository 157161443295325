import { ServiceResponse } from './service-response';
import { Expose } from "@nts/std/serialization";
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';


@ResponseCachedDecorator()
export class UserCanAccessToServiceResponse extends ServiceResponse {

    @Expose()
    userCanAccessToService: boolean;
}
