import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CurrentRouteService } from '../routing/current-route.service';

export const checkAuthentication:(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>Observable<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)=>{
    const authService = inject(AuthService);
    
    const currentRouteService = inject(CurrentRouteService);
    currentRouteService.setAuthenticatedRouteFromGuard();

    return from(authService.checkInitialization(route, state)).pipe(switchMap((success) => {
        if (!success) {
            return of(false)
        }
        else{
            return from(authService.checkQueryStringFromRoute(route, state)).pipe(switchMap(() => {
                return authService
                    .isUserAuthenticated()
            }));    
        }
    }));    
}

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    return checkAuthentication(route, state).pipe(map((isUserAuthenticated:boolean)=>{
        // non sono autenticato
        if (!isUserAuthenticated) {
            // login standard
            authService.logIn();        }
        return isUserAuthenticated;
    }));
};