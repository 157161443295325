<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-date-text-box 
        #baseDateTextBox 
        (onDateValueChange)="onDateValueChange($event)"
        (onDateFocus)="onDateFocus($event)" 
        (onDateSelect)="onDateSelect($event)"
        (onDateBlur)="onDateBlur()"
        (onFinishEditing)="onFinishEditing.emit($event)"
        (onTimeZoneChange)="onTimeZoneChange($event)"
        (onTimeZoneBlur)="onTimeZoneBlur()" 
        [minDate]="propertyViewModel.metadataMinValue"
        [maxDate]="propertyViewModel.metadataMaxValue" 
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [initialChar]="initialChar" 
        [valueDate]="valueDate"
        [appendTo]="appendTo" 
        [isRequired]="!isNullable" 
        [hideOnDateTimeSelect]="true" 
        [selectOtherMonths]="true"
        [showIcon]="true" 
        [showOnFocus]="false"
        [defaultDate]="todayMidnight"
        [showTime]="propertyViewModel.showTime"
        [showButtonBar]="showButtonBar"
        [isDisabled]="isDisabled || !(propertyViewModel.isEnabled$ | async) || propertyViewModel?.securityAccess != null"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [errorList]="propertyViewModel?.errors$ | async"
        [title]="propertyViewModel.formattedValue$ | async"
        [customCommandList]="customCommandsName" 
        [showOffset]="showOffset"
        [showTimeZone]="propertyViewModel.showTimeZone" 
        [valueTimeZone]="valueTimeZone"
        [timeZoneOptions]="propertyViewModel.timeZones" 
        [maskSettings]="maskSettings"
        [listenClickOutside]="listenClickOutside"></nts-base-date-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"></nts-base-security-text-box>
</ng-template>
