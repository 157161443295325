import { Expose, Type } from '@nts/std/serialization';
import { CompanyInformationDto } from "./company-information.dto";

export class EnterpriseInformationDto {
    
    @Expose()
    public enterpriseId: number;
    
    @Expose()
    public name: string;
    
    @Expose()
    public isDefault: boolean;

    @Expose()
    @Type(() => CompanyInformationDto)
    public companies: CompanyInformationDto[];
}