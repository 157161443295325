import { CoreModel } from '../../domain-models/core-model';
import { BaseIdentity } from '../../domain-models/base-identity';
import { LongOpHttpCommunication } from './long-op-http-communication';
import { BaseLongOpModel } from '../../domain-models/base-long-op-model';
import { Observable } from 'rxjs';
import { CacheOptionsInterface, WebApiServiceAgent } from '../web-api-service-agent';
import { CreateResponse } from '../../responses/create-response';
import { ExternalReaderApiClient } from '../external-reader-api-client';
import { LongOpResultTypeInspector } from '../../decorators/long-op-result-type.decorator';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { ServiceRequest } from '../../requests/service-request';
import { OnlineService } from '@nts/std/utility';
import { Injectable } from '@angular/core';

@Injectable()
export class LongOpApiClient<TBaseLongOpModel extends BaseLongOpModel<TIdentity, TParams, TResult>,
    TIdentity extends BaseIdentity,
    TParams extends CoreModel<TIdentity>,
    TResult extends CoreModel<TIdentity>> extends ExternalReaderApiClient<TBaseLongOpModel, TIdentity> {

    longOpModelType: any;
    longOpResultType: any;

    constructor(
        agent: WebApiServiceAgent,
        onlineService: OnlineService
    ) {

        super(agent, onlineService);

        this.longOpResultType = LongOpResultTypeInspector.getValue(this);
        if (this.longOpResultType === undefined) {
            throw new Error(
                `MetaData ${LongOpResultTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpResultTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this.longOpModelType = this.rootModelType;
    }

    create(
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<CreateResponse<TBaseLongOpModel, TIdentity>> {
        const communication = this.createLongOpHttpCommunication();
        return communication.create(
            cacheOptions
        );
    }

    getByObject<TRequest extends ServiceRequest, TResponse extends GenericServiceResponse<any>>(
        requestInstance: TRequest,
        responseInstance: TResponse,
        uri: string,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<TResponse> {
        const communication = this.createLongOpHttpCommunication();
        return communication.getByObject<TRequest, TResponse>(requestInstance, responseInstance, uri, cacheOptions);
    }

    executeLongOp(model: TBaseLongOpModel): Observable<GenericServiceResponse<TResult>> {
        const communication = this.createLongOpHttpCommunication();
        return communication.executeLongOp(model);
    }

    protected createLongOpHttpCommunication(): LongOpHttpCommunication<TBaseLongOpModel, TIdentity, TParams, TResult> {
        return new LongOpHttpCommunication<TBaseLongOpModel, TIdentity, TParams, TResult>(
            this.agent, this.longOpModelType, this.longOpResultType);
    }
}

