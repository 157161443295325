import { BaseIdentity } from '../domain-models/base-identity';
import { Expose } from "@nts/std/serialization";
import { ServiceRequest } from './service-request';

export class GetAllSnapShotsFrameRequest<TIdentity extends BaseIdentity> extends ServiceRequest {

  @Expose()
  identity: TIdentity;

  @Expose()
  take: number;

  @Expose()
  skip: number;

}
