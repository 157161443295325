import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BaseCellRendererComponent } from './base_cell_renderer.component';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PopperHelper } from '@nts/std/utility';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
@UntilDestroy()
// create your cellEditor as a Angular component
@Component({
    selector: 'nts-date-text-box-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgFor, 
        NgIf,
        AsyncPipe
    ],
    template: `
        <span>
            <div *ngIf="!params?.data" class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div class="read-template"  *ngIf="params?.data">
            <div [style.height.px]="cellHeight"
                #popperError="popper"
                [popper]="tooltipErrTemplate"
                popperAppendTo="body"
                popperApplyClass="error"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDisabled]="!propertyViewModel?.hasErrors"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                class="renderer-cell" >
                <div>{{ propertyViewModel?.formattedValue }}</div>
            </div>

            <popper-content #tooltipErrTemplate>
                <div *ngFor="let item of propertyViewModel?.errors$ | async">
                    {{ item }}
                </div>
            </popper-content>
        </div>`
})
export class DateCellRendererComponent extends BaseCellRendererComponent implements OnDestroy {

    @ViewChild('popperError', { static: false }) popperError!: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public readonly el: ElementRef,
        private readonly cd: ChangeDetectorRef,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);
        merge(
            this.propertyViewModel.customGetterValueChanged,
            this.propertyViewModel.propertyChanged
        ).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.detectChanges();
        });

        this.propertyViewModel.onFocusRequested.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.el.nativeElement.parentElement.parentElement.scrollIntoView();
            
            if (this.propertyViewModel?.hasErrors) {
                PopperHelper.show(this.popperError);
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
}
