import { Expose } from "@nts/std/serialization";
import { ServiceRequest } from './service-request';

export class MetaDataRequest extends ServiceRequest {

    @Expose()
    includeDescriptions: boolean;

    @Expose()
    includeSecurityMetaData: boolean;
}
