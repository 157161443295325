import { BaseError } from '../messages/base-error';
import { Exception } from '../messages/exception';
import { Information } from '../messages/information';
import { Warning } from '../messages/warning';
import { Expose, Type } from '@nts/std/serialization';

export class ServiceResponse {

    private _opSuccedeed: boolean;

    @Expose()
    get operationSuccedeed(): boolean {
        return (this.errors?.length !== 0) ? false : this._opSuccedeed;
    }

    set operationSuccedeed(value: boolean) {
        this._opSuccedeed = value;
    }

    @Expose()
    @Type(() => Information)
    informations: Information[];

    @Expose()
    @Type(() => BaseError)
    errors: BaseError[];

    @Expose()
    @Type(() => Exception)
    exceptions: Exception[];

    @Expose()
    @Type(() => Warning)
    warnings: Warning[];

    constructor() {
        this._opSuccedeed = true;
        this.errors = new Array<BaseError>();
        this.informations = new Array<Information>();
    }

    toString(): string {
        let ret: string = null;
        if (this.errors != null) {
            ret += 'ERROR: ';
            this.errors.forEach((err) => {
                ret += err.description + '; ';
            });
        }
        if (this.informations != null) {
            ret += 'INFORMATION: ';
            this.informations.forEach((info) => {
                ret += info.description + '; ';
            });
        }
        if (ret == null) {
            return 'ServiceResponse';
        } else {
            return ret;
        }
    }

}
