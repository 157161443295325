<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-toggle-text-box #baseToggleTextBox 
        (onChange)="change($event)"
        (onClick)="onClick.emit()" 
        [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip" 
        [showErrorBorder]="showErrorBorder"
        [showTitleTooltip]="showTitleTooltip"
        [errorList]="propertyViewModel?.errors$ | async"
        [isReadOnly]="isReadOnly"
        [isDisabled]="isDisabled || !(propertyViewModel.isEnabled$ | async) || propertyViewModel?.securityAccess != null"
        [label]="propertyViewModel.metadataDescription" 
        [checkStatus]="checkStatus"
        [showLabelText]="showLabelText"
        [labelText]="propertyViewModel.formattedValue$ | async"
        [attr.data-check-status]="checkStatus"
        [attr.data-property-name]="propertyViewModel.propertyName">
    </nts-base-toggle-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox 
        [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"></nts-base-security-text-box>
</ng-template>
