import { Expose } from "@nts/std/serialization";
import { IdentityTypeNameDecorator } from "../decorators/identity-type-name.decorator";
import { OperationIdentity } from "../report/operation.identity";

@IdentityTypeNameDecorator('AttachmentIdentity')
export class AttachmentIdentity extends OperationIdentity {

    private _attachmentId: number;

    @Expose()
    get attachmentId(): number {
        return this.getValue<number>(() => this._attachmentId, 'attachmentId');
    }
    set attachmentId(value: number) {
        this.setValue<number>(() => { this._attachmentId = value; }, value, 'attachmentId');
    }
}
