import { BaseMultiLanguageModel } from '../domain-models/base-multi-language-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { MultiLanguageIdentityInterface } from '../domain-models/multi-language-identity.interface';
import { ItemViewModel } from './item-view-model';
import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { MultiLanguageCollectionViewModel } from './multi-language-collection-view-model';

export abstract class BaseMultiLanguageViewModel<
    TMlModel extends BaseMultiLanguageModel<TMlIdentity>,
    TMlIdentity extends BaseIdentity & MultiLanguageIdentityInterface> extends ItemViewModel<TMlModel, TMlIdentity> {

    get languageCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._languageCode = value; }, this._languageCode, 'languageCode');
    }

    private _languageCode: StringPropertyViewModel;

    override async postInit(): Promise<void> {
        await super.postInit();
        this.languageCode.propertyChanged.subscribe(
            () => (this.parent as MultiLanguageCollectionViewModel<BaseMultiLanguageViewModel<TMlModel, TMlIdentity>, TMlModel, TMlIdentity>).languageChanged.next())
    }

}
