import { Expose, Type } from '@nts/std/serialization';
import { DomainModelMetaData } from './domain-model-meta-data';

export class AggregateMetaData {

    constructor() {
        this.domainModels = [];
        this.useMessageResourceKey = true;
    }

    @Expose()
    @Type(() => DomainModelMetaData)
    domainModels: DomainModelMetaData[];

    @Expose()
    @Type(() => DomainModelMetaData)
    rootMetaData: DomainModelMetaData;


    // TODO transformare in get che punta a rootMetaData->name?
    @Expose()
    rootName: string;

    @Expose()
    rootFullName: string;

    @Expose()
    isDeactivable: boolean;

    @Expose()
    useMessageResourceKey: boolean;

}
