import { NTSReflection } from '@nts/std/utility';

const EXTERNAL_DOMAIN_MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY = 'externalDomainModelTypeNameDecoratorMetaDataKey';

export function ExternalDomainModelTypeNameDecorator(externalDomainModelTypeName: string) {
    return (target: any) => {
        Reflect.defineMetadata(EXTERNAL_DOMAIN_MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, externalDomainModelTypeName, target);
    };
}

export class ExternalDomainModelTypeNameInspector {

    static META_DATA_KEY = EXTERNAL_DOMAIN_MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ExternalDomainModelTypeNameDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            EXTERNAL_DOMAIN_MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string {
        return NTSReflection.getClassMetadata(
            EXTERNAL_DOMAIN_MODEL_TYPE_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
