import { Expose, Type } from '@nts/std/serialization';
import { ExternalLayoutMetaData } from './external-layout-meta-data';
import { GridFieldMetaData } from './grid-field-meta-data';

export class GridColumnsMetaData {

    constructor() {
        this.gridFields = [];
        this.externals = [];
    }

    @Expose()
    modelName: string;

    @Expose()
    @Type(() => GridFieldMetaData)
    gridFields: GridFieldMetaData[];

    @Expose()
    @Type(() => ExternalLayoutMetaData)
    externals: ExternalLayoutMetaData[];

    @Expose()
    get sortedFields(): GridFieldMetaData[] {
        return [...this.gridFields].sort((a, b) => a.position - b.position);
    }

}
