import { Expose, Type } from '@nts/std/serialization';
import { UserLayoutGridMetaData } from "./user-layout-grid-meta-data";
import { UserPanelMetaData } from "./user-panel-meta-data";

export class UserLayoutMetaData {

    constructor() {
        this.panels = [];
        this.grids = [];
    }

    @Expose()
    @Type(() => UserPanelMetaData)
    panels: UserPanelMetaData[]

    @Expose()
    @Type(() => UserLayoutGridMetaData)
    grids: UserLayoutGridMetaData[]
}
