import { NTSReflection } from '@nts/std/utility';

const MODEL_FULL_NAME_META_DATA_IDENTIFICATION_KEY = 'modelFullNameDecoratorMetaDataKey';

export function ModelFullNameDecorator(modelFullName: string) {
    return (target: any) => {
        Reflect.defineMetadata(MODEL_FULL_NAME_META_DATA_IDENTIFICATION_KEY, modelFullName, target);
    };
}

export class ModelFullNameInspector {

    static META_DATA_KEY = MODEL_FULL_NAME_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ModelFullNameDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            MODEL_FULL_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): string {
        return NTSReflection.getClassMetadata(
            MODEL_FULL_NAME_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
