import { UIStarter } from '../starter/ui-starter';
import { LogService } from '@nts/std/utility';
import { NtsBootService } from '../boot/boot.service';
import { TelemetryService } from '@nts/std/telemetry';

export abstract class NtsAppRootModule {

    constructor(
        protected bootService: NtsBootService,
    ) {

        UIStarter.zone = bootService.zone;
        UIStarter.httpClient = bootService.httpClient;
        UIStarter.environmentConfiguration = bootService.environmentConfiguration;
        UIStarter.authService = bootService.authService;
        UIStarter.onlineService = bootService.onlineService;
        UIStarter.routingService = bootService.routingService;
        UIStarter.responseCacheService = bootService.responseCacheService;
        UIStarter.webApiServiceAgent = bootService.webApiServiceAgent;
        LogService.logService = bootService.logService;
        TelemetryService.telemetryService = bootService.telemetryService;
    } 
}
