import { from, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap, timeout } from 'rxjs/operators';
import { WebApiServiceAgent } from '../web-api-service-agent';
import { ServiceResponse } from '../../responses/service-response';
import { BaseError } from '../../messages/base-error';
import { FindUIInfoRequest } from '../../requests/ui-finder-service-request';
import { UIFinderWebApiClientInterface } from './ui-finder-web-api-client.interface';
import { FindUIInfoResponse } from '../../responses/find-ui-info-response';
import { LocalstorageHelper, LogService, OnlineService } from '@nts/std/utility';
import { classToPlain, plainToClass } from '@nts/std/serialization';
import { UIInfo } from '../../domain-models';

export class UIFinderWebApiClient implements UIFinderWebApiClientInterface {

    principalEntityName: string;

    private readonly uiFinderCacheKey = 'uiFinderCache'

    private static readonly uiFinderRequestUri: string = 'FindUIInfo';
    private agent: WebApiServiceAgent;

    constructor(
        agent: WebApiServiceAgent,
        public onlineService: OnlineService
    ) {

        this.agent = agent;
        this.initPrincipalEntityName('uifinder');

    }

    initPrincipalEntityName(principalEntityName: string) {
        this.principalEntityName = principalEntityName;
        this.agent.rootModelName = principalEntityName;
    }

    findUIInfos(request: FindUIInfoRequest): Observable<FindUIInfoResponse> {

        return this.onlineService.isOnline$.pipe(
            distinctUntilChanged(), 
            switchMap(() => {
                if (this.onlineService.isOnline) {
                    const result = this.agent.invokePostWithResponse<FindUIInfoRequest, FindUIInfoResponse>
                        (
                            UIFinderWebApiClient.uiFinderRequestUri, 
                            request, 
                            FindUIInfoResponse, 
                            true, 
                            undefined, 
                            undefined, 
                            undefined, 
                            {
                                bypass: false,
                                enableTimeout: true,
                                tenantBarrier: true,
                                enterpriseBarrier: false,
                                userBarrier: false,
                                force: true
                            }
                        ).pipe(
                            catchError(error => {
                                return this.handleErrorAsResponse<FindUIInfoResponse>(error, FindUIInfoResponse);
                            }),
                            tap(async (result) => {
                                await this.updateOfflineCache(request, result);
                            })
                        );
                    return result;
                } else {
                    return this.getFromOfflineCache(request);
                }                
            })
        );
    }

    getFromOfflineCache(request: FindUIInfoRequest): Observable<FindUIInfoResponse> {
        return from(LocalstorageHelper.getStorageItem(this.uiFinderCacheKey, undefined, true, false , false)).pipe(
            map((cache: Map<string, Object>) => {
                if (!cache || !(cache instanceof Map)) {
                    cache = new Map<string, Object>();
                }
                if (cache.has(request.requestData.objectFullName + '-' + request.requestData.clientType.toString())) {
                    const found = cache.get(request.requestData.objectFullName + '-' + request.requestData.clientType.toString());
                    if (found) {
                        const uiInfo = plainToClass(UIInfo, found, { strategy: 'excludeAll' });
                        const response = new FindUIInfoResponse();
                        response.result = uiInfo;
                        return response;
                    }
                }
                return new FindUIInfoResponse();
            })
        );
    }

    async updateOfflineCache(request: FindUIInfoRequest, result: FindUIInfoResponse): Promise<void> {
        let cache = await LocalstorageHelper.getStorageItem(this.uiFinderCacheKey, undefined, true, false , false) as Map<string, Object>;
        if (!cache || !(cache instanceof Map)) {
            cache = new Map<string, Object>();
        }
        const plainObj = classToPlain(result.result, { strategy: 'excludeAll' });
        cache.set(
            request.requestData.objectFullName + '-' + request.requestData.clientType.toString(), 
            plainObj
        );
        await LocalstorageHelper.setStorageItem(this.uiFinderCacheKey, cache, undefined, true, false , false);
    }

    private handleErrorAsResponse<TResponse extends ServiceResponse>(error: any, responseType: any): Observable<TResponse> {

        const messageError = new BaseError();

        let errorMessage = 'Error in UIFinderWebApiClient: ';
        if (error.message) {
            errorMessage += error.message;
        }

        // TODO: Approfondire se possiamo valorizzare ulterioremente l'errore
        messageError.description = errorMessage;

        const typedResponse: ServiceResponse = new responseType();
        typedResponse.operationSuccedeed = false;
        typedResponse.errors.push(messageError);

        LogService.warn(errorMessage, messageError)

        return Observable.create(observer => {
            observer.next(typedResponse);
        });

    }
}
