import { Expose } from "@nts/std/serialization";
import { MetaDataDescriptions } from './meta-data-descriptions';
import { PropertyMetaData } from './property-meta-data';
import { UserMetaData } from './user-meta-data';

export class DateTimeMetaData extends PropertyMetaData {

    @Expose()
    isDateTimeOffset: boolean;

    protected override _type = 'DateTime';

    constructor(
        name?: string,
        descriptions?: MetaDataDescriptions,
        userMetaData?: UserMetaData,
        isRequired?: boolean,
        position?: number,
        isDateTimeOffset?: boolean,
    ) {
        super(
            name,
            descriptions,
            userMetaData,
            isRequired,
            position
        )
        this.isDateTimeOffset = isDateTimeOffset;
    }

    override set(data: any) {
        super.set(data);
        if (data?.isDateTimeOffset != null) {
            this.isDateTimeOffset = data.isDateTimeOffset;
        }
    }
}
