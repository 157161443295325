import { BaseNumericPropertyViewModel } from './base-numeric-property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { NumeralService } from '@nts/std/utility';

export class NNumericPropertyViewModel extends BaseNumericPropertyViewModel {

    getFormattedValue(value: number) {
        if (typeof value !== 'number') {
            // TODO: we should throw an exception
            return '';
        }
        if (value == null) {
            return '';
        }
        return NumeralService.Current(value).format(this.format);
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
    ) {
        super(initInfo, true);
        this._defaultFaultBackValue = null;
    }
}
