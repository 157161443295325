import { Expose, Type } from '@nts/std/serialization';
import { CustomReportItemIdentity } from "./custom-report/custom-report-item.identity";
import { StandardReportIdentity } from "./standard-report/standard-report.identity";

export class ReportInfoDto {

    constructor() {
        this.standardReportIdentity = new StandardReportIdentity();
        this.customReportItemIdentity = new CustomReportItemIdentity();
    }

    @Expose()
    @Type(() => StandardReportIdentity)
    standardReportIdentity: StandardReportIdentity;

    @Expose()
    @Type(() => CustomReportItemIdentity)
    customReportItemIdentity: CustomReportItemIdentity;

    @Expose()
    displayName: string;

    @Expose()
    description: string;

    @Expose()
    ownerObjectFullName: string;

    @Expose()
    baseAddress: string;

    @Expose()
    apiAddress: string;

    @Expose()
    isUIRequired: boolean;

    @Expose()
    uiFullAddress: string;

    equals(otherReportInfo: ReportInfoDto) {
        if (
            (this.standardReportIdentity == null && otherReportInfo.standardReportIdentity != null) ||
            (this.standardReportIdentity != null && otherReportInfo.standardReportIdentity == null)
        ) {
            return false;
        }

        if (
            (this.customReportItemIdentity == null && otherReportInfo.customReportItemIdentity != null) ||
            (this.customReportItemIdentity != null && otherReportInfo.customReportItemIdentity == null)
        ) {
            return false;
        }

        if (this.standardReportIdentity) {
            return this.standardReportIdentity.equals(otherReportInfo.standardReportIdentity);
        }

        if (this.customReportItemIdentity) {
            return this.customReportItemIdentity.equals(otherReportInfo.customReportItemIdentity);
        }

        // Se entrambi sono nulli
        return this.displayName === otherReportInfo.displayName;

    }

}
