<div class='enum-text-wrapper'
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body">
    <p-dropdown #combo
        (onChange)="dropdownChange($event)"
        (onBlur)="blur($event)"
        (onShow)="onShow($event)"
        [(ngModel)]="value"
        #popperInfo="popper"
        [popper]="title"
        [popperDisabled]="!title || title?.length === 0 || errorList?.length > 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        [popperDelay]="1000"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [panelStyleClass]="panelClass"
        [showClear]="isNullable"
        [options]="simpleOptions$ | async" 
        [appendTo]="appendTo" 
        [disabled]="isDisabled"
        [optionLabel]="optionLabel" 
        [dataKey]="dataKey"
        placeholder="{{placeholder}}">
        <ng-template let-option pTemplate="item">
            <div class="option-item"
                [popper]="option.descriptionExt"
                [popperDisabled]="!option?.descriptionExt || option?.descriptionExt?.length == 0"
                popperApplyClass="info"
                popperAppendTo="body"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDelay]="1000">
                {{ option.description }}
            </div>
        </ng-template>
    </p-dropdown>
</div>

<ng-template ngFor let-commandName [ngForOf]="customCommandList">
    <button class="command-bar-button" (click)="commandName.execute()"
        [disabled]="isDisabled || isReadonly || !(commandName.canExecute$ | async)"
        [attr.title]="commandName.tooltip"><i [ngClass]="commandName.iconClass"></i></button>
</ng-template>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
