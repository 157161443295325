import { Expose } from "@nts/std/serialization";

export class CompanyInformationDto {
    
    @Expose()
    companyId: number;

    @Expose()
    name: string;

    @Expose()
    color: string;

    @Expose()
    isDefault: boolean;
}