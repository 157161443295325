import { Expose, Type } from '@nts/std/serialization';
import { ExternalLayoutMetaData } from "./external-layout-meta-data";
import { FieldMetaData } from "./field-meta-data";
import { GridMetaData } from "./grid-meta-data";
import { SimpleFieldMetaData } from "./simple-field-meta-data";

export class ExtraLayoutMetaData {

    constructor() {
        this.simpleFields = [];
        this.externals = [];
        this.grids = [];
    }

    @Expose()
    @Type(() => SimpleFieldMetaData)
    simpleFields: SimpleFieldMetaData[];

    @Expose()
    @Type(() => ExternalLayoutMetaData)
    externals: ExternalLayoutMetaData[];

    @Expose()
    @Type(() => GridMetaData)
    grids: GridMetaData[];

    @Expose()
    get sortedFields(): FieldMetaData[] {
        return [...this.simpleFields, ...this.externals].sort((a, b) => a.position - b.position);
    }
}
