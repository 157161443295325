import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { SvgIconComponent } from "@ngneat/svg-icon";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PopperHelper } from "@nts/std/utility";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { BehaviorSubject, filter } from "rxjs";

export enum FilledButtonType {
    Success = 1,
    Error = 2,
    Info = 3,
}

@UntilDestroy()
@Component({
    selector: 'nts-filled-button',
    templateUrl: './filled-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./filled-button.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        SvgIconComponent,
        NgClass,
        NgIf
    ]
})
export class FilledButtonComponent implements OnChanges, OnDestroy {
    
    @Input() buttonAttributeType: string = 'button';
    @Input() tabIndex: number;
    @Input() icon: string = null;
    @Input() isLoading: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() label: string = null;
    @Input() title: string = null;
    @Input() type: FilledButtonType = FilledButtonType.Success;
    @Input() isDetachedObservable: BehaviorSubject<boolean> = new BehaviorSubject(false);

    @Output() onClicked: EventEmitter<any> = new EventEmitter();

    @ViewChild('button', { static: false }) button: ElementRef;
    @ViewChild(NgxPopperjsDirective, {static: false}) popperInfo: NgxPopperjsDirective;

    filledButtonType = FilledButtonType;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    ngOnDestroy(): void {
        if (this.popperInfo) {
            PopperHelper.hide(this.popperInfo);
        }        
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Se disabilito il componente nascondo il tooltip
        if (changes['isDisabled']) {
            if (changes['isDisabled']?.currentValue === true && this.popperInfo) {
                PopperHelper.hide(this.popperInfo);
            }
        // Se cambio il title nascondo il tooltip
        } else if (changes['title']) {
            if (changes['title']?.currentValue !== changes['title']?.previousValue) {
                PopperHelper.hide(this.popperInfo);
            }
        }
    }

    buttonClicked(e) {
        this.onClicked.emit(e);
        if (this.popperInfo) {
            PopperHelper.hide(this.popperInfo);
        }
    }

    ngOnInit(): void {
        if (this.isDetachedObservable) {
            this.isDetachedObservable
            .pipe(untilDestroyed(this), filter((isDetached: boolean) => isDetached))
            .subscribe((isDetached) => {
                    if (this.popperInfo && isDetached) {
                        PopperHelper.hide(this.popperInfo);
                    }
                })
        }
    }
}