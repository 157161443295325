import { NTSReflection } from '@nts/std/utility';

const RESPONSE_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY = 'responseResultTypeDecoratorMetaDataKey';

export function ResponseResultTypeDecorator(responseResultType: any) {
    return (target: any) => {
        Reflect.defineMetadata(RESPONSE_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, responseResultType, target);
    };
}

export class ResponseResultTypeInspector {

    static META_DATA_KEY = RESPONSE_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@ResponseResultTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            RESPONSE_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            RESPONSE_RESULT_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
